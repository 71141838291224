export const getUser = (state) => state.userState.userDetails;

export const getUserProfileAndSettings = (state) => {
    if (state && state.userState && state.userState.profileAndSettings) {
        return state.userState.profileAndSettings
    }
    return {};
}

export const getLotAssociation = (state) => state.lotAssociationState;

export const getTasks = (state) => state.taskState;

export const getWarranty = (state) => state.warrantyState;

export const getWarrantyServiceItems = (state) => state.warrantyState.warrantyClaims.claimsData;

export const getDeposits = (state) => state.depositsState;

export const getPhotoModalState = (state) => state.photoModalState;

export const getProgressContent = (state) => state.userState.progressContent;

