import React from 'react';
import ContentLoader from 'react-content-loader';

const MyHomeDesktopSkeleton = () => {
    return (
        <React.Fragment>
            <ContentLoader
                data-testid="my-home-desktop-skeleton"
                speed={2}
                viewBox="0 0 875 435"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <rect x="0" y="0" rx="2" ry="2" width="65%" height="435" />
                <rect x="585" y="0" rx="2" ry="2" width="35%" height="211" />
                <rect x="585" y="225" rx="2" ry="2" width="35%" height="211" />
            </ContentLoader>

            <ContentLoader
                data-testid="my-home-desktop-skeleton-overlay"
                speed={2}
                viewBox="0 0 875 435"
                backgroundColor={'#E3E3E3'}
                foregroundColor={'#999'}
                style={{
                    position: 'absolute',
                    left: '1px',
                }}
            >
                <rect x="630" y="275" rx="2" ry="2" width="15%" height="20" />
                <rect x="100" y="250" rx="2" ry="2" width="45%" height="35" />
                <rect x="100" y="300" rx="2" ry="2" width="15%" height="20" />
            </ContentLoader>
        </React.Fragment>
    );
};


export default MyHomeDesktopSkeleton;
