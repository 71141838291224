import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ContactBioModal.scss';
import { createCloseBioModalAction } from 'redux/actions/ContactBioModal/ContactBioModalActions';
import SanitizeService from 'services/SanitizeService';
import Utils from 'utilities/Utils';
import { useOutsideAlerter } from 'utilities/HooksAndSelectors';
import DefaultBioIcon from '../../../assets/Bio-Icon.svg';

const ContactBioModal = () => {
    const dispatch = useDispatch();
    const bio = useSelector((reduxState) => reduxState.contactBioState);
    const [errorBioImage, setErrorBioImage] = useState();
    const wrapperRef = useRef(null);
    const keyPress = (e) => {
        if (e.keyCode === 27) {
            dispatch(createCloseBioModalAction());
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyPress);
        return () => {
            window.removeEventListener('keydown', keyPress)
        };
    });
    useOutsideAlerter(wrapperRef, (whatWasClicked) => {
        const parentIsContent = Utils.findParent(
            whatWasClicked,
            'contact-bio__content'
        );

        if (parentIsContent === null) {
            dispatch(createCloseBioModalAction());
        }
    });

    let elProps = null;
    if (bio) {
        elProps = {
            open: true,
        };
    } else {
        return null;
    }

    const { repContactLink, repImageUrl, repName } = bio;

    const handleImageError = () => {
        setErrorBioImage(DefaultBioIcon);
    };

    return (
        <dialog

            className="contact-bio"
            {...elProps}
            data-testid="test-bio-modal"
        >
            <div className="contact-bio__content" ref={wrapperRef}>
                <div className="contact-bio__content-inner">
                    <button
                        type="button"
                        className="contact-bio__close"
                        data-testid="contact-bio__close"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(createCloseBioModalAction());
                            return false;
                        }}
                    >
                        close
                    </button>
                    <div className="contact-bio__img-wrap">
                        <img
                            data-testid="test-bio-modal__img"
                            lazy="true"
                            className="contact-bio__img"
                            src={repImageUrl ? (errorBioImage || repImageUrl) : DefaultBioIcon}
                            onError={handleImageError}
                            alt={repName}
                        />
                    </div>
                    <h2 className="contact-bio__title" data-testid="test-bio-modal__title">{repName}</h2>
                    <div
                        className="contact-bio__bio"
                        // eslint-disable-next-line react/no-danger
                        data-testid="test-bio-modal__bio"
                        dangerouslySetInnerHTML={{
                            __html: SanitizeService.sanitize(repContactLink),
                        }}
                    />
                </div>
            </div>
        </dialog>
    );
};
ContactBioModal.defaultProps = {};
ContactBioModal.propTypes = {};

export default ContactBioModal;
