import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import { DocumentsService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchDocuments(lotId) {
    try {
        return await _sendRequest(lotId, DocumentsService.getDocuments);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchSingleDocument(documentId) {
    try {
        return await _sendRequest(documentId, DocumentsService.getDocument, false, true);
    } catch (error) {
        return await buildErrorObject(error);
    }
}

export function* documentsSaga(lotId) {
    const documentsResponse = yield call(fetchDocuments, lotId);
    if (documentsResponse && documentsResponse.opportunityDocuments && Array.isArray(documentsResponse.opportunityDocuments)) {
        yield put({
            type: ActionTypes.GetDocumentsSuccess,
            payload: documentsResponse.opportunityDocuments,
        });
    } else {
        yield put({
            type: ActionTypes.GetDocumentsError,
        });
    }
}

export function* singleDocumentsSaga(action) {
    const { payload } = action;
    const taskResponse = yield call(fetchSingleDocument, payload);
    if (taskResponse && !taskResponse.errors) {
        yield put({
            type: ActionTypes.GetSingleDocumentSuccess,
            payload: {
                data: taskResponse,
                packageId: payload,
            },
        });
    } else {
        yield put({
            type: ActionTypes.GetSingleDocumentError,
            payload: {
                errors: taskResponse.errors,
                packageId: payload,
            },
        });
    }
}
