import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ChecklistAccordionHeader from './ChecklistAccordionHeader';
import ChecklistSingleTask from './ChecklistSingleTask';

const TaskSetAccordion = (props) => {
    const { label, tasks, mode, testId, isPersonal } = props;
    const [isOpen, setIsOpen] = useState(true);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Accordion defaultActiveKey="0" activeKey={isOpen ? label : ''} data-testid={testId}>
            <Card>
                <ChecklistAccordionHeader
                    eventKey={label}
                    label={label}
                    mode={mode}
                    isOpen={isOpen}
                    handleToggle={toggleAccordion}
                />
                <Accordion.Collapse eventKey={label}>
                    <div>
                        {tasks.map((task, index) => (
                            <ChecklistSingleTask
                                key={label + task.taskId}
                                sectionLabel={label}
                                task={task}
                                isPersonal={isPersonal}
                            />
                        ))}
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

TaskSetAccordion.propTypes = {
    label: PropTypes.string.isRequired,
    tasks: PropTypes.array.isRequired,
    mode: PropTypes.string.isRequired,
    testId: PropTypes.string,
};
export default TaskSetAccordion;
