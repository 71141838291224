import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { HomesService, LotService } from 'services';
import { getUserProfileAndSettings } from 'redux/sagas/selectors';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchCommunities(divisionId) {
    try {
        return await _sendRequest({ divisionId }, HomesService.getCommunities);
    } catch (error) {
        return buildErrorObject(error);
    }
}
async function fetchDivisions() {
    try {
        return await _sendRequest({}, HomesService.getDivisions);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchBuyers(lotId) {
    try {
        return await _sendRequest({ lotId }, LotService.getBuyers);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function searchByAddress(data) {
    try {
        return await _sendRequest(data, HomesService.searchByAddress);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function searchByName(data) {
    try {
        return await _sendRequest(data, HomesService.searchByName);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* getCommunitiesSaga(action) {
    const { payload } = action;
    const divisionId = payload;
    const communities = yield call(fetchCommunities, divisionId);
    if (communities && !communities.errors) {
        yield put({
            type: ActionTypes.GetCommunitiesSuccess,
            payload: {
                divisionId,
                communities,
            },
        });
    } else {
        yield put({
            type: ActionTypes.GetCommunitiesError,
        });
    }
}

export function* getDivisionsSaga() {
    const res = yield call(fetchDivisions);
    if (res && !res.errors) {
        yield put({
            type: ActionTypes.GetDivisionsSuccess,
            payload: res,
        });
    } else {
        yield put({
            type: ActionTypes.GetDivisionsError,
        });
    }
}

export function* getAssociatedBuyersSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const lotId = userProfileAndSettings.activeLotId;
    const res = yield call(fetchBuyers, lotId);
    if (res && !res.errors) {
        yield put({
            type: ActionTypes.GetAssociatedBuyersSuccess,
            payload: res,
        });
    } else {
        yield put({
            type: ActionTypes.GetAssociatedBuyersError,
        });
    }
}

export function* searchByAddressSaga(action) {
    const results = yield call(searchByAddress, action.payload);
    if (
        results &&
        !results.error &&
        !results.errors && Array.isArray(results)
    ) {
        yield put({
            type: ActionTypes.SearchForLotByAddressResults,
            payload: results,
        });
    } else {
        yield put({
            type: ActionTypes.SearchForLotByAddressError,
        });
    }
}

export function* searchByNameSaga(action) {
    const results = yield call(searchByName, action.payload);
    if (
        results &&
        !results.error &&
        !results.errors && Array.isArray(results)
    ) {
        yield put({
            type: ActionTypes.SearchForLotByNameResults,
            payload: results,
        });
    } else {
        yield put({
            type: ActionTypes.SearchForLotByNameError,
        });
    }
}
