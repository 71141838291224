import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    isOpen: false,
};

const ShareReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OpenShareModal:
            return {
                isOpen: true,
            };
        case ActionTypes.CloseShareModal:
            return {
                isOpen: false,
            };
        default:
            return state;
    }
};
export default ShareReducer;
