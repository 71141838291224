import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    lotId: '',
    errors: {},
};

const LotAssociationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ClearLotErrors:
            return {
                ...state,
                errors: {},
            }
        case ActionTypes.CancelAddLot:
        case ActionTypes.LogOut:
            return initialState;
        case ActionTypes.OpenFindLotPage:
            return {
                ...state,
                errors: {},
            }
        case ActionTypes.AddLotIdRequest:
            return { ...state };
        case ActionTypes.LotIdError:
            return {
                ...state,
                errors: action.payload.errors,
            };
        case ActionTypes.LoginBannerContentSuccess:
            return {
                ...state,
                loginMessage: action.payload,
            };
        case ActionTypes.LotTooltipSuccess:
            return {
                ...state,
                lotTooltipContent: action.payload,
                errors: {},
            };
        case ActionTypes.GetLotByIdError:
        case ActionTypes.LotTooltipError:
            return {
                ...state,
                errors: action.payload.errors,
            };
        case ActionTypes.UpdateLotId:
            return { ...state, lotId: action.payload };
        default:
            return state;
    }
};
export default LotAssociationReducer;
