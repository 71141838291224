import React, { useState } from 'react';
import './TabSet.scss';

const TabSet = ({ components, labels, initialTab = 0, extraFuncOnTabChange = null }) => {
    const [activeIndex, setActiveIndex] = useState(initialTab);
    if (components.length !== labels.length) {
        console.error(
            `mismatching between labels (${labels.length}) & components (${components.length}) in TabSet.js`
        );
        return <React.Fragment />;
    }
    return (
        <div className="tabset">
            <div className="tabset__tabs">
                {labels.map((label, index) => {
                    const before = index === activeIndex - 1;
                    const active = index === activeIndex;
                    const after = index === activeIndex + 1;
                    return (
                        <button
                            data-testid={`tabset-button-${index}`}
                            key={`button-${index}`}
                            className={`tabset__tab-button ${after && 'tabset__tab-button--after'} ${before && 'tabset__tab-button--before'} ${active && 'tabset__tab-button--active'}`}
                            onClick={(e) => {
                                setActiveIndex(index);
                                if (extraFuncOnTabChange) {
                                    extraFuncOnTabChange();
                                }
                            }}
                            type="button"
                        >
                            <span
                                className={`${index === activeIndex - 1 && 'before'} ${index === activeIndex && 'active'} ${index === activeIndex + 1 && 'after'}`}
                            >
                                {label}
                            </span>
                        </button>
                    );
                })}
            </div>
            <div className="tabset__tab-content">
                {components.map((component, index) => {
                    const props = {
                        className: 'tabset__tab',
                        'aria-hidden': index !== activeIndex,
                    }
                    return index === activeIndex ? (
                        <div data-testid={`tabset-component-${index}`} key={`component-${index}`} {...props}>
                            {component}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default TabSet;
