import React from 'react';

const Loader = () => (
    <div className="lds-ring lds-ring--small" data-testid="loader-wrapper">
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default Loader;
