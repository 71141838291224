import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProgressAccordionHeader.scss';
import { Accordion, Button } from 'react-bootstrap';
import { Icon, Text } from 'ComponentLibrary';
import Utils from 'utilities/Utils';

function fixStatus(str) {
    if (str === 'NotStarted') {
        return 'Not Started';
    }
    if (str === 'Started') {
        return 'In Progress';
    }
    return str;
}

export default function ProgressAccordionHeader(props) {
    const { title, indicator, defaultOpen, status } = props;
    const [toggleState, setToggleState] = useState(defaultOpen);

    const toggleIcon = () => {
        setToggleState(!toggleState);
    };

    return (
        <Accordion.Toggle
            as={Button}
            variant=""
            data-testid="progress-accordion-header-toggle"
            eventKey={defaultOpen ? '1' : '0'}
            onClick={toggleIcon}
        >
            <div
                className="progress-accordion-header-container"
                data-testid="progress-accordion-header-container"
            >
                {indicator}
                <Text type="block" variant="regular" color="indigo">
                    {title}
                </Text>
                {!toggleState ? (
                    <Icon
                        size={1}
                        icon="skinnyarrow"
                        color="steel-blue"
                        circle="mystic"
                        testId="open-icon"
                    />
                ) : (
                    <Icon
                        size={1}
                        icon="skinnyarrowup"
                        color="mystic"
                        circle="steel-blue"
                        testId="close-icon"
                    />
                )}
                <span className={`progress-accordion-status-label progress-accordion-status-label--${Utils.slugify(status)}`}>{fixStatus(status)}</span>
            </div>
        </Accordion.Toggle>
    );
}

ProgressAccordionHeader.defaultProps = {
    title: null,
    indicator: null,
    defaultOpen: false,
};

ProgressAccordionHeader.propTypes = {
    title: PropTypes.string,
    indicator: PropTypes.element,
    defaultOpen: PropTypes.bool,
};
