import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import { FeedbackService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import { createSubmitFeedbackErrorAction } from 'redux/actions/Feedback';

async function submitFeedback(data) {
    try {
        const r = await _sendRequest(data, FeedbackService.submit, false);
        return r;
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* submitFeedbackSaga(formData) {
    const data = formData;
    const feedbackResponse = yield call(submitFeedback, data);
    if (feedbackResponse === undefined || feedbackResponse === '') {
        yield put({
            type: ActionTypes.SubmitFeedbackFormSuccess,
        });
    } else {
        yield put(createSubmitFeedbackErrorAction(feedbackResponse && feedbackResponse.errors ? feedbackResponse.errors : feedbackResponse));
    }
}
