import ForgotPasswordLink from './ForgotPasswordLink';
import RegisterAccountLink from './RegisterAccountLink';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import TermsOfUseLink from './TermsOfUseLink';

const Links = {
    ForgotPasswordLink,
    RegisterAccountLink,
    PrivacyPolicyLink,
    TermsOfUseLink,
};

export default Links;