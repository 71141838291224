import React from 'react';
import PropTypes from 'prop-types';

import './PaginationDot.scss';

const PaginationDot = (props) => {
    const { active } = props;
    const classList = `dot ${active ? 'active' : ''}`;
    return <span className={classList} data-testid="pagination-dot" />;
};

PaginationDot.defaultProps = {
    active: false,
};

PaginationDot.propTypes = {
    active: PropTypes.bool,
};

export default PaginationDot;
