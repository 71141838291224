import React from 'react';
import ContentLoader from 'react-content-loader';

const MyHomeMobileSkeleton = () => {
    return (
        <ContentLoader
            data-testid="my-home-mobile-skeleton"
            speed={2}
            viewBox="0 0 875 435"
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="435" />
        </ContentLoader>
    );
};

export default MyHomeMobileSkeleton;
