import React, { Fragment } from 'react';
import './LotListItem.scss';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Icon } from 'ComponentLibrary';
import {
    createChangeActiveLotAction,
    createDeleteLotAssociationAction,
    createConfirmLotDeletionAction,
} from 'redux/actions/ProfileAndSettings';
import Constants from 'Constants';
import WithCondition from 'hoc/WithCondition';
import { CookieService } from 'services';

const LotListItem = (props) => {
    const { lot, multiple, index, testId } = props;
    const { lotId, lotSummary } = lot;
    const { address } = lotSummary;

    const { streetAddress, city, state, zip } = address;

    const user = useSelector((reduxState) => reduxState.userState);

    const { profileAndSettings } = user;
    const { activeLotId, removeLotMode, lotForDeletion } = profileAndSettings;

    const selected = activeLotId === lotId || activeLotId === lotId.toString();
    const forDeletion = lotForDeletion === lotId || lotForDeletion === lotId.toString();
    const dispatch = useDispatch();

    return (
        <li
            data-testid={testId}
            className={`lot-list-item ${selected && 'lot-list-item--selected'} ${!multiple && 'lot-list-item--single'}`}
        >
            <WithCondition
                condition={multiple}
                wrapper={(children) => (
                    <Fragment>
                        {removeLotMode && (lotForDeletion === null || forDeletion) && (
                            <button
                                className={`lot-list-item__delete ${forDeletion && 'lot-list-item__delete--is-confirmable'} ${selected && 'lot-list-item__delete--active'} ${removeLotMode && 'lot-list-item__delete--remove-mode'}`}
                                data-testid="lot-list-item__delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(createDeleteLotAssociationAction(lotId));
                                    return false;
                                }}
                                type="button"
                            >
                                <span className="lot-list-item__delete-inner">
                                    <Icon icon="delete" />
                                    {Constants.profileDropDown.deleteHome}
                                </span>
                            </button>
                        )}
                        <label
                            className={`lot-list-item__label ${
                                selected ? 'lot-list-item__label--selected' : ''
                            } ${
                                removeLotMode && 'lot-list-item__label--in-remove-mode'
                            } ${
                                multiple ? 'lot-list-item__label--multiple' : ''
                            } ${
                                removeLotMode && !forDeletion && lotForDeletion === null ? 'lot-list-item__label--remove-mode' : ''
                            } ${
                                removeLotMode && forDeletion && 'lot-list-item__label--for-deletion'
                            }`}
                            htmlFor={`home-${index}`}
                        >
                            {children}
                            <div className="input-wrap input-wrap--radio input-wrap--radio-alt">
                                <span className="checkbox checkbox--radio checkbox--radio-alt ">
                                    <input
                                        className="input input--radio"
                                        onChange={(e) => {
                                            const action = createChangeActiveLotAction(
                                                parseInt(e.currentTarget.value, 10)
                                            );
                                            CookieService.setCookie(
                                                'activeLotId',
                                                e.currentTarget.value,
                                                365
                                            );
                                            dispatch(action);
                                        }}
                                        data-testid={`${testId}__radio-button`}
                                        checked={selected}
                                        key={`home-${index}`}
                                        type="radio"
                                        name="homepicker"
                                        id={`home-${index}`}
                                        value={lotId}
                                    />
                                    <span className="checkbox__indicator checkbox__indicator--radio" />
                                </span>
                            </div>
                        </label>
                        {removeLotMode && (
                            <button
                                onClick={(e) => {
                                    // we are actually going to delete
                                    e.preventDefault();
                                    dispatch(createConfirmLotDeletionAction());
                                    return false;
                                }}
                                type="button"
                                data-testid="lot-list-item__confirm-deletion"
                                className={`lot-list-item__confirm-deletion ${
                                    forDeletion &&
                                    'lot-list-item__confirm-deletion--active'
                                }`}
                            >
                                {Constants.profileDropDown.delete}
                            </button>
                        )}
                    </Fragment>
                )}
            >
                <address
                    className="lot-list-item__address"
                    data-testid={`${testId}__address`}
                >
                    <Text
                        type="inline"
                        color={`${!selected && multiple ? 'tundora' : ''}`}
                    >
                        {streetAddress}
                    </Text>
                    <br />
                    <Text type="inline" variant="extra-small">
                        {city}, {state} {zip}
                    </Text>
                </address>
                <p className="lot-list-item__lot-id">
                    <Text type="inline" variant="extra-small" semibold>Lot ID: </Text>
                    <Text type="inline" variant="extra-small">{lotId}</Text>
                </p>
            </WithCondition>
        </li>
    );
};

LotListItem.defaultProps = {
    multiple: false,
    testId: null,
};

LotListItem.propTypes = {
    multiple: PropTypes.bool,
    lot: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    testId: PropTypes.string,
};

export default LotListItem;
