import ApiService from './ApiService';

const ProfileService = {
    deleteSubscription: (notificationsubscriptionid, service = ApiService()) => service
        .delete(
            `/Profile/pushnotifications/subscription/${notificationsubscriptionid}`
        )
        .then((response) => response)
        .catch((error) => error.response),
    get: (options, service = ApiService()) => service
            .get('/profile', {
                headers: { Authorization: `Bearer ${options.accessToken}` },
            })
            .then((response) => response)
            .catch((error) => error.response),
    deleteLotAssociation: ({ lotId }, service = ApiService()) => service
            .delete(`/Profile/lotassociation/${lotId}`)
            .then((response) => response)
            .catch((error) => error.response),
    addLotAssociation: (options, service = ApiService()) => service
            .post('/profile/LotAssociations', { LotId: options.lotId }, false)
            .then((response) => response)
            .catch((error) => error.response.data),
    setNotificationPreferences: (options, service = ApiService()) => service
            .put('/Profile/NotificationSettings', options)
            .then((response) => response)
            .catch((error) => error.response),
};

export default ProfileService;
