import ActionTypes from 'redux/actions/ActionTypes';
import { sortDeposits } from './DepositsTransform';

export const initialState = {
  deposits: null,
  footnotes: null,
  processedSortOrder: null,
  scheduledSortOrder: null,
  paymentConfirmationModalDepositId: null,
  paymentConfirmationModalDepositBase64: null,
  disabledDepositIds: [],
};

const DepositsReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.ChangeActiveLot:
    case ActionTypes.OpenFindLotPage:
    case ActionTypes.LogOut:
    case ActionTypes.SignOut:
      return initialState;
    case ActionTypes.ClosePaymentConfirmationModal:
      if(action.payload) {
        return {
          ...state,
          paymentConfirmationModalDepositId: null,
          paymentConfirmationModalDepositBase64: null,
          disabledDepositIds: [...state.disabledDepositIds, action.payload]
        };
      }
      return {
        ...state,
        paymentConfirmationModalDepositBase64: null,
        paymentConfirmationModalDepositId: null,
      };
    case ActionTypes.ShowPaymentConfirmationModal:
      return {
        ...state,
        paymentConfirmationModalDepositId: action.payload.depositId,
        paymentConfirmationModalDepositBase64: action.payload.depositIdBase64String,
      };
    case ActionTypes.GetDepositsSuccess:
      const scheduledDeposits = [];
      const processedDeposits = [];
      const { deposits, footnotes } = action.payload;

      deposits.forEach((deposit) => {
        deposit.processedDate
          ? processedDeposits.push(deposit)
          : scheduledDeposits.push(deposit);
      });

      return {
        ...state,
        deposits: {
          scheduled: sortDeposits(scheduledDeposits, 'scheduled-descending'),
          processed: sortDeposits(processedDeposits, 'processed-descending'),
        },
        footnotes,
      };
    case ActionTypes.LotIdSuccess:
      return initialState;
    case ActionTypes.GetDepositsError:
      return {
        ...state,
        deposits: {
          scheduled: [],
          processed: [],
        },
        footnotes: [],
      };

    case ActionTypes.SortProcessed:
      return {
        ...state,
        deposits: {
          scheduled: state.deposits.scheduled,
          processed: sortDeposits(state.deposits.processed, action.payload),
        },
        processedSortOrder: action.payload,
      };

    case ActionTypes.SortScheduled:
      return {
        ...state,
        deposits: {
          scheduled: sortDeposits(state.deposits.scheduled, action.payload),
          processed: state.deposits.processed,
        },
        scheduledSortOrder: action.payload,
      };

    default:
      return state;
  }
};

export default DepositsReducer;
