import React from 'react';
import ContentLoader from 'react-content-loader';

const MessageBoardSkeleton = () => {
    return (
        <ContentLoader
            data-testid="message-board-skeleton"
            speed={2}
            viewBox="0 0 550 70"
            width={400}
            height={50}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="20" y="19" rx="0" ry="0" width="25%" height="22%" />
            <rect x="20" y="45" rx="0" ry="0" width="50%" height="22%" />
        </ContentLoader>
    );
};

export default MessageBoardSkeleton;
