import React from 'react';
import PropTypes from 'prop-types';
import TabContent from './TabContent';


const WarrantyTabContent = (props) => {
    const { tab, active } = props;
    return <TabContent tab={tab} active={active}  />;
};

WarrantyTabContent.defaultProps = {};

WarrantyTabContent.propTypes = {
    tab: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export default WarrantyTabContent;
