import ActionTypes from 'redux/actions/ActionTypes';

export function createShowFeedbackModalAction(rating = null) {
    return {
        type: ActionTypes.ShowFeedbackModal,
        payload: rating,
    };
}

export function createHoverFeedbackRatingAction(value) {
    return {
        type: ActionTypes.FeedbackHoverRating,
        payload: value,
    };
}

export function createCloseFeedbackModalAction() {
    return {
        type: ActionTypes.CloseFeedbackModal,
    };
}

export function createSetFeedbackRatingAction(rating) {
    return {
        type: ActionTypes.SetFeedbackRating,
        payload: rating,
    };
}

export function createSetFeedbackCommentsAction(comments) {
    return {
        type: ActionTypes.SetFeedbackComments,
        payload: comments,
    };
}

export function createSubmitFeedbackAction(formData) {
    return {
        type: ActionTypes.SubmitFeedbackForm,
        payload: formData,
    };
}

export function createSubmitFeedbackErrorAction(errors) {
    return {
        type: ActionTypes.SubmitFeedbackFormError,
        payload: errors,
    }
}
