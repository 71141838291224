import React from 'react';
import PropTypes from 'prop-types';
import TabContentImage from 'components/LibraryElements/Tabs/TabContentImage';
import './AccordionCard.scss';
import SanitizeService from 'services/SanitizeService';

export default function AccordionCard(props) {
    const { imageUrl, videoUrl, altText, phaseTitle, content } = props;
    return (
        <div
            className="accordion-card-container"
            data-testid="accordion-card-container"
        >
            <div className="accordion-card-image">
                {(imageUrl || videoUrl) && (
                    <TabContentImage
                        imageLink={imageUrl}
                        videoLink={videoUrl}
                        altText={altText}
                        phaseTitle={phaseTitle}
                    />
                )}
            </div>
            <div
                className="accordion-card-content"
                dangerouslySetInnerHTML={{ __html: SanitizeService.sanitize(content) }}
            />
        </div>
    );
}

AccordionCard.defaultProps = {
    imageUrl: null,
    videoUrl: null,
    content: null,
};

AccordionCard.propTypes = {
    imageUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    content: PropTypes.string,
};
