import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { isIOS, isAndroid } from 'react-device-detect';
import { Button, ButtonStyles, Text, Icon } from 'ComponentLibrary';
import Constants from 'Constants';
import { useDispatch } from 'react-redux';
import {
    createNotificationPreferencesSetEvent,
    createSavePushSubscriptionToStateEvent,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import PWAService from 'services/PWAService';
import NotificationPreferencesForm from './NotificationsPreferencesForm';
import slide1ImgAndroid from '../../assets/Slide1-mobile-Android.png';
import slide1ImgIOS from '../../assets/Slide1-mobile-ios.png';
import slide3Img from '../../assets/Slide3-mobile.png';
import slide1ImgLogo from '../../assets/Slide1-mi-logo.png';

const MobileWelcome = (props) => {
    const { submitWelcomeScreen } = props;
    const [notficationsData, setNotificationsData] = useState({});
    const dispatch = useDispatch();
    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
        draggable: false,
        swipeToSlide: false,
        swipe: false,
    };

    const customSlider = useRef();

    const gotoNext = () => {
        customSlider.current.slickNext();
    };

    let headerOnScreen1;
    let subTextOnScreen1;
    let slide1Img;
    const headerOnScreen3 = Constants.welcome.mobile.common.screen3.mainText;
    const subTextOnScreen3 = Constants.welcome.mobile.common.screen3.subText;
    if (isAndroid) {
        headerOnScreen1 = Constants.welcome.mobile.android.screen1.mainText;
        subTextOnScreen1 = Constants.welcome.mobile.android.screen1.subText;
        slide1Img = slide1ImgAndroid;
    } else if (isIOS) {
        headerOnScreen1 = Constants.welcome.mobile.ios.screen1.mainText;
        subTextOnScreen1 = Constants.welcome.mobile.ios.screen1.subText;
        slide1Img = slide1ImgIOS;
    } else {
        headerOnScreen1 = Constants.welcome.mobile.common.screen1.mainText;
        subTextOnScreen1 = Constants.welcome.mobile.common.screen1.subText;
        slide1Img = slide1ImgLogo;
    }

    const headerOnScreen2 = Constants.welcome.mobile.common.screen2.mainText;
    const subTextOnScreen2 = Constants.welcome.mobile.common.screen2.subText;
    return (
        <Slider {...settings} ref={customSlider}>
            <div className="welcome-screen screen1" data-testid="screen1">
                <div className="welcome-image-wrapper">
                    <img src={slide1Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            {headerOnScreen1}
                        </Text>
                        <Text type="block" variant="medium">
                            {subTextOnScreen1}
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={gotoNext}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen2" data-testid="screen2">
                <div className="welcome-content-wrapper welcome-content-wrapper--no-image">
                    <div className="welcome-content">
                        <div className="welcome-content__icon">
                            <Icon icon="alert" circle="portal-bg-color" size={2.5} />
                            <div className="welcome-content__alert-dot"></div>
                        </div>
                        <Text type="block" variant="large">
                            {headerOnScreen2}
                        </Text>
                        <Text type="block" variant="medium">
                            {subTextOnScreen2}
                        </Text>
                        <NotificationPreferencesForm
                            uniqueId="3"
                            onUpdate={(data) => {
                                setNotificationsData(data);
                            }}
                        />
                        <Button
                            selected
                            as="button"
                            testId="notifications-preferences-button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={async (e) => {
                                if (notficationsData.updateFlag) {
                                    dispatch(
                                        createNotificationPreferencesSetEvent(
                                            notficationsData
                                        )
                                    );
                                }
                                const subscription = await PWAService.getPushNotificationSubscription();
                                if (
                                    notficationsData.updateFlag &&
                                    notficationsData.enablePush
                                ) {
                                    PWAService.subscribeToPushNotifications(
                                        notficationsData
                                    );
                                } else if(
                                    subscription &&
                                    subscription.endpoint
                                ) {
                                    PWAService.savePushSubscription(
                                        subscription
                                    ).then((res) => {
                                        if (
                                            res.data.notificationSubscriptionId
                                        ) {
                                            dispatch(
                                                createSavePushSubscriptionToStateEvent(
                                                    res.data.notificationSubscriptionId,
                                                    subscription.endpoint,
                                                )
                                            );
                                        }
                                    }).catch(() => {

                                    })
                                }
                                gotoNext();
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen3" data-testid="screen3">
                <div className="welcome-image-wrapper">
                    <img src={slide3Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            {headerOnScreen3}
                        </Text>
                        <Text type="block" variant="medium">
                            {subTextOnScreen3}
                        </Text>
                        <Button
                            selected
                            as="button"
                            testId="desktop-welcome-done-button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.done}
                            submit
                            onClick={submitWelcomeScreen}
                        />
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default MobileWelcome;
