import React from 'react';
import PropTypes from 'prop-types';
import './ResourceArticles.scss';
import { Icon, IconTypes, FlexibleLink } from 'ComponentLibrary';
import Utils from 'utilities/Utils';

const ResourceArticles = (props) => {
    const { articles, title, testId } = props;
    if (!articles || articles.length === 0) return null;
    const sectionTitle = title;
    return (
        <section className="resources" data-testid={testId}>
            <h2 className="resources__section-title">{title}</h2>
            <ul
                className="resources__article-list"
                data-testid={`${testId}__list`}
            >
                {articles.map((article, index) => {
                    const {
                        title,
                        body,
                        callToActionLink,
                        callToActionText,
                        callToActionLinkLink,
                    } = article;

                    if (!title || (!callToActionLinkLink && (!callToActionLink || !callToActionText))) {
                        return null;
                    }

                    const hasInternalLinkObject = callToActionLinkLink && callToActionLinkLink.target !==  '_blank' && callToActionLinkLink.url.indexOf('/') === 0;

                    return (
                        <li
                            data-testid={`${testId}__article-${index + 1}`}
                            className="resources__article-list-item"
                            key={Utils.slugify(`${sectionTitle}-${title}`)}
                        >
                            <article className="resources-article">
                                {hasInternalLinkObject ? (
                                    <FlexibleLink
                                        isInternal
                                        classList="resources-article__link"
                                        testId="resources-article__internal-link"
                                        linkDetails={callToActionLinkLink}
                                    >
                                        <h1 className="resources-article__title">
                                            {title}
                                        </h1>
                                        <p className="resources-article__body">
                                            {body}
                                        </p>
                                        <span className="resources-article__cta">
                                            {callToActionLinkLink.text}{' '}
                                            <Icon
                                                withText
                                                icon={IconTypes.DoubleArrow}
                                            />
                                        </span>
                                    </FlexibleLink>
                                ) : (
                                    <FlexibleLink
                                        isInternal={false}
                                        classList="resources-article__link"
                                        testId="resources-article__external-link"
                                        linkDetails={callToActionLinkLink || {url: callToActionLink}}
                                        rel="noopener noreferrer"
                                    >
                                         <h1 className="resources-article__title">
                                            {title}
                                        </h1>
                                        <p className="resources-article__body">
                                            {body}
                                        </p>
                                        <span className="resources-article__cta">
                                            {callToActionLinkLink && callToActionLinkLink.text ? callToActionLinkLink.text : callToActionText}{' '}
                                            <Icon
                                                withText
                                                icon={IconTypes.DoubleArrow}
                                            />
                                        </span>
                                    </FlexibleLink>
                                )}
                            </article>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

ResourceArticles.defaultProps = {
    testId: null,
};
ResourceArticles.propTypes = {
    articles: PropTypes.array.isRequired,
    testId: PropTypes.string,
};

export default ResourceArticles;
