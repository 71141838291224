import React from 'react';
import { Link } from 'react-router-dom';
import Utils from 'utilities/Utils';

const TabLink = (props) => {
    const { title, href, status } = props;
    return (
        <li key={Utils.slugify(title)} className="tablink-wrapper" data-testid="tablink-wrapper">
            <Link className={`tablink tablink--${Utils.slugify(status)}`} to={href}>
                <span className="tablink__label">{title}</span>
                <span className={`tablink__status tablink__status--${Utils.slugify(status)}`}>{Utils.translateStatus(status)}</span>
            </Link>
        </li>
    );
};

export default TabLink;
