import ActionTypes from 'redux/actions/ActionTypes';

export function createGetDivisionsAction(contactData) {
    return {
        type: ActionTypes.GetDivisions,
        payload: contactData,
    };
}

export function createGetAssociatedBuyers() {
    return {
        type: ActionTypes.GetAssociatedBuyers
    }
}

export function createClearSearchResultsAction(mode = null) {
    return {
        type: ActionTypes.ClearSearchResults,
        payload: {
            mode,
        },
    }
}

export function createGetCommunitiesAction(divisionId) {
    return {
        type: ActionTypes.GetCommunities,
        payload: divisionId,
    };
}

export function createSearchByAddressAction(searchText) {
    return {
        type: ActionTypes.SearchForLotByAddress,
        payload: {
            searchText,
        },
    };
}

export function createSearchParamUpdateEventAction(value, field) {
    return {
        type: ActionTypes.UpdateLotSearchParam,
        payload: {
            value,
            field,
        },
    };
}

export function createLotSearchSortAction(sortBy, sortOrder) {
    return {
        type: ActionTypes.SortLotSearchResults,
        payload: {
            sortBy,
            sortOrder,
        },
    };
}

export function createSearchByNameAction(divisionId, firstName, lastName) {
    return {
        type: ActionTypes.SearchForLotByName,
        payload: {
            divisionId,
            firstName,
            lastName,
        },
    };
}
