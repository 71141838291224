import React from 'react';
import './LinkBox.scss';
import { FlexibleLink } from 'ComponentLibrary';
import PropTypes from 'prop-types';

const haveButton = (title, link, linkObject) =>
    linkObject || (title && title.length > 0 && link && link.length > 0);

const isInternalLinkObject = (linkObject) =>
    linkObject && linkObject.target !==  '_blank' && linkObject.url.indexOf('/') === 0

const LinkBox = (props) => {
    const { links, slug, stackButtons } = props;
    const {
        title,
        body,
        extraBody,
        secondaryButtonText,
        secondaryButtonLink,
        secondaryButtonLinkLink,
        primaryButtonLink,
        primaryButtonText,
        primaryButtonLinkLink,
        show,
    } = links;

    if (!title || show === false) {
        return null;
    }
    const havePrimaryButton = haveButton(
        primaryButtonText,
        primaryButtonLink,
        primaryButtonLinkLink
    );
    const haveSecondaryButton = haveButton(
        secondaryButtonText,
        secondaryButtonLink,
        secondaryButtonLinkLink
    );
    if (!havePrimaryButton && !haveSecondaryButton) {
        return null;
    }
    return (
        <section className="link-box" data-testid="link-box">
            <div className="link-box__content">
                <img
                    className="link-box__bg"
                    alt=""
                    sizes="(min-with: 764px) 30vw, 100vw"
                    lazy="true"
                    srcSet="/images/link-box/link-box-bg-1x.jpg 400w, /images/link-box/link-box-bg-2x.jpg 800w, /images/link-box/link-box-bg-3x.jpg 1200w"
                />
                <h2 className="link-box__title">{title}</h2>
                <p className="link-box__body">{body}</p>
                <p className="link-box__body">{extraBody}</p>
                {(havePrimaryButton || haveSecondaryButton) && (
                    <div className={`link-box__links  ${stackButtons && 'link-box__links--stack'}`} data-testid="link-box__links">
                        {
                            isInternalLinkObject(primaryButtonLinkLink) || (!primaryButtonLinkLink && havePrimaryButton && primaryButtonLink && primaryButtonLink.indexOf('[INTERNAL]/') === 0) ? (
                                <FlexibleLink
                                    isInternal
                                    handleClick={(e) => {
                                        window.scrollTo(0, 0);
                                    }}
                                    classList={`link-box__link ${stackButtons && 'link-box__link--stack'}`}
                                    testId="internal-primary-button-link"
                                    linkBox={`1st-${slug}`}
                                    linkDetails={
                                        primaryButtonLinkLink || {
                                            url: primaryButtonLink.replace('[INTERNAL]', ''),
                                            title,
                                        }
                                    }
                                >
                                    <span
                                        className={`link-box__text`}
                                    >
                                        {primaryButtonLinkLink && primaryButtonLinkLink.text ? primaryButtonLinkLink.text : primaryButtonText}
                                    </span>
                                </FlexibleLink>
                            ) : (primaryButtonLinkLink && !isInternalLinkObject(primaryButtonLinkLink) || (!isInternalLinkObject(primaryButtonLinkLink) && havePrimaryButton && primaryButtonLink && primaryButtonLink.indexOf('[INTERNAL]/') < 0)) && (
                                <FlexibleLink
                                    isInternal={false}
                                    classList={`link-box__link ${stackButtons && 'link-box__link--stack'}`}
                                    testId="primary-button-link"
                                    linkBox={`1st-${slug}`}
                                    rel="noopener noreferrer"
                                    linkDetails={
                                        primaryButtonLinkLink || {
                                            url: primaryButtonLink,
                                            title,
                                        }
                                    }
                                >
                                    <span
                                        className={`link-box__text`}
                                    >
                                        {primaryButtonLinkLink && primaryButtonLinkLink.text ? primaryButtonLinkLink.text : primaryButtonText}
                                    </span>
                                </FlexibleLink>
                            )
                        }
                        {
                            isInternalLinkObject(secondaryButtonLinkLink) || (!secondaryButtonLinkLink && haveSecondaryButton && secondaryButtonLink && secondaryButtonLink.indexOf('[INTERNAL]/') === 0) ? (
                                <FlexibleLink
                                    isInternal
                                    handleClick={(e) => {
                                        window.scrollTo(0, 0);
                                    }}
                                    classList={`link-box__link link-box__link--secondary ${stackButtons && 'link-box__link--stack'}`}
                                    testId="internal-secondary-button-link"
                                    linkBox={`2nd-${slug}`}
                                    linkDetails={
                                        secondaryButtonLinkLink || {
                                            url: secondaryButtonLink.replace('[INTERNAL]', ''),
                                            title,
                                        }
                                    }
                                >
                                    <span
                                        className={`link-box__text`}
                                    >
                                        {secondaryButtonLinkLink && secondaryButtonLinkLink.text ? secondaryButtonLinkLink.text : secondaryButtonText}
                                    </span>
                                </FlexibleLink>
                            ) : (secondaryButtonLinkLink && !isInternalLinkObject(secondaryButtonLinkLink) || (!isInternalLinkObject(secondaryButtonLinkLink) && haveSecondaryButton && secondaryButtonLink && secondaryButtonLink.indexOf('[INTERNAL]/') < 0)) && (
                                <FlexibleLink
                                    isInternal={false}
                                    classList={`link-box__link link-box__link--secondary ${stackButtons && 'link-box__link--stack'}`}
                                    testId="secondary-button-link"
                                    linkBox={`2nd-${slug}`}
                                    rel="noopener noreferrer"
                                    linkDetails={
                                        secondaryButtonLinkLink || {
                                            url: secondaryButtonLink,
                                            title,
                                        }
                                    }
                                >
                                    <span
                                        className={`link-box__text`}
                                    >
                                        {secondaryButtonLinkLink && secondaryButtonLinkLink.text ? secondaryButtonLinkLink.text : secondaryButtonText}
                                    </span>
                                </FlexibleLink>
                            )
                        }
                    </div>
                )}
            </div>
        </section>
    );
};

LinkBox.defaultProps = {
    stackButtons: false,
};

LinkBox.propTypes = {
    links: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    stackButtons: PropTypes.bool,
};

export default LinkBox;
