import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Component: Title
// This component should be used by every page to set the browser's title appropriately
const Title = (props) => {
    const { title } = props;
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <React.Fragment />;
};

Title.defaultProps = {
    title: '',
};

Title.propTypes = {
    title: PropTypes.string,
};

export default Title;
