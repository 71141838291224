import ActionTypes from 'redux/actions/ActionTypes';
import { sortSelections } from './DesignSelectionsTransform';

export const initialState = {
    selections: null,
    originalSelections: null,
    categories: null,
    sortOrder: null,
    showItemModal: false,
    itemModalProduct: null,
    activeCategory: 'All',
    activeItemModalSubProduct: null,
    hasItemError: false,
};

const DesignSelectionsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case ActionTypes.GetDesignSelectionsSuccess:
            return {
                ...state,
                selections: action.payload.selectionsResponse,
                originalSelections: action.payload.selectionsResponse,
                categories: action.payload.categories,
            }

        case ActionTypes.GetDesignSelectionsError:
            return {
                ...state,
                selections: [],
                originalSelections: [],
                categories: [],
            }

        case ActionTypes.SortDesignSelections:
            return {
                ...state,
                selections: sortSelections(state.selections, action.payload),
                sortOrder: action.payload,
            }

        case ActionTypes.GetDesignSelectionsItemData:
            return {
                ...state,
                showItemModal: true,
                hasItemError: false,
            }

        case ActionTypes.GetDesignSelectionsItemDataSuccess:
            return {
                ...state,
                itemModalProduct: action.payload,
                activeItemModalSubProduct: action.payload.products.length > 0 ? action.payload.products[0] : null,
            }

        case ActionTypes.GetDesignSelectionsItemDataError:
            return {
                ...state,
                itemModalProduct: null,
                activeItemModalSubProduct: null,
                showItemModal: false,
                hasItemError: true,
            }

        case ActionTypes.HideDesignSelectionsModal:
            return {
                ...state,
                showItemModal: false,
                itemModalProduct: null,
                activeItemModalSubProduct: null,
            }

        case ActionTypes.UpdateDesignSelectionsTableFilter:
            return {
                ...state,
                activeCategory: action.payload,
                selections:
                    action.payload !== 'All'
                        ? sortSelections(
                              state.selections,
                              'description-ascending'
                          )
                    : state.originalSelections,
                sortOrder:
                    action.payload !== 'All' ? 'description-ascending' : null,
            }

        case ActionTypes.SetItemProductModalSubProduct:
            return {
                ...state,
                activeItemModalSubProduct: action.payload,
            }

        case ActionTypes.LogOut:
        case ActionTypes.SignOut:
        case ActionTypes.ChangeActiveLot:
        case ActionTypes.OpenFindLotPage:
        case ActionTypes.LotIdSuccess:
            return initialState;

        default:
            return state;
    }
};

export default DesignSelectionsReducer;
