import React from 'react';
import PropTypes from 'prop-types';
import TabContent from './TabContent';

const PreContructionTabContent = (props) => {
    const { tab, active } = props;
    return <TabContent tab={tab} active={active} />;
};

PreContructionTabContent.defaultProps = {};

PreContructionTabContent.propTypes = {
    tab: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export default PreContructionTabContent;
