import ApiService from './ApiService';

const WarrantyService = {
    getCategories: (options, service = ApiService()) => service
            .get('/warranty/categories', true)
            .then((response) => response)
            .catch((error) => error.response),
    getCategoryData: (options, service = ApiService()) => service
            .get(`/warranty/subcategories?categoryId=${options.categoryId}`, true)
            .then((response) => response)
            .catch((error) => error.response),
    getServiceRequestId: (options, service = ApiService()) => {
            if(options.accessToken) {
                    return service
                        .postWithPassedToken('/Warranty/servicerequest', options.accessToken, options)
                        .then((response) => response)
                        .catch((error) => error.response);
            }
            return service
                .post('/Warranty/servicerequest', options, false)
                .then((response) => response)
                .catch((error) => error.response);
    },
    submitClaim: (options, service = ApiService()) => service
            .post('/Warranty/serviceitem', options, false)
            .then((response) => response)
            .catch((error) => error.response),
    completeServiceRequest: (options, service = ApiService()) => service
        .post('/Warranty/servicerequest/complete', options, false)
        .then((response) => response)
        .catch((error) => error.response),
};

export default WarrantyService;
