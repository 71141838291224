import { options } from 'sanitize-html';
import ApiService from './ApiService.js';

const RedirectService = {
  getEnvisionUrl: (options, service = ApiService()) =>
    service
      .get(`/DesignCenter/envision?lotId=${options.lotId}`, true)
      .then((response) => response)
      .catch((error) => error.response),
  getWellsFargoUrl: (options, service = ApiService()) =>
    service
      .post('/Deposits/initiateachdeposit', options, false)
      .then((response) => response)
      .catch((error) => error.response),
  getWarrantyUrl: (options, service = ApiService()) =>
    service
      .get(`/Progress/phases/warranty/manual?lotId=${options.lotId}`, true)
      .then((response) => response)
      .catch((error) => error.response),
};

export default RedirectService;
