import React from 'react';
import {
  triggerEnvisionExternalRedirectAction,
  triggerWarrantyExternalRedirectAction,
  triggerWellsFargoExternalRedirectAction,
} from 'redux/actions/ExternalRedirect/ExternalRedirectActions';

import Login from 'pages/Login';
import Invite from 'pages/Invite';
import FindLot from 'pages/FindLot';
import Dashboard from 'pages/Dashboard';
import ExternalRedirect from 'pages/ExternalRedirect';
import Missing from 'pages/404';
const LazyLoadWithTimeout = (moduleImport) =>
  React.lazy(() => {
    return Promise.all([
      moduleImport(),
      new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([module]) => module);
  });

const LotAssociation = LazyLoadWithTimeout(() =>
  import('pages/LotAssociation')
);
const Progress = LazyLoadWithTimeout(() => import('pages/Progress'));
const UserSettings = LazyLoadWithTimeout(() => import('pages/UserSettings'));
const Contacts = LazyLoadWithTimeout(() => import('pages/Contacts'));
const Documents = LazyLoadWithTimeout(() => import('pages/Documents'));
const Checklist = LazyLoadWithTimeout(() =>
  import('App/ContentComponents/Checklist')
);
const Warranty = LazyLoadWithTimeout(() => import('pages/Warranty'));
const Deposits = LazyLoadWithTimeout(() => import('pages/Deposits'));
const PhotoGallery = LazyLoadWithTimeout(() => import('pages/PhotoGallery'));
const DivisionDashboard = LazyLoadWithTimeout(() =>
  import('pages/DivisionDashboard')
);
const DesignSelections = LazyLoadWithTimeout(() =>
  import('pages/DesignSelections')
);
const LotDashboard = LazyLoadWithTimeout(() => import('pages/LotDashboard'));

// eslint-disable-next-line max-len
// This is the configuration object that is used by the <BuyerPortalRouter /> component to automatically configure each declared route.
// eslint-disable-next-line max-len
// Each route requires a path string and a component reference. Optionally, a nested routes property can be created to achieve sub-routes.

const routeConfig = [
  {
    path: '/',
    component: Login,
    page: 'login',
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    page: 'login',
  },
  {
    path: '/invite',
    component: Invite,
    page: 'invite',
  },
  {
    path: '/find-lot',
    component: FindLot,
    page: 'findLot',
  },
  {
    path: '/dashboard/checklist/:taskId?',
    component: Dashboard,
    page: 'dashboard',
  },
  {
    path: '/dashboard/:tab?/:constructionStepId?',
    component: Dashboard,
    page: 'dashboard',
  },
  {
    path: '/lot-association',
    component: LotAssociation,
    page: 'lotAssociation',
  },
  {
    path: '/photos/:hash?',
    component: PhotoGallery,
    page: 'dashboard',
  },
  {
    path: '/profile',
    component: UserSettings,
    page: 'dashboard',
  },
  {
    path: '/contacts',
    component: Contacts,
    page: 'dashboard',
  },
  {
    path: '/documents',
    component: Documents,
    page: 'dashboard',
  },
  {
    path: '/progress/:tab?/:constructionStepId?',
    component: Progress,
    page: 'dashboard',
  },
  {
    path: '/checklist/:taskId?',
    component: Checklist,
    page: 'dashboard',
    extraProps: {
      mode: 'page',
    },
  },
  {
    path: '/division-dashboard',
    component: DivisionDashboard,
    page: 'dashboard',
    extraProps: {
      adminOnly: true,
    },
  },
  {
    path: '/lot-dashboard',
    component: LotDashboard,
    page: 'dashboard',
    extraProps: {
      adminOnly: true,
    },
  },
  {
    path: '/envision',
    component: ExternalRedirect,
    page: 'externalRedirect',
    extraProps: {
      actionCreator: triggerEnvisionExternalRedirectAction,
    },
  },
  {
    path: '/wellsfargo/:depositId',
    component: ExternalRedirect,
    page: 'externalRedirect',
    extraProps: {
      actionCreator: triggerWellsFargoExternalRedirectAction,
    },
  },
  {
    path: '/warranty/manual',
    component: ExternalRedirect,
    page: 'externalRedirect',
    extraProps: {
      actionCreator: triggerWarrantyExternalRedirectAction,
    },
  },
  {
    path: '/designcenter/confirmedselections',
    component: DesignSelections,
    page: 'dashboard',
  },
  {
    path: '/financing/loanapplication',
    component: ExternalRedirect,
    page: 'externalRedirect',
    extraProps: {
      redirectUrl: '{$financingLink}',
    },
  },
  {
    path: '/warranty',
    component: Warranty,
    page: 'dashboard',
  },
  {
    path: '/deposits',
    component: Deposits,
    page: 'dashboard',
  },
  {
    path: '/design-selections',
    component: DesignSelections,
    page: 'dashboard',
  },
  {
    path: '*',
    component: Missing,
    page: 'login',
  },
];

export default routeConfig;
