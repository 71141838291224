import ApiService from './ApiService';

const TaskService = {
    getTasks: (options, service = ApiService()) =>
        service
            .get(`/Tasks?lotId=${options.lotId}`, true)
            .then((response) => response)
            .catch((error) => error.response),
    updateTasks: (options, service = ApiService()) =>
        service
            .putWithTokenHeader('/Tasks/batchComplete', options)
            .then((response) => response)
            .catch((error) => error.response),
    addTask: (options, service = ApiService()) =>
        service
            .post('/Tasks/personal', options, false)
            .then((response) => response)
            .catch((error) => error.response),
    removeTask: (options, service = ApiService()) =>
        service
            .delete(`/Tasks/personal/${options.taskId}`)
            .then((response) => response)
            .catch((error) => error.response),
    editTask: (options, service = ApiService()) =>
        service
            .putWithTokenHeader(
                `/Tasks/personal/${options.taskId}`,
                options.taskData
            )
            .then((response) => response)
            .catch((error) => error.response),
    getDivisionTasks: (options, service = ApiService()) =>
        service
            .get(`/Tasks/samples?lotId=${options.lotId}`, options)
            .then((response) => response)
            .catch((error) => error),
};

export default TaskService;
