import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import './LoadingScreen.scss';
import { CSSTransition } from 'react-transition-group';

import wordmark from 'icons/wordmark.svg';
import logo from 'icons/logo-transparent.png';

const phrases = [
    'Digging the foundation',
    'Mixing concrete',
    'Framing the house',
    'Wiring electric',
    'Installing pipes',
    'Nailing roof shingles',
    'Hanging drywall',
    'Laying flooring',
    'Installing cabinets',
];

const LoadingScreen = (props) => {
    const { exit, minimumTime, customPhrasing, color } = props;
    let { variant } = props;
    if (color === 'gray') {
        variant = 'external-redirect';
    }
    const [haveReachedMinimumTime, setHaveReachedMinimumTime] = useState(false);
    const [index, setIndex] = useState(0);
    const phrasingToUse = customPhrasing.length > 0 ? customPhrasing : phrases;

    useEffect(() => {
        const timer = setTimeout(() => {
            setHaveReachedMinimumTime(true);
        }, minimumTime);
        return () => clearTimeout(timer);
    }, [minimumTime]);

    useEffect(() => {
        const indexTimer = setTimeout(() => {
            let val = index + 1;
            if (val > phrasingToUse.length - 1) {
                val = 0;
            }
            setIndex(val);
        }, 1000);
        return () => clearTimeout(indexTimer);
    }, [index, phrasingToUse]);

    const doExit = exit && haveReachedMinimumTime;
    const onLogout = useLocation().search.indexOf('logout=true') >= 0;
    if (onLogout) return null;
    return (
        <CSSTransition
            unmountOnExit
            in={!doExit}
            exit={doExit}
            timeout={500}
            classNames="fade-load-screen"
            data-testid="loading-screen"
        >
            <div className={`loading-screen ${variant}`}>
                <div className="loading-screen__inner-wrapper">
                    <div className="loading-screen__centered-content">
                        <div className="lds-ring">
                            <div />
                            <div />
                            <div />
                            <div />
                            <span className="loading-screen__img">
                                <img
                                    className="loading-screen__logo"
                                    width="60"
                                    alt=""
                                    height="51"
                                    src={logo}
                                />
                            </span>
                            <span className="loading-screen__img">
                                <img
                                    className="loading-screen__wordmark"
                                    width="170"
                                    height="20"
                                    src={wordmark}
                                    alt=""
                                />
                            </span>
                        </div>
                        <p className="loading-screen__tagline">
                            {phrasingToUse[index]}…
                        </p>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

LoadingScreen.defaultProps = {
    minimumTime: 0,
    variant: null,
    customPhrasing: [],
    color: null,
};

LoadingScreen.propTypes = {
    exit: PropTypes.bool.isRequired,
    minimumTime: PropTypes.number,
    variant: PropTypes.string,
    color: PropTypes.string,
    customPhrasing: PropTypes.array,
};

export default LoadingScreen;
