import sanitizeHtml from 'sanitize-html';

const SanitizeService = {
    sanitize: (content) =>
        sanitizeHtml(content, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'iframe', 'img']),
        allowedAttributes: {
            '*': [
                'href',
                'style',
                'alt',
                'width',
                'height',
                'rel',
                'src',
                'class',
                'target',
            ],
            // style/alt'
        },
    }),
};

export default SanitizeService;
