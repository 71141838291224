import ApiService from './ApiService.js';
import { withCurrentLotId } from './ServiceHelpers.js';

const ContactService = {
    getDivisionContacts: (options, service = ApiService()) => service
            .get("/Contacts/division", {params: {
                lotId: withCurrentLotId('$lotId'),
            }})
            .then((response) => response)
            .catch((error) => ({})),
    getAll: (options, service = ApiService()) => service
            .get(withCurrentLotId('/Contacts?lotId=$lotId'), true)
            .then((response) => {
                response.data = {
                    ...response.data,
                };
                return response;
            })
            .catch((error) => ({
                ...error.response,
            })),
    get: (options, service = ApiService()) => service
            .get(
                withCurrentLotId(
                    `/progress/phases/${options.tabSlug}/contacts?lotId=$lotId`
                ),
                true
            )
            .then((response) => {
                response.data = {
                    ...response.data,
                    lotId: withCurrentLotId('$lotId'),
                    tabSlug: options.tabSlug,
                };
                return response;
            })
            .catch((error) => ({
                ...error.response,
                lotId: withCurrentLotId('$lotId'),
                tabSlug: options.tabSlug,
            })),
};

export default ContactService;
