import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    isOpen: false,
    videoLink: '',
};

const VideoModalReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
    case ActionTypes.OpenVideoModal:
        return {
            ...state,
            isOpen: true,
            videoLink: action.payload.videoLink,
            title: action.payload.title,
        };

    case ActionTypes.CloseVideoModal:
        return {
            ...state,
            isOpen: false,
            videoLink: '',
        };

    default:
        return state;
    }
};

export default VideoModalReducer;

