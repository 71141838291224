import React from 'react';
import { useDispatch } from 'react-redux';
import { createCloseVideoModalAction } from 'redux/actions/VideoModal/VideoModalActions';
import './VideoModal.scss';
import { Icon, MIModal } from 'ComponentLibrary';

export default function VideoModal(props) {
    const { isOpen, videoLink, title } = props;
    const dispatch = useDispatch();
    const videoTitle = `${title} Header Video`;

    const iframe = `<iframe ${
        videoLink.indexOf('html5=1') >= 0 || videoLink.indexOf('html5=true') >= 0
            ? 'sandbox="allow-scripts allow-same-origin"'
            : ''
    } id="youtube-modal-video" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="${videoTitle}" width="640" height="360" src="${videoLink}" data-gtm-yt-inspected-10506871_53="true"></iframe>`;
    const handleCloseVideoModal = () => {
        dispatch(createCloseVideoModalAction());
    };

    return (
        <React.Fragment>
            <MIModal
                show={isOpen}
                closeModal={handleCloseVideoModal}
                customClass="video-modal"
                isHeader
                modalSize="xl"
                centered="true"
                isVideo
            >
                <button
                    type="button"
                    className="video-close-icon"
                    data-testid="video-close-icon"
                    onClick={handleCloseVideoModal}
                >
                    <Icon color="black" icon="largePlus" size={2} />
                </button>
                <div
                    className="youtube-iframe-container"
                    dangerouslySetInnerHTML={{ __html: iframe }}
                />
            </MIModal>
        </React.Fragment>
    );
}
