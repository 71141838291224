import React from 'react';
import { Button, ButtonStyles } from 'ComponentLibrary';
import Constants from 'Constants';
import './PersonalTaskControls.scss';

const PersonalTaskControls = (props) => {
    const { handleEditTask, handleRemoveTask, disableAllControls, disableEdit } = props;
    const personalTaskControlsText = Constants.checklistModal.taskControls;

    return (
        <div className="personal-task-controls" data-testid="personal-task-controls">
            <Button
                as="button"
                icon="pencil"
                testId="task-edit-button"
                disabled={disableAllControls || disableEdit}
                text={personalTaskControlsText.editText}
                style={ButtonStyles.TextOnlyLink}
                bold
                onClick={handleEditTask}
            />

            <Button
                as="button"
                icon="trash"
                testId="task-remove-button"
                disabled={disableAllControls}
                text={personalTaskControlsText.removeText}
                style={ButtonStyles.TextOnlyLink}
                bold
                onClick={handleRemoveTask}
            />
        </div>
    );
};

export default PersonalTaskControls;
