import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import {
  useAwaitTokenHasBeenRefreshed,
  useIsAdmin,
} from 'utilities/HooksAndSelectors';
import { createSaveUsersDestinationForAfterLoginAction } from 'redux/actions/Login/LoginActions';

const WithRedirect = (props) => {
  const { currentPage, page, children, adminOnly } = props;
  const refreshed = useAwaitTokenHasBeenRefreshed();
  const user = useSelector((reduxState) => reduxState.userState);
  const userDetails = user ? user.userDetails : null;
  const afterSuccessfulLogin = userDetails
    ? userDetails.afterSuccessfulLogin
    : null;
  const location = useLocation();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  if (!isAdmin && adminOnly) {
    return <Redirect to='/dashboard' />;
  }
  if (!refreshed) {
    return null;
  }

  const { pathname } = location;
  const isExternalRedirect =
    pathname.indexOf('/financing/loanapplication') >= 0 ||
    pathname.indexOf('/envision') >= 0 ||
    pathname.indexOf('/designcenter/confirmedselections') >= 0 ||
    pathname.indexOf('/wellsfargo') >= 0;

  if (
    !isExternalRedirect &&
    currentPage !== page &&
    refreshed &&
    page !== 'admin'
  ) {
    switch (currentPage) {
      case 'dashboard':
        const whereIAmGoing =
          afterSuccessfulLogin !== null &&
          afterSuccessfulLogin !== undefined &&
          afterSuccessfulLogin !== '' &&
          afterSuccessfulLogin !== '/'
            ? afterSuccessfulLogin
            : `/dashboard${
                location.pathname.startsWith('/dashboard')
                  ? location.pathname.substr(10)
                  : ''
              }`;
        return <Redirect to={whereIAmGoing} />;
      case 'findLot':
        return <Redirect to='/find-lot' />;
      case 'lotAssociation':
        return <Redirect to='/lot-association' />;
      default:
        if (
          location.pathname + window.location.hash !== afterSuccessfulLogin &&
          location.pathname.indexOf('login') < 0
        ) {
          dispatch(
            createSaveUsersDestinationForAfterLoginAction(
              location.pathname + window.location.hash
            )
          );
        }
        return <Redirect to={`/login${location.search}`} />;
    }
  }

  return children;
};

WithRedirect.propTypes = {
  adminOnly: PropTypes.bool,
};
WithRedirect.defaultProps = {
  adminOnly: false,
};
export default WithRedirect;
