import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOpenNotificationsDropdownAction } from 'redux/actions/Notifications/NotificationsActions';
import './DashboardNotificationsButton.scss';
import { Text, Icon } from 'ComponentLibrary';
import Constants from 'Constants';
import DashboardNotificationsButtonSkeleton from './DashboardNotificationsButtonSkeleton';

export default function DashboardNotificationsButton(props) {
    const { skeletonMode } = props;
    const dispatch = useDispatch();
    const notificationsState = useSelector((state) => state.notificationsState);
    const {
        unreadNotifications,
    } = notificationsState;

    const handleNotificationsToggle = () => {
        dispatch(createOpenNotificationsDropdownAction());
        return false;
    };

    const hasUnreadNotifications = Array.isArray(unreadNotifications) ? unreadNotifications.length > 0 : false;

    return (
        <div className="dashboard-notifications-button-container" data-testid="dashboard-notifications-button-container">
            {skeletonMode ? (
                <DashboardNotificationsButtonSkeleton />
            ) : (
                <button className="dashboard-notifications-button" data-testid="dashboard-notifications-button" onClick={handleNotificationsToggle}>
                    <Icon
                        size={1.2}
                        icon={
                            hasUnreadNotifications
                                ? 'notificationBellAlert'
                                : 'notificationBell'
                        }
                        color="indigo"
                    />
                    <Text family="museo">
                        {Constants.notificationsDropdown.title}
                    </Text>
                    <div className="notifications-total">
                        {hasUnreadNotifications ? unreadNotifications.length : 0}
                    </div>
                </button>
            )}
        </div>
    );
}
