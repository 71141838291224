import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './AcuityModal.scss';
import { MIModal } from 'ComponentLibrary';
import { cleanHash } from 'utilities/Utils';
import { useGetConfigurationForCurrentLot } from 'utilities/HooksAndSelectors';
import {
    createOpenAcuityModalAction,
    createCloseAcuityModalAction,
} from 'redux/actions/AcuityModal/AcuityModalActions';

export default function AcuityModal() {
    const location = useLocation();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.userState);
    const acuityModalState = useSelector(
        (reduxState) => reduxState.acuityModalState
    );
    const { userDetails, profileAndSettings, progressContent } = userState;
    const { lots } = progressContent;
    const lotConfiguration = useGetConfigurationForCurrentLot(false);
    const { activeLotId } = profileAndSettings;
    const { progress } = lotConfiguration;

    const communityName = lots[activeLotId]
        ? lots[activeLotId].communityName
        : null;
    const homePlanElevation = lots[activeLotId]
        ? lots[activeLotId].homePlanElevation
        : null;
    const homePlanName = lots[activeLotId]
        ? lots[activeLotId].homePlanName
        : null;
    const streetAddress = lots[activeLotId]
        ? lots[activeLotId].streetAddress
        : null;

    useEffect(() => {
        if (
            location.hash === '#first-design-scheduling' &&
            !acuityModalState.isOpen
        ) {
            dispatch(createOpenAcuityModalAction());
            cleanHash();
        }
    }, [location])

    if (!progress.design || !userDetails.accountProfile) {
        return null;
    }

    const handleCloseAcuityModal = () => {
        dispatch(createCloseAcuityModalAction());
    };

    const { designCenterFirstAppointmentLink } = progress.design;

    const replacedLink = designCenterFirstAppointmentLink
        .replace('{LotINumber}', activeLotId)
        .replace('{HomePlanElevation}', homePlanElevation)
        .replace('{HomePlanName}', homePlanName)
        .replace('{CommunityName}', communityName)
        .replace('{StreetAddress}', streetAddress);
    const iframe = `<iframe src='${replacedLink}&firstName=${userDetails.accountProfile.firstName}&lastName=${userDetails.accountProfile.lastName}&email=${userDetails.accountProfile.email}&phone=${userDetails.accountProfile.phoneNumber}' title="Schedule Appointment" width="100%" height="100%" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>`;

    return (
        <React.Fragment>
            <MIModal
                show={acuityModalState.isOpen}
                closeModal={handleCloseAcuityModal}
                customClass="acuity-modal"
                isHeader
                modalSize="xl"
                centered="true"
            >
                <div
                    className="acuity-iframe-container"
                    dangerouslySetInnerHTML={{ __html: iframe }}
                />
            </MIModal>
        </React.Fragment>
    );
}
