import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// Creates middleware to handle asynchronous operations
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const { logger } = require('redux-logger');
    middleware.push(logger);
}
// Creates redux store with initial state and saga middleware
const store = createStore(rootReducer, {}, applyMiddleware(...middleware));

// Run Sagas
sagaMiddleware.run(rootSaga);

export const action = (type) => store.dispatch({ type });

export default store;
