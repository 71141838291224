const CookieService = {
    setCookie: (cname, cvalue, exdays = 1, domain = '') => {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const fullcookie = `${cname}=${cvalue};${expires};domain=${domain};path=/`;
        document.cookie = fullcookie;
    },
    deleteCookie: (cname, path='/', domain = '') => {
        if (CookieService.getCookie(cname)) {
            document.cookie = `${cname}=${path ? `;path=${path}` : ''}${
                domain ? `;domain=${domain}` : ''
            };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        }
    },
    getCookie: (name, decode = true) => {
        const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
        let decoded = null;
        if (v) {
            decoded = decode ? decodeURIComponent(v[2]) : v[2];
        }
        return v ? decoded : null;
    },
};

export default CookieService;
