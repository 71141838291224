import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { NavHeader, LoadingScreen, FatalError } from 'ComponentLibrary';
import UserProfileDropdown from 'App/ContentComponents/UserProfileDropdown';
import ChecklistDropdown from 'App/ContentComponents/ChecklistDropdown';
import NotificationsDropdown from 'App/ContentComponents/NotificationsDropdown';
import Checklist from 'App/ContentComponents/Checklist';
import AnchoredMobileNav from 'App/ContentComponents/AnchoredMobileNav';
import VideoModal from 'App/ContentComponents/VideoModal';
import ContactBioModal from 'App/ContentComponents/ContactBioModal';
import FeedbackModal from 'App/ContentComponents/FeedbackModal';
import HamburgerMenu from 'App/ContentComponents/HamburgerMenu';
import AcuityModal from 'App/ContentComponents/AcuityModal';
import PhotoModal from 'App/ContentComponents/Photos/PhotoModal';
import ShareModal from 'App/ContentComponents/ShareModal';
import routeConfig from 'routeConfig';
import { createRefreshFromCookieAction } from 'redux/actions/Login/LoginActions';
import WithRedirect from 'utilities/withRedirect';
import {
  useHaveUser,
  useHaveFatalError,
  useIsAdmin,
} from 'utilities/HooksAndSelectors';
import AnalyticsListener from 'components/Analytics';
import './BuyerPortalRouter.scss';
import { CSSTransition } from 'react-transition-group';

const BuyerPortalRouter = () => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const haveUser = useHaveUser();
  const user = useSelector((reduxState) => reduxState.userState);
  const bio = useSelector((reduxState) => reduxState.contactBioState);
  const videoModalState = user.videoModal;
  const isExternalRedirect = user.userDetails.currentPage === 'externalRedirect';

  let showAnchoredNav =
    haveUser &&
    user &&
    user.userDetails &&
    user.userDetails.lotAssociations &&
    user.userDetails.lotAssociations.length > 0 &&
    user.userDetails.currentPage === 'dashboard';

  if (isAdmin && user.userDetails.currentPage === 'dashboard') {
    showAnchoredNav = true;
  }

  if (
    (!haveUser || !user) &&
    user.userDetails.refreshCookie !== 'expired' &&
    user.userDetails.refreshCookie !== 'checking' &&
    user.userDetails.refreshCookie !== 'refreshed'
  ) {
    if (
      window.location.search.indexOf('logout=true') < 0 &&
      window.location.pathname.indexOf('invite') < 0
    ) {
      const action = createRefreshFromCookieAction();
      dispatch(action);
    }
  }
  // if token is refreshed or expired, or if page is invite and we have checked the token status, hide load screen
  const exit = !!(
    (user &&
      user.userDetails &&
      user.userDetails.refreshCookie &&
      (user.userDetails.refreshCookie === 'expired' ||
        user.userDetails.refreshCookie === 'refreshed')) ||
    (window.location.pathname.indexOf('invite') > -1 &&
      user.userDetails.inviteTokenStatus !== undefined)
  );

  const showFatalError = useHaveFatalError();

  return (
    <Router>
      <AnalyticsListener trackingCode={window._env_.REACT_APP_GA_TRACKING_CODE}>
        <NavHeader
          links={[
            { path: '/dashboard', name: 'Dashboard' },
            { path: '/photos', name: 'Photos' },
            { path: '/contacts', name: 'Contacts' },
            { path: '/deposits', name: 'Deposits' },
            { path: '/documents', name: 'Documents' },
          ]}
        />
        <HamburgerMenu />
        {showAnchoredNav && <HamburgerMenu />}
        {showAnchoredNav && <AnchoredMobileNav />}
        <Switch>
          {routeConfig.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
        <ChecklistDropdown />
        <NotificationsDropdown />
        <UserProfileDropdown />
        <Checklist />
        <VideoModal {...videoModalState} />
        <FeedbackModal />
        <PhotoModal />
        <ShareModal />
        {bio && <ContactBioModal />}
        <LoadingScreen
          exit={isExternalRedirect ? false : exit}
          minimumTime={3000}
          variant={isExternalRedirect ? 'external-redirect' : null}
          color={
            user && user.userDetails ? user.userDetails.loadScreenColor : null
          }
          customPhrasing={
            isExternalRedirect ? user.userDetails.loadingScreenPhrasing : []
          }
        />
        {showFatalError && <FatalError error={user.userDetails.errors} />}
        <AcuityModal />
      </AnalyticsListener>
    </Router>
  );
};

const RouteWithSubRoutes = (route) => {
  const { path = '', routes = [] } = route;
  const userState = useSelector((state) => state.userState.userDetails);

  const { currentPage, afterSuccessfulLogin } = userState;
  return (
    <Route path={path} exact={route.exact || false}>
      {(props) => {
        const isAnimatable =
          path.indexOf('progress') >= 0 ||
          path.indexOf('dashboard') >= 0 ||
          path.indexOf('photos') >= 0 ||
          path.indexOf('profile') >= 0;
        return (
          <Suspense
            key={path}
            fallback={
              route.customFallback ? (
                <route.customFallback {...route.customFallbackProps} />
              ) : (
                <LoadingScreen exit={false} variant={null} color={null} />
              )
            }
          >
            <CSSTransition
              in={props.match != null}
              appear={props.match != null}
              timeout={400}
              classNames={isAnimatable ? 'slide' : ''}
              exit={props.match === null}
              key={path}
              unmountOnExit
            >
              <WithRedirect
                page={route.page}
                currentPage={currentPage}
                afterSuccessfulLogin={afterSuccessfulLogin}
                adminOnly={route.extraProps && route.extraProps.adminOnly}
              >
                <route.component
                  routes={routes}
                  {...props}
                  {...route.extraProps}
                />
              </WithRedirect>
            </CSSTransition>
          </Suspense>
        );
      }}
    </Route>
  );
};

export default BuyerPortalRouter;
