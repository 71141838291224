import React from 'react';
import PropTypes from 'prop-types';
import IconSVGs from './IconSVGs';
import './Icon.scss';

export const IconSizes = {
    Tiny: .75,
    ExtraSmall: 1,
    KindaSmall: 1.25,
    Small: 1.5,
    Medium: 2,
    Large: 2.5,
    ExtraLarge: 3,
};

const Icon = (props) => {
    const { extraClasses, style, border, icon, circle, color, inButton, size, centered, testId, withText } = props;

    let iconCode = IconSVGs[icon] && IconSVGs[icon].replace(new RegExp('RANDOM', 'g'), Math.round(Math.random() * 250));
    const circleColorVal = circle ? `bg-${circle}` : '';
    let sizekey = null;
    Object.keys(IconSizes).forEach((a) => {
        if (IconSizes[a] === size) {
            sizekey = a;
        }
    });
    let originalWidth = '';
    let originalHeight = '';

    if (iconCode === null || iconCode === undefined) {
        console.warn(`Missing Icon: ${icon}`)
        return null;
    }

    iconCode
        .substring(1, iconCode.indexOf('>'))
        .split(' ')
        .forEach((val) => {
            if (val.indexOf('width="') === 0) {
                originalWidth = val.split('="')[1].slice(0, -1);
            }
            if (val.indexOf('height="') === 0) {
                originalHeight = val.split('="')[1].slice(0, -1);
            }
        });
    const newWidth = size * parseInt(originalWidth, 10);
    const newHeight = size * parseInt(originalHeight, 10);

    iconCode = iconCode.replace(
        `width="${originalWidth}"`,
        `width="${newWidth}"`
    );
    iconCode = iconCode.replace(
        `height="${originalHeight}"`,
        `height="${newHeight}"`
    );

    return (
        <i
            style={style}
            data-testid={testId || 'icon'}
            data-name={icon}
            className={`${extraClasses} icon  ${withText ? 'icon--with-text' : ''} ${centered ? 'icon--centered' : ''} ${inButton ? 'icon--in-button' : ''} ${
                circle || border
                    ? `icon--circle ${
                        border ? `icon--border border-${border}` : ''
                    } ${circleColorVal}`
                    : ''
            } ${color || ''} icon--${sizekey ? sizekey.toLowerCase() : ''}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: iconCode }}
        />
    );
};
export default Icon;
Icon.defaultProps = {
    style: {},
    circle: null,
    color: null,
    inButton: false,
    border: null,
    size: IconSizes.ExtraSmall,
    centered: false,
    testId: null,
    extraClasses: null,
};
Icon.propTypes = {
    style: PropTypes.object,
    color: PropTypes.string,
    circle: PropTypes.string,
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    inButton: PropTypes.bool,
    centered: PropTypes.bool,
    border: PropTypes.string,
    testId: PropTypes.string,
};

export const IconTypes = {
    User: 'user',
    Calendar: 'calendar',
    AnimatedCheckmark: 'animatedcheckmark',
    Checlist: 'checklist',
    Checkmark: 'checkmark',
    DoubleArrow: 'doublearrow',
    ThinCheckmark: 'thinCheckmark',
    Location: 'location',
    VirtualTour: 'virtualtour',
    Floorplan: 'floorplan',
    Arrow: 'arrow',
    Contact: 'contact',
    CustomerCare: 'customercare',
    Garage: 'garage',
    Bedroom: 'bedroom',
    Facebook: 'facebook',
    Google: 'google',
    Task: 'task',
    House: 'house',
    Area: 'area',
    FullBath: 'fullbath',
    Documents: 'documents',
    Dashboard: 'dashboard',
    Play: 'play',
    Progress: 'progress',
    Gear: 'gear',
    Image: 'image',
    Couple: 'couple',
    PlayButton: 'playbutton',
    LongArrow: 'longarrow',
    Airplane: 'airplane',
    Mail: 'mail',
    Phone: 'phone',
    Star: 'star',
    Info: 'info',
    LargePlus: 'largePlus',
    LargeMinus: 'largeMinus',
    ThickCheckmark: 'thickCheckmark',
    NotificationBell: 'notificationBell',
    Printer: 'print',
    Download: 'download',
};
