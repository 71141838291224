import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    notifications: null,
    unreadNotifications: [],
    notificationsDropDownOpen: false,
    openNotification: null,
};

const NotificationsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.ChangeActiveLot:
        case ActionTypes.OpenFindLotPage:
            return initialState;
        case ActionTypes.OpenNotificationsDropdown:
            return {
                ...state,
                notificationsDropDownOpen: true,
            };

        case ActionTypes.CloseNotificationsDropdown:
            return {
                ...state,
                notificationsDropDownOpen: false,
            };
        case ActionTypes.GetNotifications:
            return {
                ...state,
                notifications: 'requested',
            };
        case ActionTypes.GetNotificationsSuccess:
            return {
                ...state,
                notifications: action.payload.notifications,
                unreadNotifications: action.payload.unreadNotifications,
            };

        case ActionTypes.GetNotificationsError:
            return {
                ...state,
                notifications: [],
                unreadNotifications: [],
            };

        case ActionTypes.ViewNotificationSuccess:
            return {
                ...state,
                unreadNotifications: state.unreadNotifications.filter(notification => {
                    return JSON.stringify(notification) !== JSON.stringify(action.payload);
                }),
            };

        case ActionTypes.SetOpenNotification:
                return {
                    ...state,
                    openNotification: action.payload,
                };

        case ActionTypes.LogOut:
            return {
                ...state,
                notifications: null,
                unreadNotifications: [],
                notificationsDropDownOpen: false,
                openNotification: null,
            }

        default:
            return state;
    }
};

export default NotificationsReducer;
