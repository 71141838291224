import ActionTypes from 'redux/actions/ActionTypes';

export function createHamburgerToggleAction() {
    return {
        type: ActionTypes.ToggleHamburger,
    };
}

export function createHamburgerOpenAction() {
    return {
        type: ActionTypes.OpenHamburger,
    };
}

export function createHamburgerCloseAction() {
    return {
        type: ActionTypes.CloseHamburger,
    };
}
