import React from 'react';
import PropTypes from 'prop-types';
import './Heading.scss';

const Heading = (props) => {
    const { as, like, centered, testId, children } = props;
    const Tag = as;
    const idVal = { 'data-testid': testId };
    return (
        <Tag
            {...idVal}
            className={`heading heading--${like} ${
                centered ? 'heading--centered' : ''
            }`}
        >
            {children}
        </Tag>
    );
};

Heading.defaultProps = {
    like: 'h1',
    as: 'h1',
    centered: false,
    testId: null,
};

Heading.propTypes = {
    as: PropTypes.string,
    like: PropTypes.string,
    centered: PropTypes.bool,
    testId: PropTypes.string,
};

export default Heading;
