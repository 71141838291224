export default function buildLotProgressData(lotId, lots, progressDataArray) {
    progressDataArray.forEach((item, index) => {
        if(item) {
            if (item.stages) {
                item.stages = buildProgressStages(lots[lotId.toString()], item, index);
            }
            if(lotId && lots[lotId.toString()] && lots[lotId.toString()].progress && lots[lotId.toString()].progress[index]) {
                item.status = lots[lotId.toString()].progress[index].status;
            }

            item.contentLoaded = true;
        }
    });
    return progressDataArray;
}

function buildProgressStages(lot, item, index) {
    let { stages } = item;
    const auxillaryStagesInfo = lot && lot.progress && lot.progress[index] ? lot.progress[index].stages : null;
    if (auxillaryStagesInfo) {
        stages = item.stages.map((stage, i) => ({
            ...stage,
            status: (
                auxillaryStagesInfo.find(a => a.stageId === stage.stageId) ?
                    auxillaryStagesInfo.find(a => a.stageId === stage.stageId).status : 'NotStarted'
            ),
        }));
    }
    return stages;
}
