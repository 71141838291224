import React, { useEffect, useRef, useState } from 'react';
import { Error, SortableTable, Heading, Icon } from 'ComponentLibrary';
import { Accordion, Card } from 'react-bootstrap';
import { createLotSearchSortAction } from 'redux/actions/Admin';
import { useDispatch, useSelector } from 'react-redux';
import { createGetLotByIdAction } from 'redux/actions/LotAssociation/LotAssociationActions';
import Constants from 'Constants';
import CookieService from 'services/CookieService';
import Utils from 'utilities/Utils';
import './LotSearchResults.scss';

const getOpposite = (currentSortBy, thisSort, currentOrder) => {
    if (currentSortBy === thisSort) {
        if (currentOrder === 'asc') {
            return 'desc';
        }
        if (currentOrder === 'desc') {
            return 'asc';
        }
        return 'badone';
    }
    return 'desc';
};

const FirstCell = (props) => {
    const { children, lotId, jdeCommunityName, name, address, city } = props;

    const dispatch = useDispatch();
    return (
        <button
            className="lot-search-results__select-lot-button"
            type="button"
            onClick={(e) => {
                e.preventDefault();
                window.scrollTo(0, 0);
                dispatch(
                    createGetLotByIdAction(lotId, jdeCommunityName, name, address, city)
                );
                return false;
            }}>
            {children}
        </button>
    );
};

const LotSearchResults = ({
    id = null,
    plain = true,
    transparent = false,
    results,
    title,
    sortActions,
    passSortBy,
    passSortOrder,
    override,
    nameOrAddress = 'address',
}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const homesState = useSelector((state) => state.homesState);
    const [openId, setOpenId] = useState(null);
    let { sortBy, sortOrder } = homesState;

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    if (override) {
        sortBy = passSortBy;
        sortOrder = passSortOrder;
        CookieService.setCookie('searchMode', 'recent');
    }

    useEffect(() => {
        forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, sortOrder, passSortBy, passSortBy, override, results]);

    useEffect(() => {
        if (id && results && Array.isArray(results) && ref && ref.current) {
            ref.current.scrollIntoView();
        }
    }, [id, results]);

    return (
        <div
            ref={ref}
            id={id || null}
            className={`recent-lot lot-search-results find-lot-block ${
                plain && 'find-lot-block--plain'
            } ${transparent && 'find-lot-block--transparent'}`}
        >
            {results && title && (
                <h2 id={Utils.slugify(title)} className="lot-search-results__title">{title}</h2>
            )}
            {results && Array.isArray(results) && results.length === 0 && (
                <Error>{Constants.pages.findLot.noResults}</Error>
            )}
            {results && Array.isArray(results) && results.length > 0 && (
                <SortableTable
                    headers={['Lot Id', 'Community', 'Name', 'Address', 'City']}
                    describedBy={Utils.slugify(title)}
                    keys={[
                        'lotId',
                        'jdeCommunityName',
                        'name',
                        'address',
                        'city',
                    ]}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    rows={results}
                    cellRenderers={[FirstCell]}
                    sortActions={
                      (sortActions && sortActions.map((func) => {
                          return () => {
                            func();
                            forceUpdate();
                            return false;
                          };
                        })) || [
                            () => {
                                const newSortOrder = !sortOrder
                                    ? 'desc'
                                    : getOpposite(sortBy, 'lotId', sortOrder);
                                dispatch(
                                    createLotSearchSortAction(
                                        'lotId',
                                        newSortOrder
                                    )
                                );
                            },
                            () => {
                                const newSortOrder = !sortOrder
                                    ? 'desc'
                                    : getOpposite(
                                        sortBy,
                                        'jdeCommunityName',
                                        sortOrder
                                      );
                                dispatch(
                                    createLotSearchSortAction(
                                      'jdeCommunityName',
                                      newSortOrder
                                    )
                                );
                            },
                            () => {
                                const newSortOrder = !sortOrder
                                    ? 'desc'
                                    : getOpposite(sortBy, 'name', sortOrder);
                                dispatch(
                                    createLotSearchSortAction(
                                      'name',
                                      newSortOrder)
                                );
                            },
                            () => {
                                dispatch(
                                    createLotSearchSortAction(
                                        'address',
                                        sortOrder === null
                                            ? 'desc'
                                            : getOpposite(sortBy, 'address', sortOrder)
                                    )
                                );
                            },
                            () => {
                                dispatch(
                                    createLotSearchSortAction(
                                        'city',
                                        sortOrder === null
                                            ? 'desc'
                                            : getOpposite(sortBy, 'city', sortOrder)
                                    )
                                );
                            },
                        ]}
                />
            )}
            {results && Array.isArray(results) && results.length > 0 && (
                <ul className="mobile-search-results">
                    <Accordion>
                        {results && results.map((result, index) => {
                            const isOpen = openId === index + 1;
                            return (
                                <Card as="li" className={'sortable-table__mobile-result sortable-table-mobile-result'} key={index + 1}>
                                    <Accordion.Toggle
                                        className={`sortable-table-mobile-result__button ${isOpen && 'sortable-table-mobile-result__button--open'   }`}
                                        variant="link"
                                        eventKey={index + 1}
                                        onClick={() => {
                                            setOpenId(isOpen ? null : index + 1);
                                        }}
                                    >
                                        <Heading like="h5" as="h5">
                                            {nameOrAddress === 'address' && result.address}
                                            {nameOrAddress !== 'address' && result.name && nameOrAddress !== null ? result.name : (nameOrAddress !== 'address' ? result.address : null)}
                                        </Heading>
                                        <Icon
                                            icon="skinnyarrow"
                                            color={isOpen ? 'white' : 'aqua'}
                                            circle={isOpen ? 'boston-blue' : null}
                                            border="boston-blue"
                                            extraClasses={`sortable-table-mobile-result__icon ${isOpen && 'sortable-table-mobile-result__icon--open'}`}
                                        />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <div className="sortable-table-mobile-result__content" onClick={() => {
                                            setOpenId(index + 1)
                                        }}>
                                            {result.name && (<p>{Constants.pages.findLot.resultLabels.address}: <span>{result.address}</span></p>)}
                                            <p>{Constants.pages.findLot.resultLabels.city}: <span>{result.city}</span></p>
                                            {nameOrAddress === null && result.name && (<p>{Constants.pages.findLot.resultLabels.name}: <span>{result.name}</span></p>)}
                                            <p>{Constants.pages.findLot.resultLabels.community}: <span>{result.jdeCommunityName}</span></p>
                                            <p>{Constants.pages.findLot.resultLabels.lot}: <button type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.scrollTo(0, 0);
                                                    dispatch(
                                                        createGetLotByIdAction(result.lotId, result.jdeCommunityName, result.name, result.address, result.city)
                                                    );
                                                    return false;
                                                }}>{result.lotId}</button></p>

                                        </div>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })}
                    </Accordion>
                </ul>
            )}
        </div>
    );
};

export default LotSearchResults;
