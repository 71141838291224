import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { DepositsService } from 'services';
import { getUserProfileAndSettings } from 'redux/sagas/selectors';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';

async function fetchDepositsData(data) {
    try {
        return await _sendRequest(data, DepositsService.getDeposits);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* depositsDataRequestSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };
    const depositsResponse = yield call(fetchDepositsData, data);
    if (depositsResponse && depositsResponse.deposits && Array.isArray(depositsResponse.deposits)) {
        yield put({
            type: ActionTypes.GetDepositsSuccess,
            payload: depositsResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetDepositsError,
        });
    }
}
