/* eslint-disable import/prefer-default-export */
import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { AuthService, ProfileService, RedirectService } from 'services';


import {
  assignCurrentPage,
  buildErrorObject,
} from 'redux/sagas/sagaHelpers/userHelpers';
import { getUser, getUserProfileAndSettings } from 'redux/sagas/selectors';
import {
  _sendRequest,
  _sendAuthRequest,
} from 'redux/sagas/sagaHelpers/generalHelpers';
import Constants from 'Constants';
import CookieService from 'services/CookieService';
import { Event } from 'components/Analytics';
import Utils from 'utilities/Utils';

async function loginUser(data) {
  try {
    return await _sendAuthRequest(data, AuthService.login);
  } catch (error) {
    if (error.name === 'TypeError') {
      const genericError = {
        message: Constants.global.errors.generic.fail,
      };
      return buildErrorObject(genericError);
    }
    return buildErrorObject(error);
  }
}

async function loginAdminUser(data) {
  try {
    return await _sendAuthRequest(data, AuthService.adminLogin);
  } catch (error) {
    if (error.name === 'TypeError') {
      const genericError = {
        message: Constants.global.errors.generic.fail,
      };
      return buildErrorObject(genericError);
    }
    return buildErrorObject(error);
  }
}

async function refreshUser() {
  try {
    return await _sendRequest({ timeout: 5000 }, AuthService.refresh);
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function logoutUser() {
  try {
    const ret = await _sendAuthRequest({}, AuthService.logout);
    return ret;
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function deleteSubscription(data) {
  try {
    const ret = await _sendRequest(data, ProfileService.deleteSubscription);
    return ret;
  } catch (error) {
    return buildErrorObject(error);
  }
}

export async function fetchProfile(data) {
  try {
    return await _sendRequest(data, ProfileService.get, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function setNotificationPreferences(data) {
  try {
    return await _sendRequest(
      data,
      ProfileService.setNotificationPreferences,
      false
    );
  } catch (error) {
    return buildErrorObject(error);
  }
}

export async function fetchEnvisionExternalRedirectUrl(data) {
  try {
    return await _sendRequest(data, RedirectService.getEnvisionUrl, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

export async function fetchWellFargoExternalRedirectUrl(data) {
  try {
    return await _sendRequest(data, RedirectService.getWellsFargoUrl, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

export async function fetchWarrantyExternalRedirectUrl(data) {
  try {
    return await _sendRequest(data, RedirectService.getWarrantyUrl, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

export function* loginSaga(action) {
  yield put({ type: ActionTypes.LoginRequest });
  const response = yield call(loginUser, action.payload);
  if (!!response && !response.errors) {
    Event('login', 'login');
    yield* profileSaga(response);
  } else {
    try {
      Event('error', 'login-error', response.errors.form[0].message);
    } catch (e) {
      Event('error', 'generic-login-error');
    }
    yield put({
      type: ActionTypes.LoginError,
      payload:
        response ||
        buildErrorObject({
          message: Constants.global.errors.generic.fail,
        }),
    });
  }
}

export function* adminLoginSaga(action) {
  yield put({ type: ActionTypes.AdminLoginRequest, payload: action.payload });
  const response = yield call(loginAdminUser, action.payload);
  if (!!response && !response.errors) {
    yield* profileSaga(response);
  } else {
    yield put({
      type: ActionTypes.LoginError,
      payload:
        response ||
        buildErrorObject({
          message: Constants.global.errors.generic.fail,
        }),
    });
  }
}

export function* logoutSaga(data) {
  const signOutRequest = yield call(logoutUser, data.payload.token);

  if (signOutRequest === 'success') {
    yield put({
      type: ActionTypes.LogoutSuccess,
      payload: {},
    });
    if (data.payload.isAdmin || data.isAdmin) {
      try {
        CookieService.deleteCookie('adminLotId');
      } catch (e) { }
    }
  } else {
    yield put({
      type: ActionTypes.LogoutError,
      payload: buildErrorObject({
        message: Constants.global.errors.generic.logout,
      }),
    });
  }
}

export function* setNotificationPreferencesSaga(data) {
  const setNotificationPrefencesResult = yield call(
    setNotificationPreferences,
    data.payload
  );

  if (
    data &&
    data.payload &&
    data.payload.subscription &&
    data.payload.enablePush === false &&
    data.payload.subscription.databaseSubscription
  ) {
    yield call(
      deleteSubscription,
      data.payload.subscription.databaseSubscription.notificationSubscriptionId
    );
    yield put({
      type: ActionTypes.NotificationPrefDeletePushSubscription,
      payload:
        data.payload.subscription.databaseSubscription
          .notificationSubscriptionId,
    });
  }

  if (
    setNotificationPrefencesResult === '' ||
    !setNotificationPrefencesResult.errors
  ) {
    if (data && data.payload) {
      yield put({
        type: ActionTypes.NotificationPrefUpdateState,
        payload: data.payload,
      });
    }
    yield put({
      type: ActionTypes.NotificationPrefSuccess,
      payload: 'success',
    });
  } else {
    yield put({
      type: ActionTypes.NotificationPrefError,
      payload: 'error',
    });
  }
}

export function* profileSaga(data) {
  const userObject = { ...data };
  const profileData = yield call(fetchProfile, data);
  let isEmployee = false;
  if (profileData && profileData.roles) {
    profileData.roles.forEach((role) => {
      const { description } = role;
      if (description === 'Employee') {
        isEmployee = true;
      }
    });
  }
  if (window.ga) {
    window.ga('set', 'dimension1', isEmployee ? 'employee' : 'customer');
  }
  const user = yield select(getUser);

  if (!!profileData && !profileData.errors) {
    const currentPage =
      user.currentPage === 'externalRedirect'
        ? 'externalRedirect'
        : assignCurrentPage(profileData);
    const isAdmin =
      profileData &&
      profileData.roles &&
      profileData.roles.filter(
        (role) => role.description === Constants.global.adminRole
      ).length > 0;

    let activeLotId = null;
    const possibleLotId = CookieService.getCookie('activeLotId')
      ? parseInt(CookieService.getCookie('activeLotId'), 10)
      : null;

    if (isAdmin && CookieService.getCookie('adminLotId')) {
      activeLotId = parseInt(CookieService.getCookie('adminLotId'), 10);
      const currentLots = Utils.getLocalStorage('adminLots')
        ? JSON.parse(Utils.getLocalStorage('adminLots'))
        : [];
      const timestamp = new Date().getTime();
      if (
        currentLots.filter(
          (lot) => lot.lotId.toString() === activeLotId.toString()
        ).length > 0
      ) {
        currentLots.forEach((lot, index) => {
          if (lot.lotId === activeLotId) {
            currentLots[index].timestamp = timestamp;
          }
        });
        Utils.setLocalStorage('adminLots', JSON.stringify(currentLots));
      }
    } else if (!isAdmin && CookieService.getCookie('activeLotId')) {
      const possibles = profileData.lotAssociations.filter(
        ({ lotId }) => lotId === parseInt(possibleLotId, 10)
      );
      const isValidLotId = possibles.length === 1;
      if (isValidLotId) {
        activeLotId = possibleLotId;
      }
    }

    yield put({
      type: ActionTypes.LoginSuccess,
      payload: {
        ...userObject,
        ...profileData,
        currentPage,
        possibleLotId,
        activeLotId,
      },
    });
  } else {
    yield put({
      type: ActionTypes.LoginError,
      payload:
        profileData ||
        buildErrorObject({
          message: Constants.global.errors.generic.fail,
        }),
    });
  }
}

export function* refreshSaga(action) {
  yield put({ type: ActionTypes.RefreshUserRequest });
  const response = yield call(refreshUser);
  if (!!response && !response.errors && response.name !== 'ERROR') {
    yield put({
      type: ActionTypes.RefreshUserSuccess,
    });
    yield* profileSaga(response);
  } else {
    yield put({
      type: ActionTypes.RefreshUserError,
      payload:
        response ||
        buildErrorObject({
          message: Constants.global.errors.generic.fail,
        }),
    });
  }
}

export function* externalRedirectSaga(action) {
  let response;
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  //let { depositId } = useParams();
  const depositId = window.location.toString().split('__')[0].split('/').pop();
  const depositIdBase64String = window.location.toString().split('__')[1];
  const data = {
    lotId: userProfileAndSettings.activeLotId,
    depositId,
    depositIdBase64String,
  };
  if (action.payload === 'envision') {
    response = yield call(fetchEnvisionExternalRedirectUrl, data);
    if (!!response && !response.errors && response.name !== 'ERROR') {
      yield put({
        type: ActionTypes.FetchExternalRedirectSuccess,
        payload: response.redirectUrl,
      });
    } else {
      yield put({
        type: ActionTypes.FetchExternalRedirectError,
        payload: response || buildErrorObject({ message: 'An error occurred' }),
      });
    }
  } else if (action.payload === 'wellsfargo') {
    response = yield call(fetchWellFargoExternalRedirectUrl, data);
    if (!!response && !response.errors && response.name !== 'ERROR') {
      if (response.initiateAchDepositUrl) {
        yield put({
          type: ActionTypes.FetchExternalRedirectSuccess,
          payload: response.initiateAchDepositUrl,
        });
        window.location = response.initiateAchDepositUrl;
      }
    } else {
      yield put({
        type: ActionTypes.FetchWellsFargoExternalRedirectError,
      });
    }
  } else if (action.payload === 'warranty') {
    response = yield call(fetchWarrantyExternalRedirectUrl, data);
    if (!!response && !response.errors && response.name !== 'ERROR') {
      if (response.url) {
        yield put({
          type: ActionTypes.FetchExternalRedirectSuccess,
          payload: response.url,
        });
      }
    } else {
      yield put({
        type: ActionTypes.FetchExternalRedirectNoUrl,
      });
    }
  }
}
