import React from 'react';
import PropTypes from 'prop-types';
import './Error.scss';

const Error = (props) => {
    const { field, testId, children, className, center, spaceBelow } = props;
    const role = !field ? { role: 'alert' } : null;
    const idVal = { 'data-testid': testId };

    return (
        <div
            {...idVal}
            {...role}
            className={`error ${center && 'error--center'} ${className} ${spaceBelow && 'error--margin-bottom'}  ${field ? 'error--field' : ''}`}
        >
            {children}
        </div>
    );
};

Error.defaultProps = { testId: null, className: null, spaceBelow: false, center: false };

Error.propTypes = { testId: PropTypes.string, spaceBelow: PropTypes.bool, className: PropTypes.string, center: PropTypes.bool };

export default Error;
