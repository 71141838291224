import { fork } from 'redux-saga/effects';

import {
    watchLogin,
    watchAdminLogin,
    watchLogout,
    watchLotAssociation,
    watchGetConfiguration,
    watchGetProgressContent,
    watchLotToolTip,
    watchRefresh,
    watchContacts,
    watchExternalRedirect,
    watchContactRequest,
    watchSetNotificationPreferences,
    watchTasksRequest,
    watchGetDocuments,
    watchGetSingleDocument,
    watchTaskComplete,
    watchTaskModalTrigger,
    watchTaskCompleteSuccess,
    watchTaskCompleteError,
    watchNotificationsRequest,
    watchSampleNotificationsRequest,
    watchViewNotification,
    watchGetLotById,
    watchFeedback,
    watchGetDivisionContacts,
    watchSaveWarrantyClaim,
    watchWarrantyCategoriesRequest,
    watchWarrantySetActiveCategory,
    watchWarrantyRemoveActiveCategory,
    watchSubmitServiceItems,
    watchRemoveExistingWarrantyClaimDraft,
    watchGetServiceRequestId,
    watchCompleteServiceRequest,
    watchDeposits,
    watchGetDivisionTasks,
    watchGetPhotosDownloadUrl,
    watchAddNewTask,
    watchAddNewTaskSuccess,
    watchRemovePersonalTask,
    watchRemovePersonalTaskSuccess,
    watchSubmitEditPersonalTask,
    watchSubmitEditPersonalTaskSuccess,
    watchDeleteLotAssociation,
    watchGetDivisions,
    watchGetCommunities,
    watchDesignSelections,
    watchGetDesignSelectionItemRequest,
    watchSearchByName,
    watchSearchByAddress,
    watchGetAssociatedBuyers,
    watchCheckInviteToken,
    watchSubmitPassword,
    watchSubmitPasswordSuccess,
} from './sagas/index';

export default function* rootSaga() {
    yield fork(watchDeleteLotAssociation);
    yield fork(watchGetPhotosDownloadUrl);
    yield fork(watchFeedback);
    yield fork(watchContacts);
    yield fork(watchLogin);
    yield fork(watchRefresh);
    yield fork(watchLogout);
    yield fork(watchGetConfiguration);
    yield fork(watchLotAssociation);
    yield fork(watchGetLotById);
    yield fork(watchGetProgressContent);
    yield fork(watchLotToolTip);
    yield fork(watchGetDocuments);
    yield fork(watchGetSingleDocument);
    yield fork(watchExternalRedirect);
    yield fork(watchContactRequest);
    yield fork(watchSetNotificationPreferences);
    yield fork(watchTasksRequest);
    yield fork(watchTaskComplete);
    yield fork(watchTaskModalTrigger);
    yield fork(watchTaskCompleteSuccess);
    yield fork(watchTaskCompleteError);
    yield fork(watchNotificationsRequest);
    yield fork(watchSampleNotificationsRequest);
    yield fork(watchViewNotification);
    yield fork(watchAdminLogin);
    yield fork(watchGetDivisionContacts);
    yield fork(watchGetDivisionTasks);
    yield fork(watchWarrantyCategoriesRequest);
    yield fork(watchWarrantySetActiveCategory);
    yield fork(watchWarrantyRemoveActiveCategory);
    yield fork(watchSaveWarrantyClaim);
    yield fork(watchSubmitServiceItems);
    yield fork(watchRemoveExistingWarrantyClaimDraft);
    yield fork(watchGetServiceRequestId);
    yield fork(watchCompleteServiceRequest);
    yield fork(watchDeposits);
    yield fork(watchAddNewTask);
    yield fork(watchAddNewTaskSuccess);
    yield fork(watchRemovePersonalTask);
    yield fork(watchRemovePersonalTaskSuccess);
    yield fork(watchSubmitEditPersonalTask);
    yield fork(watchSubmitEditPersonalTaskSuccess);
    yield fork(watchGetCommunities);
    yield fork(watchGetDivisions);
    yield fork(watchDesignSelections);
    yield fork(watchGetDesignSelectionItemRequest);
    yield fork(watchSearchByAddress);
    yield fork(watchSearchByName);
    yield fork(watchGetAssociatedBuyers);
    yield fork(watchCheckInviteToken);
    yield fork(watchSubmitPassword);
    yield fork(watchSubmitPasswordSuccess);
}
