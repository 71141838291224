import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Button, ButtonStyles, Text, Icon } from 'ComponentLibrary';
import Constants from 'Constants';
import Utils from 'utilities/Utils';
import slide1Img from '../../assets/my-home-logo-360w.png';


const AppUpdateWelcome = (props) => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        adaptiveHeight: true,
        swipeToSlide: false,
        swipe: false,
    };

    const customSlider = useRef();
    const gotoNext = () => {
        customSlider.current.slickNext();
    };

    return (
        <Slider {...settings} ref={customSlider}>
            <div className="welcome-screen screen1" data-testid="screen1">
                <div className="welcome-image-wrapper">
                    <img height="360" width="360" src={slide1Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            We’re making some updates
                        </Text>
                        <Text type="block" variant="regular">
                            You’ll need to uninstall and visit journey.mihomes.com to reinstall the new app.
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={gotoNext}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen2" data-testid="screen2">
                <div className="welcome-image-wrapper" style={{ position: 'relative' }}>
                    <div className='bg-indigo'
                        style={{
                            borderRadius: "20px",
                            textAlign: "center",
                            margin: "auto",
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            width: "180px",
                            padding: '60px 0 0 10px',
                            height: "180px",
                            transform: "translate(-50%, -50%)"
                        }}>
                        <Icon
                            size={2}
                            icon={'notificationBellAlert'}
                            color="white"
                            circle='silver'
                        />
                    </div>

                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            Stay up-to-date
                        </Text>
                        <Text type="block" variant="regular">
                            Check your notification preferences to turn on push, email, or SMS updates.
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.done}
                            submit
                            onClick={(e) => {
                                props.closeModal()
                                Utils.setLocalStorage('mihomes-app-change', true);
                            }} //submitWelcomeScreen
                        />
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default AppUpdateWelcome;
