import React from 'react';
import { Link } from 'react-router-dom';

const FlexibleLink = (props) => {
    const {
        children,
        isInternal,
        classList,
        linkDetails,
        testId,
        handleClick,
        rel,
        linkBox,
    } = props;

    if (!linkDetails) return null;

    return (
        <>
       { isInternal ? (
           <Link
                onClick={handleClick}
                data-testid={testId}
                className={classList}
                data-linkbox={linkBox || ''}
                to={linkDetails.url}
                title={linkDetails.title || ''}
                >
                {children}
            </Link>
       ) : (
            <a
            className={classList}
            data-testid={testId}
            href={linkDetails.url}
            target={linkDetails.target || '_self'}
            title={linkDetails.title || ''}
            data-linkbox={linkBox || ''}
            rel={rel}
            >
                {children}
            </a>
       )}
       </>
    );
};

export default FlexibleLink;
