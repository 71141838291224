import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOpenUserProfileDropdownAction,
    createCloseUserProfileDropdownAction,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import { useHaveUser } from 'utilities/HooksAndSelectors';
import { Icon, IconSizes } from 'ComponentLibrary';
import './UserBadge.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Event } from 'components/Analytics';

export default function UserBadge(props) {
    const { clickable, type, userImageUrl, borderStyle, testId, size, lazy, noBorder, altText } = props;
    const Tag = clickable ? 'button' : 'div';
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.userState);
    const { profileAndSettings } = user;
    const { profileDropDownOpen } = profileAndSettings;

    const haveUser = useHaveUser();

    const imgToUse = userImageUrl || 'get-the-svg.png';

    const click = clickable
        ? {
            onClick: () => {
                const action = profileDropDownOpen
                    ? createCloseUserProfileDropdownAction()
                    : createOpenUserProfileDropdownAction();
                Event(
                    'user-profile-dropdown',
                    profileDropDownOpen ? 'close' : 'open'
                );
                history.push(location.pathname);
                dispatch(action);
                return false;
            },
        }
        : null;
    return (
        <Tag
            className={`mi-user-badge-container ${clickable && 'clickable'} ${clickable && profileDropDownOpen && 'active'} ${noBorder ? 'no-border' : ''} ${type} ${size} ${borderStyle || ''}`}
            data-testid={testId || 'mi-user-badge-container'}
            {...click}
        >
            {haveUser && !userImageUrl && (
                <Icon
                    icon={'new-user'}
                    size={IconSizes.KindaSmall}
                    color="white"
                    testId="new-user-icon"
                />
            )}
            {userImageUrl && (
                <img
                  lazy={lazy ? 'true' : ''}
                  alt={altText || "User"}
                  className="header-user-image"
                  src={imgToUse}
                />
            )}
        </Tag>
    );
}

UserBadge.defaultProps = {
    type: null,
    userImageUrl: null,
    borderStyle: null,
    testId: null,
    size: 'regular',
    clickable: false,
    lazy: false,
    noBorder: false,
};

UserBadge.propTypes = {
    size: PropTypes.string,
    type: PropTypes.string,
    userImageUrl: PropTypes.string,
    borderStyle: PropTypes.string,
    testId: PropTypes.string,
    lazy: PropTypes.bool,
    clickable: PropTypes.bool,
    noBorder: PropTypes.bool,
};
