import React from 'react';
import PropTypes from 'prop-types';
import { useHasClosingData, useCurrentLotId } from 'utilities/HooksAndSelectors';
import { getPlainDateAndTime } from 'utilities/Utils';
import { Card, Text } from 'ComponentLibrary';
import { useSelector } from 'react-redux';
import TabContent from './TabContent';

const ClosingTabContent = (props) => {
    const { tab, active } = props;
    let tabContent = <TabContent tab={tab} active={active} />;
    const hasClosingData = useHasClosingData();
    const hasOfficeAddress = tab && tab.office && tab.office.address;
    const closingDate = getPlainDateAndTime(hasClosingData);

    let hideCardBecauseClosingPast = false;
    const today = new Date();
    const split = closingDate ? closingDate.split('/') : [];

    const userState = useSelector((state) => state.userState);
    const { progressContent } = userState;
    const { lots } = progressContent;
    const lotId = useCurrentLotId();
    const lot = lots[lotId];

    if(lot) {
        if(lot.isClosed) {
            //lot is closed, go ahead & hide
            hideCardBecauseClosingPast = true;
        }

        if (tab && tab.office && tab.office.officeType && tab.office.officeType === 'TitleOffice') {
            if(!lot.scheduledBuyerClosingDateTime) {
                hideCardBecauseClosingPast = true;
            }
            //resign with override title
            tabContent = <TabContent tab={tab} active={active} overrideOfficeTitle="Office Location" />;
        }
        if(split.length >= 2) {
            //check the date in case the close status isn't correct
            if (
                (today.getMonth() + 1) == parseInt(split[0]) &&
                today.getDate() > parseInt(split[1])
            ) {
                hideCardBecauseClosingPast = true;
            } else if (
                (today.getMonth() + 1) > parseInt(split[0])
            ) {
                hideCardBecauseClosingPast = true;
            }
        }
    }

    const officeString = hasOfficeAddress ? `${hasOfficeAddress.streetAddress1}, ${hasOfficeAddress.streetAddress2}, ${hasOfficeAddress.city}, ${hasOfficeAddress.state} ${hasOfficeAddress.zip}` : ''
    return (hasClosingData || hasOfficeAddress) && active ? (
        <>
            {!hideCardBecauseClosingPast && (
                <Card type="alert">
                    <Text color="err">Your closing has been scheduled!</Text>
                    {hasClosingData && (
                        <Text color="err">When: {closingDate}</Text>
                    )}
                    {hasOfficeAddress && (
                        <Text color="err">Where: {officeString}</Text>
                    )}
                </Card>
            )}
            {tabContent}
        </>
    ) : (
        tabContent
    );
};

ClosingTabContent.defaultProps = {};

ClosingTabContent.propTypes = {
    tab: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export default ClosingTabContent;
