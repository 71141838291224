import ActionTypes from 'redux/actions/ActionTypes';

export function createGetTasksAction() {
    return {
        type: ActionTypes.GetTasks,
    };
}

export function createCompleteTasksAction() {
    return {
        type: ActionTypes.CompleteTask,
    };
}

export function createGetTasksSuccessOnRetryAction(response) {
    return {
        type: ActionTypes.GetTasksSuccess,
        payload: response,
    };
}

export function createCloseChecklistDropdownAction() {
    return {
        type: ActionTypes.CloseChecklistDropdown,
    };
}

export function createOpenChecklistDropdownAction() {
    return {
        type: ActionTypes.OpenChecklistDropdown,
    };
}

export function createAddTaskToBeCompletedAction(taskId) {
    return {
        type: ActionTypes.AddTaskToBeCompleted,
        payload: taskId,
    };
}
export function createAddTaskToBeUncompletedAction(taskId) {
    if(!taskId) throw new Error('must provide task id');
    return {
        type: ActionTypes.AddTaskToBeUncompleted,
        payload: taskId,
    };
}

export function createRemoveTaskFromToBeCompletedAction(taskId) {
    return {
        type: ActionTypes.RemoveTaskFromToBeCompleted,
        payload: taskId,
    };
}

export function createRemoveTaskFromToBeUncompletedAction(taskId) {
    if (!taskId) throw new Error('must provide task id');
    return {
        type: ActionTypes.RemoveTaskFromToBeUncompleted,
        payload: taskId,
    };
}

export function createSetVisibleTasksAction(miTasks, personalTasks) {
    return {
        type: ActionTypes.SetVisibleTasks,
        payload: { miTasks, personalTasks },
    };
}

export function createOpenAddTaskFormAction(tasks) {
    return {
        type: ActionTypes.OpenAddTaskForm,
    };
}

export function createCloseAddTaskFormAction(tasks) {
    return {
        type: ActionTypes.CloseAddTaskForm,
    };
}

export function createUpdateNewTaskAction(update) {
    return {
        type: ActionTypes.UpdateNewTask,
        payload: update,
    };
}

export function createCancelTaskAction() {
    return {
        type: ActionTypes.CancelTaskForm,
    };
}

export function createAddNewTaskAction() {
    return {
        type: ActionTypes.AddNewTask,
    };
}

export function createRemovePersonalTaskAction(taskId) {
    return {
        type: ActionTypes.RemovePersonalTask,
        payload: taskId,
    };
}

export function createOpenEditTaskFormAction(task) {
    return {
        type: ActionTypes.OpenEditTaskForm,
        payload: task,
    };
}

export function createEditPersonalTaskAction(taskId) {
    return {
        type: ActionTypes.EditPersonalTask,
        payload: taskId,
    };
}

export function createSubmitEditPersonalTaskAction() {
    return {
        type: ActionTypes.SubmitEditPersonalTask,
    };
}

export function createAddTaskFormErrorAction(errors) {
    return {
        type: ActionTypes.AddTaskFormError,
        payload: errors,
    };
}

export function createOpenPersonalTasksAction() {
    return {
        type: ActionTypes.OpenPersonalTasks,
    };
}

export function createClosePersonalTasksAction() {
    return {
        type: ActionTypes.ClosePersonalTasks,
    };
}
