import React from 'react';
import './ChecklistDropdownSection.scss';
import ChecklistDropdownCard from '../ChecklistDropdownCard';

export default function ChecklistDropdownSection(props) {
    const {
        title,
        tasks,
        mode,
        handleTaskCompletion,
        taskIdsToComplete,
        maxTasksToShow,
        isPersonal,
    } = props;

    if (!tasks || tasks.length === 0) {
        return null;
    }

    return (
        <div className="checklist-dropdown-section-container" data-testid="checklist-dropdown-section-container">
            <p className={`checklist-dropdown-section-title checklist-dropdown-section-title--${mode}`}>{title}</p>
            {tasks.map((task, index) => {
                return index < maxTasksToShow && (
                    <div className="checklist-task" data-testid="checklist-task" key={task.taskId}>
                        <ChecklistDropdownCard
                            task={task}
                            mode={mode}
                            handleComplete={handleTaskCompletion}
                            toComplete={taskIdsToComplete.indexOf(task.taskId) > -1}
                            isPersonal={isPersonal}
                        />
                    </div>
                )
            })}
        </div>
    );
}
