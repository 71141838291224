import ApiService from './ApiService';

const PhotoService = {
    getDownloadUrl: (data, service = ApiService()) => {
        return service
            .post(`/photos/batch`, data, false).then((response) => response)
            .catch((error) => error.response);
        },
};

export default PhotoService;
