import Constants from 'Constants';
import { AuthService } from 'services';
import store from 'redux/configureStore';
import {
  handleTokenRefreshSuccessAction,
  handleTokenRefreshErrorAction,
  handleApiFatalErrorAction,
  handleRedirectForbiddenErrorAction,
  handleRedirect404ErrorAction,
} from 'redux/actions/Login/LoginActions';
import { createGetTasksSuccessOnRetryAction } from 'redux/actions/Tasks/TasksActions';
import { createGetNotificationsSuccessOnRetryAction } from 'redux/actions/Notifications/NotificationsActions';
import { handleExceptionEvent } from 'utilities/AnalyticsEvents';
import Utils from 'utilities/Utils';
import { Event } from 'components/Analytics';
import { isIOS, isSafari } from 'react-device-detect';

const { fatalErrorCodes } = Constants.global.errors;

// Parse all bad error messages from API here and transform into human readable message
export function parseErrorMessage(response, useApiMessage = false) {
  if (response.status === 401) {
    return { message: Constants.login.errorMessages.unauthorized };
  }
  if (
    response &&
    response.request &&
    response.request.responseURL &&
    (response.request.responseURL.indexOf('/Lots/') > 0 ||
      response.request.responseURL.indexOf('/invites/') > 0 ||
      response.request.responseURL.indexOf('/changepassword') > 0)
  ) {
    try {
      const json = JSON.parse(response.request.response);
      return {
        message:
          json.message ||
          response.message ||
          Constants.global.errors.generic.fail,
        title: json.title || response.title || '',
      };
    } catch (e) {
      return {
        message: response.message || Constants.global.errors.generic.fail,
        title: response.title || '',
      };
    }
  } else if (useApiMessage) {
    let r = null;
    try {
      r = response.data
        .text()
        .then((res) => {
          const json = JSON.parse(res);
          return {
            message:
              json && json.message
                ? json.message
                : Constants.global.errors.generic.fail,
            title: json && json.title ? json.title : '',
          };
        })
        .catch((e) => {
          return {
            message: Constants.global.errors.generic.fail,
          };
        });
    } catch (e) {
      if (isIOS && isSafari) {
        return {
          message: Constants.global.errors.generic.fail,
        };
      }
    }
    return r;
  } else {
    return {
      message: response.message || Constants.global.errors.generic.fail,
    };
  }
}

// eslint-disable-next-line no-underscore-dangle
export async function _sendRequest(
  data,
  service,
  shouldHandleFatalError,
  useApiMessage = false
) {
  if (data === null) {
    console.warning(
      "In '_sendRequest', you aren't passing data, this could cause an error in refresh"
    );
  }
  const response = await service(data);

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'requestComplete',
      attributes: {
        // Return empty strings to prevent accidental inheritance of old data
        pathname: window.location.pathname || '',
        hostname: window.location.host || '',
        fragment: window.location.hash || '',
        statusCode: response.status || '',
        response: response.data || '',
      },
    });
  }

  const { dispatch } = store;
  if (
    (!!response && (response.name === 'ERROR' || response.status >= 400)) ||
    response.toString().indexOf('401') >= 0
  ) {
    if (shouldHandleFatalError && fatalErrorCodes.includes(response.status)) {
      handleExceptionEvent(response);
      dispatch(handleApiFatalErrorAction(response.request.responseURL.toLowerCase().indexOf('/envision?') > 0 ? 'envision' : null));
    }
    if (shouldHandleFatalError && response.status === 403) {
      handleExceptionEvent(response);
      const redirectType = Utils.findRedirectType(response.config.url);
      dispatch(handleRedirectForbiddenErrorAction(redirectType));
    }
    if (shouldHandleFatalError && response.status === 404) {
      handleExceptionEvent(response);
      const redirectType = Utils.findRedirectType(response.config.url);
      if (
        redirectType === 'wellsfargo' ||
        redirectType === 'envision' ||
        redirectType === 'warranty'
      ) {
        dispatch(handleRedirect404ErrorAction(response.data));
      } else {
        dispatch(handleApiFatalErrorAction());
      }
    }
    if (response.status === 401 || response.toString().indexOf('401') >= 0) {
      if (
        window.location.href.indexOf('logout=true') < 0 &&
        window.location.pathname !== '/invite'
      ) {
        const refreshResponse = await AuthService.refresh();
        if (data.isRetry) {
          dispatch(handleTokenRefreshErrorAction());
        }
        if (
          !!refreshResponse &&
          (refreshResponse.name === 'ERROR' || refreshResponse.status >= 400)
        ) {
          dispatch(handleTokenRefreshErrorAction());
        }
        if (
          window.location.href.indexOf('logout=true') < 0 &&
          refreshResponse.data &&
          refreshResponse.status === 200
        ) {
          dispatch(handleTokenRefreshSuccessAction(refreshResponse));
          if (data && data.accessToken === refreshResponse.data.accessToken) {
            dispatch(handleTokenRefreshErrorAction());
          } else {
            data.accessToken = refreshResponse.data.accessToken;
            const wasRetry = data.isRetry;
            data.isRetry = true;
            if (!wasRetry) {
              return await _sendRequest(data, service, false);
            } else {
              Event('error', response.config.url);
            }
          }
        }
      } else {
        dispatch(handleTokenRefreshErrorAction());
      }
    } else {
      handleExceptionEvent(response);
      if (useApiMessage) {
        throw await parseErrorMessage(response, useApiMessage);
      } else {
        throw parseErrorMessage(response);
      }
    }
  }
  if (!response.data && response.status === 200) {
    return 'success';
  }
  if (
    response.data &&
    response.config &&
    response.config.url.indexOf('Tasks') > -1 &&
    data.isRetry
  ) {
    dispatch(createGetTasksSuccessOnRetryAction(response.data));
  }

  if (
    response.data &&
    response.config &&
    response.config.url.indexOf('Notifications') > -1 &&
    data.isRetry
  ) {
    dispatch(createGetNotificationsSuccessOnRetryAction(response.data));
  }
  return response.data;
}

// eslint-disable-next-line no-underscore-dangle
export async function _sendAuthRequest(data, service) {
  const response = await service(data);
  if (!!response && (response.name === 'ERROR' || response.status >= 400)) {
    handleExceptionEvent(response);
    throw parseErrorMessage(response);
  }
  if (!response.data && response.status === 200) {
    return 'success';
  }
  return response.data;
}
