import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Title } from 'ComponentLibrary';
import Constants from 'Constants';
import { Link } from 'react-router-dom';
import './404.scss';

const errorIcon = (<svg viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M73.72 92.357H63.59v10.13h10.13v-10.13zM73.72 35.837H63.59v45.46h10.13v-45.46z" fill="#4698B3"/><path d="M68.62 137.817c37.93 0 68.69-30.72 68.69-68.65 0-37.94-30.76-68.66-68.69-68.66C30.69.507 0 31.227 0 69.167c0 37.93 30.69 68.65 68.62 68.65zm.04-127.18c32.33 0 58.52 26.19 58.52 58.52s-26.19 58.52-58.52 58.52-58.52-26.19-58.52-58.52c-.01-32.33 26.18-58.52 58.52-58.52z" fill="#4698B3"/></svg>);

const Missing = () => {
    return (
        <React.Fragment>
            <Title title={Constants.pages.missing.pageTitle} />
            <main className="main main--container main--no-vertical-padding" data-testid="main-missing">
                <Container fluid="lg">
                    <Row>
                        <Col
                            lg={12}
                            md={12}
                            sm={12}
                            className="col--no-pad-mobile"
                        >
                            <div className="generic-container generic-container--blue-bg generic-container--extra-side-padding-desktop generic-container--bg-neutral-light bg-neutral-light missing">
                                <div className="missing__icon">{errorIcon}</div>
                                <div className="missing__content">
                                    <h1>{Constants.pages.missing.title}</h1>
                                    <p>{Constants.pages.missing.body}</p>
                                    <Link to={Constants.pages.missing.link.href}>
                                        {Constants.pages.missing.link.text}
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </React.Fragment>
    );
};
export default Missing;
