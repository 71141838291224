import React from 'react';
import ContentLoader from 'react-content-loader';

const ProgressDashboardDesktopSkeleton = () => {
    return (
        <ContentLoader
            data-testid="progress-dashboard-desktop-skeleton"
            speed={2}
            viewBox="0 0 875 810"
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="50" />
            <rect x="0" y="70" rx="2" ry="2" width="65%" height="165" />
            <rect x="0" y="255" rx="2" ry="2" width="65%" height="165" />
            <rect x="0" y="440" rx="2" ry="2" width="31%" height="165" />
            <rect x="297" y="440" rx="2" ry="2" width="31%" height="165" />
            <rect x="0" y="625" rx="2" ry="2" width="31%" height="165" />
            <rect x="297" y="625" rx="2" ry="2" width="31%" height="165" />
            <rect x="592" y="70" rx="2" ry="2" width="33%" height="275" />
        </ContentLoader>
    );
};


export default ProgressDashboardDesktopSkeleton;
