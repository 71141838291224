import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import BuyerPortalApp from 'App';
import * as serviceWorker from './serviceWorker';

const prod = window.location.host === 'my.mihomes.com' || window.location.host === 'journey.mihomes.com';
const tagManagerArgs = {
    gtmId: 'GTM-W4KP4CB',
    preview: prod ? 'env-1' : 'env-3',
    auth: prod ? 'OH_Ri-v6v5l4iBhG0XidSA' : 'Nrjz6SVShhv-WpRfqytSXQ',
};

TagManager.initialize(tagManagerArgs);
if (!window.HTMLDialogElement) {
    document.getElementsByTagName('body')[0].classList.add('no-modal-support');
}

try {
    window.addEventListener('touchstart', () => {
        document.getElementsByTagName('body')[0].classList.add('body--supports-touch')
    }, { passive: true })
} catch (e) { }

ReactDOM.render(
    <React.StrictMode>
        <BuyerPortalApp />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
