import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import './Link.scss'

const Link = ({ to, eventLabel, referrer, blank, children }) => {
    return <ReactGA.OutboundLink
        eventLabel={eventLabel}
        to={to}
        target={blank ? "_blank" : ""}
        rel="noopener noreferrer"
        className="text-link">
        {children}
    </ReactGA.OutboundLink>
};

Link.propTypes = {
    to: PropTypes.string.isRequired,
    eventLabel: PropTypes.string.isRequired,
    referrer: PropTypes.string,
    blank: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Link.defaultProps = {
    blank: true,
};


export default Link;
