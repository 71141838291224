import ApiService from './ApiService.js';

const LotService = {
    get: (options, service = ApiService()) => service.get(`/Lots/${options.lotId}`, true)
        .then(response => response)
        .catch(error => error.response),
    getBuyers: (options, service = ApiService()) => service.get(`/Lots/${options.lotId}/registeredbuyers`, true)
        .then(response => response)
        .catch(error => error.response),

};

export default LotService;
