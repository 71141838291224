import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RadioSet.scss';
import Input from 'components/FormElements/Input';

const RadioSet = (props) => {
    const {
        id,
        options,
        label,
        groupName,
        initialValue,
        testId,
        hiddenLabel,
        grid,
        onChange,
    } = props;
    const [value, setValue] = useState(initialValue || '');
    const fieldsetprops = testId ? { 'data-testid': `${testId}-fieldset` } : {};
    const legendprops = testId ? { 'data-testid': `${testId}-legend` } : {};
    return (
        <fieldset {...fieldsetprops}>
            <div className={`${grid && 'radio-set__grid'}`}>
                <legend
                    className={`legend ${hiddenLabel && 'visuallyhidden'}`}
                    {...legendprops}
                >
                    {label}
                </legend>
                {options.map((option, index) => {
                    const idval = `${groupName}-${index}`;
                    return (
                        <Input
                            testId={
                                testId && index === 0
                                    ? `${testId}-${index}`
                                    : null
                            }
                            onChange={(e) => {
                                setValue(e.currentTarget.value);
                                onChange(e.currentTarget.value, value);
                            }}
                            checked={value === option.toLowerCase()}
                            key={idval}
                            initialValue={option.toLowerCase()}
                            type="radio"
                            name={groupName}
                            id={`${id}-${idval}`}
                            label={option}
                        />
                    );
                })}
            </div>
        </fieldset>
    );
};

RadioSet.defaultProps = {
    testId: null,
};

RadioSet.propTypes = {
    options: PropTypes.array.isRequired,
    groupName: PropTypes.string.isRequired,
    testId: PropTypes.string,
};

export default RadioSet;
