import React from 'react';
import PropTypes from 'prop-types';
import {
    LinkBox,
    LocationCard,
    TabsAccordionSection,
    ResourceArticles,
    RepCard,
    Text,
} from 'ComponentLibrary';
import {
    useGetTabContactData,
    useGetConfigurationForCurrentLot,
} from 'utilities/HooksAndSelectors';
import { CSSTransition } from 'react-transition-group';

import SanitizeService from 'services/SanitizeService';
import { createGetContactForPhaseAction } from 'redux/actions/ProgressContent/ProgressContentActions';
import { useDispatch } from 'react-redux';
import Utils from 'utilities/Utils';
import TabContentImage from './TabContentImage';

const haveEnoughDataForCard = (propsForCard) => {
    return propsForCard &&
        propsForCard.repName &&
        propsForCard.repName.replace(/null/g, '').replace(' ', '').length > 0 &&
        propsForCard.repName.replace(/undefined/g, '').replace(' ', '').length >
        0;
};

const TabContent = (props) => {
    const { tab, active, overrideOfficeTitle } = props;
    const {
        body,
        resourcesTitle,
        imageLink,
        imageCaption,
        office,
        videoLink,
        stages,
        featured,
        stageKey,
        sectionHeader,
        resources,
        title,
    } = tab;
    const dispatch = useDispatch();
    const tabContactData = useGetTabContactData(stageKey);


    if (!tabContactData && active) {
        dispatch(createGetContactForPhaseAction(stageKey));
    }

    const columnFixAndShowHeader =
        stageKey === 'financing' ||
        stageKey === 'warranty' ||
        stageKey === 'design' ||
        stageKey === 'closing';

    const tabProps = active ? {} : { 'aria-hidden': 'true' };
    const lotConfiguration = useGetConfigurationForCurrentLot();
    if (lotConfiguration === null) {
        return null;
    }
    const { progress } = lotConfiguration;
    const tabConfigurationSettings = progress[stageKey] || null;
    if (!active) {
        return null;
    }

    return (
        <CSSTransition in appear timeout={500} classNames="simple-fade">
            <div
                className="tab-content__wrapper"
                key={`content-${stageKey}`}
                role="tabpanel"
                aria-labelledby={`tab-${stageKey}`}
                data-testid="tab-content"
                {...tabProps}
            >
                <div
                    className={`desktop-right-column desktop-right-column--${stageKey}`}
                >
                    {tabContactData && Array.isArray(tabContactData) && (
                        tabContactData.map((contact, index) => {
                            const propsForCard = Utils.convertToRepCardData(contact);
                            return haveEnoughDataForCard(propsForCard) ? <RepCard key={index} {...propsForCard} desktopOnly={stageKey.indexOf('construction') < 0} /> : null;
                        })

                    )}
                    {tabConfigurationSettings &&
                        tabConfigurationSettings.branchComponentLocation ===
                        'Right' && <LocationCard overrideTitle={overrideOfficeTitle} tabslug={stageKey} data={office} />}
                </div>
                <div
                    className={`desktop-left-column  desktop-left-column--${stageKey}`}
                >
                    <section
                        data-testid={`tabs__tab-content--${stageKey}`}
                        className="tab-content"
                    >
                        <div>
                            {featured && <LinkBox slug={stageKey} links={featured} />}
                            {tabContactData &&
                                Array.isArray(tabContactData) &&
                                columnFixAndShowHeader && (
                                    tabContactData.map((contact) => {
                                        const propsForCard = Utils.convertToRepCardData(contact);
                                        const haveEnough = haveEnoughDataForCard(propsForCard);
                                        const donly = stageKey.indexOf('construction') < 0 && stageKey.indexOf('loan-application') < 0 && stageKey.indexOf('financing') < 0 && stageKey.indexOf('design') < 0 && stageKey.indexOf('closing') < 0 && stageKey.indexOf('warranty') < 0 && stageKey.indexOf('customer-care-warranty') < 0;
                                        const monly = stageKey.indexOf('loan-application') >= 0 || stageKey.indexOf('financing') >= 0 || stageKey.indexOf('design') >= 0 || stageKey.indexOf('closing') >= 0 || stageKey.indexOf('warranty') >= 0;
                                        return haveEnough ? <RepCard {...propsForCard} outsideOfContainer mobileOnly={monly} desktopOnly={donly} /> : null;
                                    })
                                )}
                            <div className="tab-content__simple-html">
                                {columnFixAndShowHeader && (
                                    <Text
                                        type="block"
                                        variant="progress-medium"
                                        semibold
                                        color="indigo"
                                    >
                                        {sectionHeader}

                                    </Text>
                                )}

                                {(imageLink || videoLink) && (
                                    <TabContentImage
                                        imageLink={imageLink}
                                        videoLink={videoLink}
                                        altText={imageCaption || `${title} Header Image`}
                                    />
                                )}
                            </div>

                            {stages && (
                                <TabsAccordionSection
                                    stages={stages}
                                    stageKey={stageKey}
                                    phaseTitle={title}
                                />
                            )}

                            {body && (
                                <div
                                    className="tab-content__simple-html"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: SanitizeService.sanitize(body),
                                    }}
                                />
                            )}
                            {resources && resources.primary && (
                                <ResourceArticles
                                    title={resourcesTitle}
                                    articles={resources.primary}
                                />
                            )}
                            {tabConfigurationSettings &&
                                tabConfigurationSettings.branchComponentLocation ===
                                'Left' && (
                                    <LocationCard
                                        overrideTitle={overrideOfficeTitle}
                                        tabslug={stageKey}
                                        left data={office} />
                                )}
                        </div>
                    </section>
                </div>
            </div>
        </CSSTransition>
    );
};

TabContent.defaultProps = {};

TabContent.propTypes = {
    tab: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export default TabContent;


