import ActionTypes from 'redux/actions/ActionTypes';

export function createOpenPhotoModalAction(modalData) {
    return {
        type: ActionTypes.OpenPhotoModal,
        payload: modalData,
    };
}

export function createShowShareModalAction() {
    return {
        type: ActionTypes.OpenShareModal,
    };
}

export function createLaunchFilterPhotosModalAction() {
    return {
        type: ActionTypes.LaunchFilterPhotosModal,
    };
}

export function createCloseFilterPhotosModalAction(tags = null) {
    return {
        type: ActionTypes.CloseFilterPhotosModal,
        payload: tags,
    };
}

export function createTogglePhotoInDownloadListAction(index) {
    return {
        type: ActionTypes.TogglePhotoInDownloadListAction,
        payload: index,
    };
}
export function createClearDownloadDataAction() {
    return {
        type: ActionTypes.ClearPhotoDownloadData,
    };
}
export function createGetDownloadPhotosUrlAction() {
    return {
        type: ActionTypes.GetDownloadPhotosUrl,
    };
}

export function createMarkAllPhotosForDownloadAction(length) {
    const arr = [];
    for(var i = 0; i<length; i++) {
        arr.push(i);
    }
    return {
        type: ActionTypes.MarkAllPhotosForDownload,
        payload: arr,
    };
}

export function createToggleSelectForDownloadModeAction() {
    return {
        type: ActionTypes.ToggleSelectForDownloadeMode,
    };
}

export function createExitSelectForDownloadModeAction() {
    return {
        type: ActionTypes.ExitSelectForDownloadeMode,
    };
}

export function createClosePhotoModalAction() {
    return {
        type: ActionTypes.ClosePhotoModal,
    };
}

export function createSetPhotoModalPageNumberAction(pageNumber) {
    return {
        type: ActionTypes.SetPhotoModalPageNumber,
        payload: pageNumber,
    };
}

export function createSetPhotoModalCaptionAction(caption) {
    return {
        type: ActionTypes.SetPhotoModalCaption,
        payload: caption,
    };
}
