import React from 'react';
import ContentLoader from 'react-content-loader';

const ChecklistDropdownSkeleton = () => {
    return (
        <React.Fragment>
            <ContentLoader
                data-testid="checklist-desktop-dropdown-skeleton"
                speed={2}
                viewBox="0 0 343 180"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <rect x="15" y="0" rx="2" ry="2" width="90%" height="40" />
                <rect x="15" y="50" rx="2" ry="2" width="90%" height="40" />
                <rect x="15" y="110" rx="2" ry="2" width="90%" height="50" />
            </ContentLoader>

            <ContentLoader
                data-testid="checklist-desktop-dropdown-skeleton-overlay"
                speed={2}
                viewBox="0 0 343 180"
                backgroundColor={'#E3E3E3'}
                foregroundColor={'#999'}
                style={{
                    position: 'absolute',
                    left: '1px',
                }}
            >
                <circle cx="40" cy="20" r="13" />
                <circle cx="40" cy="70" r="13" />
            </ContentLoader>
        </React.Fragment>
    );
};


export default ChecklistDropdownSkeleton;
