/* eslint-disable no-useless-escape */
import ApiService from './ApiService';

const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const PWAService = {
    getPushNotificationSubscription: async () => {
        if ('serviceWorker' in navigator) {
            const swRegistration = window.swRegistration;
            if (!swRegistration || !swRegistration.pushManager) {
                return null;
            }
            try {
                return swRegistration.pushManager.getSubscription();
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    },
    savePushSubscription: (subscription, service = ApiService()) => {
        return service
            .post('/Profile/pushnotifications/subscription', subscription, false)
            .then((response) => response)
            .catch((error) => error.response.data);
    },
    subscribeToPushNotifications: (notficationsData) => {
        if (notficationsData && notficationsData.enablePush) {
            Notification.requestPermission().then(async (result) => {
                if (window.swRegistration) {
                    //EVENTUALLY WE WILL WANT TO MOVE TO .ENV FILES
                    const applicationServerKey = urlB64ToUint8Array(
                        'BEAaq8oaa2UUb-sIQcr3lmKcGfVmhA2bO-ZEwkLv-P11Hk7UB5Nfyo1IbSqdShJzuM2mb_Lpdc53WdeCwDgkzsg'
                    );
                    const options = {
                        applicationServerKey,
                        userVisibleOnly: true,
                    };
                    const subscription = await window.swRegistration.pushManager.subscribe(
                        options
                    );
                    PWAService.savePushSubscription(subscription);
                }
            });
        }
    },
};

export default PWAService;
