import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ChecklistAccordionHeader from './ChecklistAccordionHeader';
import ChecklistSingleTask from './ChecklistSingleTask';
import AddPersonalTaskForm from './AddPersonalTaskForm';
import EditPersonalTaskForm from './EditPersonalTaskForm';

const PersonalUpcomingTaskSetAccordion = (props) => {
    const {
        label,
        tasks,
        mode,
        isPersonalTasksOpen,
        testId,
        showAddTaskForm,
        showEditTaskForm,
        handleToggleAddTaskForm,
        handleChangeNewTask,
        taskFormData,
        handleCancelTaskForm,
        handleSubmitTaskForm,
        handleChangeDueDate,
        isPersonalUpcomingOpen,
        handlePersonalUpcomingToggle,
        taskFormErrors,
    } = props;

    return (
        <Accordion defaultActiveKey="0" activeKey={isPersonalUpcomingOpen ? label : ''} data-testid={testId}>
            <Card>
                <ChecklistAccordionHeader
                    eventKey={label}
                    label={label}
                    mode={mode}
                    isOpen={isPersonalUpcomingOpen}
                    showTaskForm={showAddTaskForm || showEditTaskForm}
                    canAddTask
                    handleToggle={handlePersonalUpcomingToggle}
                    handleToggleAddTaskForm={handleToggleAddTaskForm}
                />
                <Accordion.Collapse eventKey={label}>
                    <div>
                        {showAddTaskForm && (
                            <AddPersonalTaskForm
                                handleChange={handleChangeNewTask}
                                taskData={taskFormData}
                                handleCancelTaskForm={handleCancelTaskForm}
                                handleSubmitTaskForm={handleSubmitTaskForm}
                                handleChangeDueDate={handleChangeDueDate}
                                taskFormErrors={taskFormErrors}
                            />
                        )}
                        {showEditTaskForm && (
                            <EditPersonalTaskForm
                                handleChange={handleChangeNewTask}
                                taskData={taskFormData}
                                handleCancelTaskForm={handleCancelTaskForm}
                                handleSubmitTaskForm={handleSubmitTaskForm}
                                handleChangeDueDate={handleChangeDueDate}
                                taskFormErrors={taskFormErrors}
                            />
                        )}
                        {tasks.map((task, index) => (
                            <ChecklistSingleTask
                                key={label + task.taskId}
                                isPersonalTasksOpen={isPersonalTasksOpen}
                                sectionLabel={label}
                                task={task}
                                isPersonal
                            />
                        ))}
                    </div>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

PersonalUpcomingTaskSetAccordion.propTypes = {
    label: PropTypes.string.isRequired,
    tasks: PropTypes.array.isRequired,
    mode: PropTypes.string.isRequired,
    testId: PropTypes.string,
};
export default PersonalUpcomingTaskSetAccordion;
