import ActionTypes from 'redux/actions/ActionTypes';

export function createCheckInviteTokenAction(token) {
    return {
        type: ActionTypes.CheckInviteToken,
        payload: token,
    };
}

export function createSubmitPasswordAction(data) {
    return {
        type: ActionTypes.SubmitPassword,
        payload: data,
    };
}

export function createInvalidTokenAction() {
    return {
        type: ActionTypes.InviteTokenInvalid,
    };
}
