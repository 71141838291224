import ApiService from './ApiService';

const HomesService = {
    getCommunities: (data, service = ApiService()) =>
        service
            .get(`/Communities?divisionSitecoreId=${data.divisionId}`, data)
            .then((response) => response)
            .catch((error) => error.response),
    getDivisions: (data, service = ApiService()) =>
        service
            .get('/Divisions', true)
            .then((response) => response)
            .catch((error) => error.response),
    searchByName: (data, service = ApiService()) =>
        service
            .post('/Lots/search/registeredbuyer', data, false)
            .then(response => response)
            .catch(error => error),
    searchByAddress: (data, service = ApiService()) =>
        service
            .post('/Lots/search/address', data, false)
            .then(response => response)
            .catch(error => error)
};

export default HomesService;
