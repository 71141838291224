export function sortDeposits(depositsArray, type) {
    return depositsArray.sort((a, b) => {
        switch (type) {
            case 'processed-ascending':
                return a.processedDate < b.processedDate ? -1 : 1;
            case 'processed-descending':
                return a.processedDate > b.processedDate ? -1 : 1;
            case 'scheduled-ascending':
                return a.scheduledDate < b.scheduledDate ? -1 : 1;
            case 'scheduled-descending':
                return a.scheduledDate > b.scheduledDate ? -1 : 1;
            case 'amount-ascending':
                    return a.amount < b.amount ? -1 : 1;
            case 'amount-descending':
                    return a.amount > b.amount ? -1 : 1;
            default:
                return 0;
        }
    });
}