import ActionTypes from 'redux/actions/ActionTypes';
/* eslint-disable indent */
import buildLotProgressData from './ProgressContentTransform';

const initialState = {
    lots: {},
};

// used to convert the progress object to array to preserve the order
function* values(obj) {
    for (const prop of Object.keys(obj)) {
        const ret = obj[prop];
        if(ret) {
            ret.stageKey = prop.toLowerCase();
        }
        yield ret;
    }
}

const ProgressContentReducer = (state = initialState, action) => {
    if (!action || !action.type) {
        return state;
    }
    if (action.type === ActionTypes.LogOut || action.type === ActionTypes.OpenFindLotPage) {
        return {
            lots: {},
        };
    }
    // this is in a try because if we don't have lot id, we just want to escape & go back
    if (!action.payload || !action.payload.lotId) {
        return state;
    }

    const { lotId } = action.payload;
    if (!lotId || lotId === undefined) {
        return state;
    }
    const { lots } = state;
    const oldLot = lots[lotId] || {};
    let newLot = lots[lotId.toString()] || {};

    let progressArray = [];
    const newLots = { ...lots };

    switch (action.type) {
        case ActionTypes.GetProgressContent:
            newLot.contentRequested = true;
            newLots[lotId.toString()] = newLot;
            return {
                lots: newLots,
            };
        case ActionTypes.GetContactForPhase:
            if (!state.lots[action.payload.lotId]) {
                return state;
            }
            let newProgressWithContact = null;
            if (state.lots[action.payload.lotId]) {
                if (state.lots[action.payload.lotId].progress) {
                    newProgressWithContact = [
                        ...state.lots[action.payload.lotId].progress,
                    ];
                }
            } else if (state.lots[parseInt(action.payload.lotId, 10)]) {
                if (state.lots[parseInt(action.payload.lotId, 10)].progress) {
                    newProgressWithContact = [
                        ...state.lots[parseInt(action.payload.lotId, 10)].progress,
                    ];
                }
            }
            if (newProgressWithContact) {
                newProgressWithContact.forEach((phase, index) => {
                    if (action.payload.tabSlug === phase.stageKey) {

                        newProgressWithContact[index].contact = {
                            status: 'loading',
                        };
                    }
                });

                newLots[
                    action.payload.lotId.toString()
                ].progress = newProgressWithContact;
            }

            return {
                lots: newLots,
            };
        case ActionTypes.GetContactForPhaseRequest:
            if (!newLot || !newLot.progress) {
                return state;
            }
            newLot.progress.forEach((prog, index) => {
                if (
                    prog.stageKey.toLowerCase() ===
                    action.payload.tabSlug.toLowerCase()
                ) {
                    newLot.progress[index].contact = 'requested';
                    prog.contact = 'requested'; // action.payload.data;
                }
            });
            newLots[lotId.toString()] = newLot;
            return {
                lots: newLots,
            };

        case ActionTypes.GetContactForPhaseSuccess:
        case ActionTypes.GetContactForPhaseError:
            if (!newLot || !newLot.progress) {
                return state;
            }
            newLot.progress.forEach((prog, index) => {
                if (
                    prog.stageKey.toLowerCase() ===
                    action.payload.tabSlug.toLowerCase()
                ) {
                    newLot.progress[index].contact = action.payload.data;
                    prog.contact = action.payload.data;
                }
            });

            newLots[lotId.toString()] = newLot;
            return {
                lots: newLots,
            };
        case ActionTypes.GetLotConfiguration:
        case ActionTypes.GetLotConfigurationRequest:
            newLot.configuration = 'requested';
            lots[lotId.toString()] = newLot;
            return {
                lots,
            };
        case ActionTypes.GetLotConfigurationSuccess:
            newLot.configuration = action.payload.data;
            lots[lotId.toString()] = newLot;
            return {
                lots,
            };

        case ActionTypes.GetProgressContentLotDetails:
            newLot = { ...oldLot, ...action.payload.data };

            try {
                progressArray = action.payload.data.progress
                    ? Array.from(values(action.payload.data.progress))
                    : [];
                if (progressArray.length === 0) {
                    progressArray = null;
                }
            } catch (e) {}
            newLot.progress = progressArray;
            lots[lotId.toString()] = newLot;
            return {
                lots,
            };
        case ActionTypes.GetProgressContentLotContent:
            progressArray = Array.from(values(action.payload.data.progress));
            if (lotId && lots[lotId.toString()]) {
                lots[lotId.toString()].documentsPage = action.payload.data && action.payload.data.documentsPage ? action.payload.data.documentsPage.featured : null;
                lots[lotId.toString()].progress = buildLotProgressData(
                    lotId,
                    lots,
                    progressArray
                );
            }

            return {
                lots,
            };
        default:
            return state;
    }
};

export default ProgressContentReducer;
