import React from 'react';
import PropTypes from 'prop-types';
import './ProgressAccordion.scss';
import { Accordion, Card } from 'react-bootstrap';
import ProgressAccordionHeader from './ProgressAccordionHeader/ProgressAccordionHeader';
import AccordionCard from './AccordionCard/AccordionCard';


export default function ProgressAccordion(props) {
    const {
        title,
        body,
        imageLink,
        videoLink,
        indicator,
        testId,
        defaultOpen,
        phaseTitle,
        status,
    } = props;
    return (
        <div className="progress-accordion-container" data-testid={testId}>
            <Accordion defaultActiveKey="1">
                <Card>
                    <ProgressAccordionHeader
                        title={title}
                        indicator={indicator}
                        defaultOpen={defaultOpen}
                        status={status}
                    />
                    <Accordion.Collapse eventKey={defaultOpen ? '1' : '0'}>
                        <Card.Body>
                            <AccordionCard
                                imageUrl={imageLink}
                                videoUrl={videoLink}
                                content={body}
                                altText={title}
                                phaseTitle={phaseTitle}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

ProgressAccordion.defaultProps = {
    title: null,
    body: null,
    imageLink: null,
    videoLink: null,
    indicator: null,
    testId: 'progress-accordion-container',
    defaultOpen: false,
};

ProgressAccordion.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    imageLink: PropTypes.string,
    videoLink: PropTypes.string,
    indicator: PropTypes.element,
    testId: PropTypes.string,
    defaultOpen: PropTypes.bool,
};
