import ActionTypes from 'redux/actions/ActionTypes';

export function createOpenBioModalAction(contactData) {
    return {
        type: ActionTypes.OpenContactBioModal,
        payload: contactData,
    };
}

export function createCloseBioModalAction() {
    return {
        type: ActionTypes.CloseContactBioModal,
    };
}
