import ActionTypes from 'redux/actions/ActionTypes';

const initialState = null;

const ContactBioReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OpenContactBioModal:
            return { ...action.payload };
        case ActionTypes.CloseContactBioModal:
            return null;
        default:
            return state;
    }
};
export default ContactBioReducer;
