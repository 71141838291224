import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import {
    LotService,
    ContentService,
    ConfigurationService,
    ContactService,
} from 'services';
import { withCurrentLotId } from 'services/ServiceHelpers';

export async function getProgressContentStepStatus(data) {
    try {
        return await _sendRequest(data, LotService.get, true);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function getProgressContentStepDetails(data) {
    try {
        return await _sendRequest(data, ContentService.get, true);
    } catch (error) {
        return buildErrorObject();
    }
}

export async function getLotConfiguration(data) {
    try {
        return await _sendRequest(data, ConfigurationService.get);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function getContact(data) {
    try {
        const ret = await _sendRequest(data, ContactService.get, true);
        return ret;
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* configurationSaga(action) {
    yield put({
        type: ActionTypes.GetLotConfigurationRequest,
        payload: action.payload,
    });
    const response = yield call(getLotConfiguration, action.payload);
    if (!!response && !response.errors) {
        yield put({
            type: ActionTypes.GetLotConfigurationSuccess,
            payload: {
                lotId: action.payload.lotId,
                data:
                    response ||
                    buildErrorObject({ message: 'An error occurred' }),
            },
        });
    } else {
        yield put({
            type: ActionTypes.GetLotConfigurationError,
            payload:
                response || buildErrorObject({ message: 'An error occurred' }),
        });
    }
}

export function* contactRequestSaga(action) {
    yield put({
        type: ActionTypes.GetContactForPhaseRequest,
        payload: { tabSlug: action.payload.tabSlug, lotId: withCurrentLotId() },
    });
    try {
        const response = yield call(getContact, action.payload);
        const nums = new RegExp(/^[0-9]+$/);
        const array = [];
        // eslint-disable-next-line array-callback-return
        Object.keys(response).map((key) => {
            if (nums.test(key)) {
                array.push(response[key]);
            }
        });

        yield put({
            type: ActionTypes.GetContactForPhaseSuccess,
            payload: {
                tabSlug: action.payload.tabSlug,
                lotId: response.lotId,
                data:
                    array || buildErrorObject({ message: 'An error occurred' }),
            },
        });
    } catch (e) {
        yield put({
            type: ActionTypes.GetContactForPhaseError,
            payload: {
                tabSlug: action.payload.tabSlug,
                lotId: withCurrentLotId('$lotId'),
                data: {
                    status: 'not-found',
                },
            },
        });
    }
}

// eslint-disable-next-line import/prefer-default-export
export function* progressContentSaga(action) {
    const progressStepStatus = yield call(
        getProgressContentStepStatus,
        action.payload
    );
    if (!!progressStepStatus && !progressStepStatus.errors) {
        yield put({
            type: ActionTypes.GetProgressContentLotDetails,
            payload: {
                lotId: action.payload.lotId,
                data: progressStepStatus,
            },
        });

        const progressStepDetails = yield call(
            getProgressContentStepDetails,
            action.payload
        );
        if (!!progressStepDetails && !progressStepDetails.errors) {
            yield put({
                type: ActionTypes.GetProgressContentLotContent,
                payload: {
                    lotId: action.payload.lotId,
                    data: {
                        progress: progressStepDetails.progress,
                        documentsPage: progressStepDetails.documentsPage,
                        warrantyPage: progressStepDetails.warrantyPage,
                    },
                },
            });
        }
    } else {
        yield put({
            type: ActionTypes.GetProgressContentError,
            payload:
                progressStepStatus ||
                buildErrorObject({ message: 'An error occurred' }),
        });
    }
}
