import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './LoginForm.scss';
import {
    Input,
    Text,
    Heading,
    Error,
    Button,
    ButtonStyles,
} from 'ComponentLibrary';
import CookieService from 'services/CookieService';
import Constants from 'Constants';
import Links from 'components/Links';
import { createLoginFromADOEventAction } from 'redux/actions/Login/LoginActions';
import { useDispatch } from 'react-redux';
import { msalAuth } from 'services/ADOHelpers';
import SanitizeService from 'services/SanitizeService';
import {
    handleTokenRefreshSuccessAction,
} from 'redux/actions/Login/LoginActions';

const tokenRequest = {
    scopes: ['user.read'],
};

const testIsMi = (value) => value &&
    (value.toLowerCase().indexOf('mihomes.com') >= 0 || value.indexOf('microsoft.com') >= 0);

const LoginForm = (props) => {
    const { errors, testId, busy, onSubmit, onUpdate, message } = props;
    const location = useLocation();
    const { search } = location;
    const history = useHistory();
    const dispatch = useDispatch();

    const [userForm, setState] = useState({
        'user-login': {
            emailAddress: CookieService.getCookie('remember'),
        },
    });

    const [iframe, setRenewIframe] = useState(false);

    const ssoRequest = {
        loginHint:
            userForm['user-login'].emailAddress ||
            (document.getElementById('emailAddress') &&
                document.getElementById('emailAddress').value) ||
            null,
    };

    const [isMiAddress, setIsMiAddress] = useState(
        testIsMi(ssoRequest.loginHint)
    );

    useEffect(() => {
        msalAuth.handleRedirectCallback(
            async () => {
                msalAuth
                    .acquireTokenSilent(tokenRequest)
                    .then((response) => {
                        if (
                            !busy &&
                            (!errors || Object.keys(errors).length === 0) &&
                            search.indexOf('logout=true') < 0
                        ) {
                            dispatch(
                                createLoginFromADOEventAction(
                                    response.idToken.rawIdToken
                                )
                            );
                        }
                    })
                    .catch(async (err) => {
                        if (err.toString().indexOf("Seamless single sign on failed for the user") >= 0) {
                            return msalAuth.acquireTokenRedirect(tokenRequest);//.then(function(accessTokenResponse) {
                            //     // Acquire token interactive success
                            //     return accessTokenResponse;
                            // }).catch(function(error) {
                            //     // Acquire token interactive failure
                            //     console.log(error);
                            // });
                            // if (res) {
                            //     const eventToDispatch = handleTokenRefreshSuccessAction({data: res});
                            //     if (eventToDispatch && eventToDispatch.payload && eventToDispatch.payload.data && eventToDispatch.payload.data.accessToken) {
                            //         dispatch(eventToDispatch);
                            //     }
                            // }
                        } else if (err.errorMessage.indexOf("interaction_required") !== -1) {
                            return msalAuth.acquireTokenRedirect(tokenRequest);
                        } else  {
                            return msalAuth.acquireTokenPopup(tokenRequest).then(function(accessTokenResponse) {
                                // Acquire token interactive success
                            }).catch(function(error) {
                                // Acquire token interactive failure
                                console.log(error);
                            });
                        }
                        return null;
                    });
            },
            () => {}
        );

        if (msalAuth.isCallback(window.location.hash)) {
            setRenewIframe(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssoRequest, errors, busy, dispatch, userForm]);

    const updateValue = (e) => {
        e.persist();
        const { name, checked, value } = e.target;
        if (name === 'emailAddress') {
            const isMi = testIsMi(value);
            if (isMi !== isMiAddress) {
                setIsMiAddress(isMi);
            }
        }
        const newUserForm = { ...userForm }; // .cop
        newUserForm['user-login'][name] = value;
        setState(newUserForm);
        onUpdate({
            [name]: e.target && e.target.type === 'checkbox' ? checked : value,
        });
    };

    const formTestId = testId ? { 'data-testid': `${testId}-form` } : null;
    const submitTestId = testId ? { 'data-testid': `${testId}-submit` } : null;
    if (iframe) {
        return null;
    }
    return (
        <div className="login-form">
            <form
                className={`form form--login ${busy ? 'form--busy' : ''}`}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (window.ga) {
                        window.ga('set', 'dimension1', 'customer');
                    }
                    onSubmit(userForm);
                }}
                {...formTestId}
            >
                <div className="login-form__section">
                    <Heading centered as="h1" like="h1">
                        {Constants.login.text.heading}
                    </Heading>
                    {message && message.length > 0 && (
                        <div className="login-form__message" dangerouslySetInnerHTML={{__html: SanitizeService.sanitize(message)}} />
                    )}
                    {errors &&
                        errors.form &&
                        errors.form.map((error, index) => (
                            <Error key={index}>{error.message}</Error>
                        ))}
                    <Input
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        label={Constants.login.inputs.email.label}
                        placeholder={Constants.login.inputs.email.placeholder}
                        maxLength="50"
                        errorMessageRequired={
                            Constants.login.inputs.email.errorMessageRequired
                        }
                        errorMessageType={
                            Constants.login.inputs.email.errorMessageType
                        }
                        autocomplete="username"
                        onChange={updateValue}
                        autofocus
                        initialValue={userForm['user-login'].emailAddress}
                    />
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        label={Constants.login.inputs.password.label}
                        placeholder={
                            Constants.login.inputs.password.placeholder
                        }
                        maxLength="50"
                        errorMessageRequired={
                            Constants.login.inputs.password.errorMessageType
                        }
                        errorMessageType={
                            Constants.login.inputs.password.errorMessageType
                        }
                        onChange={updateValue}
                        autocomplete="current-password"
                    />
                    <Input
                        type="checkbox"
                        name="remember"
                        id="remember"
                        label={Constants.login.inputs.rememberMe.label}
                        required={false}
                        onChange={updateValue}
                    />

                    <Button
                        as="button"
                        text={Constants.login.text.loginButton}
                        submit
                        style={ButtonStyles.PrimaryButton}
                        bold
                        {...submitTestId}
                    />
                    {isMiAddress && (
                        <button
                            className="button primary primary-button"
                            type="button"
                            onClick={() => {
                                history.push('/login');
                                msalAuth.loginRedirect({});
                            }}
                        >
                            <span className="button__text button__text--bold">
                                {Constants.login.text.admin}
                            </span>
                        </button>
                    )}

                    <div className="form-bottom-links">
                        <div>
                            <Links.ForgotPasswordLink />
                        </div>
                        <div>
                            <Text type="inline" variant="small">
                                {Constants.login.text.notAMember}
                            </Text>
                            &nbsp;
                            <Links.RegisterAccountLink />
                        </div>
                        <div>
                            <Links.PrivacyPolicyLink />
                            <span>{' • '}</span>
                            <Links.TermsOfUseLink />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

LoginForm.defaultProps = {
    testId: null,
};

LoginForm.propTypes = {
    testId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    busy: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default LoginForm;
