import ApiService from './ApiService';

const DepositsService = {
    getDeposits: (data, service = ApiService()) => service
        .get(`/Deposits/${data.lotId}`, true)
        .then((response) => response)
        .catch((error) => error.response),
};

export default DepositsService;
