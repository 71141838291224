import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createOpenNotificationsDropdownAction,
    createCloseNotificationsDropdownAction,
    createGetNotificationsAction,
    createSetOpenNotificationAction,
} from 'redux/actions/Notifications/NotificationsActions';
import './NotificationsToggle.scss';
import Icon from '../Icon/Icon';
import { cleanHash } from 'utilities/Utils';

export default function NotificationsToggle() {
    const dispatch = useDispatch();
    const notificationsState = useSelector((state) => state.notificationsState);
    const {
        unreadNotifications,
        notificationsDropDownOpen,
    } = notificationsState;

    const handleNotificationsToggle = () => {
        cleanHash();
        if (notificationsDropDownOpen){
            dispatch(createCloseNotificationsDropdownAction());
            dispatch(createGetNotificationsAction());
            dispatch(createSetOpenNotificationAction(null));
        } else {
            dispatch(createOpenNotificationsDropdownAction());
        };
        return false;
    };
    const hasUnreadNotifications = Array.isArray(unreadNotifications) ? unreadNotifications.length > 0 : false;
    return (
        <button
            className={`notifications-toggle-container ${notificationsDropDownOpen && 'active'}`}
            onClick={handleNotificationsToggle}
            data-testid="notifications-toggle-container"
        >
            <div className="notifications-toggle-icon">
                <Icon
                    size={1.2}
                    testId={
                        hasUnreadNotifications
                            ? 'bell-alert'
                            : 'bell'
                    }
                    icon={
                        hasUnreadNotifications
                            ? 'notificationBellAlert'
                            : 'notificationBell'
                    }
                    color="white"
                />
            </div>
        </button>
    );
}
