import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'ComponentLibrary';
import {
    createCloseChecklistDropdownAction,
    createOpenChecklistDropdownAction,
} from 'redux/actions/Tasks/TasksActions';
import './ChecklistToggle.scss';
import Constants from 'Constants';
import Icon from '../Icon/Icon';
import { cleanHash } from 'utilities/Utils';

export default function ChecklistToggle() {
    const dispatch = useDispatch();
    const taskState = useSelector((state) => state.taskState);
    const { checklistDropDownOpen } = taskState;

    const handleChecklistToggle = () => {
        cleanHash();
        const action = checklistDropDownOpen
            ? createCloseChecklistDropdownAction()
            : createOpenChecklistDropdownAction();
        dispatch(action);
        return false;
    };

    return (
        <button
            type="button"
            className={`checklist-toggle-container ${
                checklistDropDownOpen ? 'open' : ''
            }`}
            onClick={handleChecklistToggle}
            id={`${
                checklistDropDownOpen
                    ? 'close-checklist-dropdown'
                    : 'open-checklist-dropdown'
            }`}
            data-testid="checklist-toggle-container"
        >
            <div className="checklist-toggle-icon">
                <Icon size={1} icon="thickCheckmark" color="indigo" />
            </div>
            <Text type="block" variant="small" color="white">
                {Constants.checklistDropdown.checklist}
            </Text>
        </button>
    );
}
