import React from 'react';
import { Text } from 'ComponentLibrary';
import Constants from 'Constants';
import Link from './Link';

const TermsOfUseLink = () => {
    return (
        <Text type="inline" variant="small">
            <Link
                eventLabel={Constants.global.links.termsOfUse.eventLabel}
                to={Constants.global.links.termsOfUse.link}
                blank>
                {Constants.global.links.termsOfUse.label}
            </Link>
        </Text>
    )
};

export default TermsOfUseLink;