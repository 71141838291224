import ActionTypes from 'redux/actions/ActionTypes';
import { withCurrentLotId } from 'services/ServiceHelpers';

export function createGetProgressContentAction(data) {
    return {
        type: ActionTypes.GetProgressContent,
        payload: data,
    };
}

export function createGetContactForPhaseAction(tabSlug) {
    return {
        type: ActionTypes.GetContactForPhase,
        payload: {
            tabSlug: tabSlug.toLowerCase(),
            lotId: withCurrentLotId(),
        },
    };
}

export function createGetLotConfigurationAction(data) {
    return {
        type: ActionTypes.GetLotConfiguration,
        payload: data,
    };
}
