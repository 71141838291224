import ApiService from './ApiService.js';

const ConfigurationService = {
    get: (options, service = ApiService()) => service.get(`/Configuration?lotId=${options.lotId}`, true)
        .then(response => response)
        .catch(error => error.response),
    getLotToolTip: (service = ApiService()) => service.get('/configuration/corporate', {}, false)
        .then(response => response)
        .catch((error) => error.response),
};

export default ConfigurationService;
