import React, { useRef, useState, useEffect } from 'react';
import { isMobile, isTablet, isFirefox, isIOS } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
    createClosePhotoModalAction,
    createShowShareModalAction,
} from 'redux/actions/PhotoModal/PhotoModalActions';
import { Text, Button, Icon, CloseButton } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import PhotoSlider from '../PhotoSlider';
import './PhotoModal.scss';

const PhotoModal = (props) => {
    const customSlider = useRef();
    const thumbSlider = useRef();
    const dispatch = useDispatch();

    const userState = useSelector((state) => state.userState);
    const photoModalState = useSelector((state) => state.photoModalState);
    const shareModalOpen = useSelector((state) => state.shareState).isOpen;

    const {
        isOpen,
        caption,
        currentImageIndex,
        currentPageNumber,
        tagsForFiltering,
    } = photoModalState;
    const { profileAndSettings, progressContent } = userState;
    const { lots } = progressContent;
    const { activeLotId } = profileAndSettings;
    const photos = lots[activeLotId] ? Utils.getFilteredPhotos(lots[activeLotId].photos, tagsForFiltering) : null;
    const imageCaption = caption;

    const totalPhotos = photos && photos.length;
    const imageGrp = photos;

    const [hideThumbs, setHideThumbs] = useState(false);
    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
        touchThreshold: 100,
        cssEase: 'linear',
    };
    const gotoPrev = () => {
        customSlider.current.slickPrev();
    };

    const gotoNext = () => {
        customSlider.current.slickNext();
    };

    const winWidth = window.matchMedia('(max-width: 764px)');

    const isMobileWindow = !!(isMobile || winWidth.matches);
    let photoModalClass = isMobileWindow
        ? 'photo-modal--mobile'
        : 'photo-modal--desktop';
    if (isMobile && !isTablet) {
        photoModalClass += ' photo-modal--true-mobile';
    }

    const disableButton = !!(totalPhotos === 1);


    const history = useHistory();
    const location = useLocation();
    const isMobileAndTouch = isMobile && 'ontouchstart' in window;

    const shareClick = (e) => {
        e.preventDefault();
        if (navigator.share) {
            navigator.share({
                title: 'M/I Homes Journey',
                text: 'Check out this photo of my new home!',
                url: photos[currentPageNumber - 1].photoLink,
            }).then(() => {
                // succuesful share
            }).catch((error) => {
                if (error.toString().indexOf('cancellation') < 0 && error.toString().indexOf('canceled') < 0) {
                    dispatch(createShowShareModalAction());
                }
            });
        } else {
            dispatch(createShowShareModalAction());
        }
        return false;
    };
    const ref = useRef(null);
    const modalContentRef = useRef(null);
    const windowClick = (e) => {
        if (e.target && e.target.children.length > 0 && !e.target.classList.contains('myhome-container__img-launcher')) {
            if (modalContentRef.current && !modalContentRef.current.contains(e.target)) {
                e.preventDefault();
                history.push(
                    location.pathname.indexOf('/photos') >= 0
                        ? '/photos'
                        : '/dashboard'
                );
                dispatch(createClosePhotoModalAction());
            }
        }
        return false;
    };

    useEffect(() => {
        if (isOpen && ref.current) {
            if (ref && ref.current && !ref.current.hasAttribute('open')) {
                if (isFirefox) {
                    ref.current.setAttribute('open', 'true');
                }
                try {
                    ref.current.showModal();
                } catch (e) {
                    ref.current.setAttribute('open', 'true');
                }
            }
            window.addEventListener('click', windowClick);

            return () => {
                window.removeEventListener('click', windowClick);
            };
        }
    }, [isOpen, ref]);

    const handleTouchMove = (e) => {
        if (e.touches.length <= 1) {
            e.preventDefault();
        }
        return false;
    };

    useEffect(() => {
        if (isOpen && isIOS) {
            window.addEventListener('touchmove', handleTouchMove, {
                passive: false,
            });
        }
        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isOpen, isIOS]);

    if (!isOpen) {
        return null;
    }

    return (
        <dialog
            ref={isOpen ? ref : null}
            className={`photo-modal ${isOpen && 'photo-modal--open'} ${photoModalClass} ${shareModalOpen && isFirefox && 'photo-modal--firefox-with-share'}`}
        >
            <div className="photo-modal__content" ref={modalContentRef}>
                <CloseButton
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(
                            location.pathname.indexOf('/photos') >= 0
                                ? '/photos'
                                : '/dashboard'
                        );
                        dispatch(createClosePhotoModalAction());
                        return false;
                    }}
                />
                <PhotoSlider
                    hideThumbs={hideThumbs}
                    setHideThumbs={setHideThumbs}
                    settings={settings}
                    images={imageGrp}
                    customSlider={customSlider}
                    thumbSlider={thumbSlider}
                    currentPhotoIndex={currentImageIndex}
                    next={gotoNext}
                    prev={gotoPrev}
                    thumbs
                />

                <div className={`photo-nav-container ${hideThumbs && 'photo-nav-container--no-bg'} ${isMobile && !isTablet && 'photo-nav-container--true-mobile'} ${isMobileAndTouch ? 'photo-nav-container--mobile photo-nav-container--disabled' : 'photo-nav-container--desktop'}`} data-testid="photo-nav-container">
                    <div className="photo-nav-button-container">
                        <div className={`photo-actions ${hideThumbs && 'photo-actions--hidden'}`}>
                            <button className="photo-action photo-action--share" type="button" onClick={shareClick}>
                                <Icon icon="share" color="white" extraClasses="icon--in-button" />
                                Share
                            </button>
                            {imageGrp && imageGrp[currentImageIndex] && (
                                <a
                                    className="photo-action photo-action--download"
                                    href={imageGrp[currentPageNumber - 1] ? imageGrp[currentPageNumber - 1].photoLink.split(':')[1] : null}
                                    download
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const url = imageGrp[currentPageNumber - 1].photoLink || null;
                                        fetch(url, {
                                            method: 'GET',
                                            cache: 'no-cache'
                                        }).then(function (response) {
                                            return response.blob()
                                        })
                                            .then(function (blob) {
                                                let link = document.createElement('a');
                                                link.download = `my-mi-home-${new Date().getTime()}-${currentImageIndex}.jpg`;
                                                link.href = URL.createObjectURL(blob);
                                                link.click();
                                                URL.revokeObjectURL(link.href);
                                            }).catch((error) => { });
                                        return false;
                                    }}
                                >
                                    <Icon icon="download" color="white" extraClasses="icon--in-button" />
                                    Download Photo
                                </a>
                            )}
                        </div>
                        {imageGrp && imageGrp.length > 1 && (
                            <>
                                <Button
                                    addClass={`photo-nav-left-button ${isMobileAndTouch && 'photo-nav-left-button--disabled'}`}
                                    testId="photo-nav-left-button"
                                    as="button"
                                    icon="prevButton"
                                    submit
                                    onClick={gotoPrev}
                                    disabled={disableButton}
                                />
                                <Button
                                    addClass={`photo-nav-right-button ${isMobileAndTouch && 'photo-nav-right-button--disabled'}`}
                                    testId="photo-nav-right-button"
                                    as="button"
                                    icon="nextButton"
                                    submit
                                    onClick={gotoNext}
                                    disabled={disableButton}
                                />
                            </>
                        )}
                        <div className={`photo-caption ${isMobileWindow ? 'photo-caption--mobile' : 'photo-caption--desktop'} ${hideThumbs && 'photo-caption--shift'}`}>
                            <Text variant="medium">{imageCaption}</Text>
                        </div>
                        <div className={`photo-nav-pagination ${hideThumbs && 'photo-nav-pagination--shift'}`}>
                            {currentPageNumber} of {totalPhotos}
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    );
};


export default PhotoModal;
