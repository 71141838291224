import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Title, TabSet } from 'ComponentLibrary';
import Constants from 'Constants';
import NameOrAddressLotSearch from './ContentComponents/NameOrAddressLotSearch';
import LotIdSearch from './ContentComponents/LotIdSearch';
import LotSearchResults from './ContentComponents/LotSearchResults';
import './FindLot.scss';
import Utils from 'utilities/Utils';
import { CookieService } from 'services';
import { createClearLotErrorsAction } from 'redux/actions/LotAssociation';

const loadAndSortLots = () => {
    let lots = [];
    if (Utils.getLocalStorage('adminLots')) {
        try {
            lots = JSON.parse(Utils.getLocalStorage('adminLots'))
        } catch (e) {
            lots = [];
        }
    }
    lots.sort((a, b) => {
        return a.timestamp > b.timestamp ? -1 : 1;
    })
    return lots.slice(0, 20);
};

const getOpposite = (order) => {
    if (order === 'desc') return 'asc';
    if (order === 'asc') return 'desc';
};

const FindLot = () => {
    const homesState = useSelector((state) => state.homesState);
    const { searchResultsByName, searchResultsByAddress } = homesState;

    const [sortedLots, setSortedLots] = useState(loadAndSortLots());
    const [sort, setSort] = useState({by: null, order: null})



    const [fourDigit, setFourDigit] = useState(null);
    const [twelveDigit, setTwelveDigit] = useState(null);
    const [community, setCommunity] = useState(null);
    const [division, setDivision] = useState(null);
    const cookie = CookieService.getCookie('searchMode');
    let initialMode = '12 digit job id';
    if (cookie === '4 digit lot number') {
        initialMode = '4 digit lot number';
    }

    const tabSpecificCookie = CookieService.getCookie('nameOrAddressSearch')

    let initialModeForAddressOrName = 'registered buyer name';
    if (cookie === 'address' || tabSpecificCookie === 'address') {
        initialModeForAddressOrName = 'address';
    }

    const [nameOrAddressMode, setNameOrAddressMode] = useState(initialModeForAddressOrName);
    const [lotMode, setLotMode] = useState(initialMode);
    const dispatch = useDispatch();

    const [searchResults, setSearchResults] = useState(nameOrAddressMode === 'registered buyer name' ? searchResultsByName : searchResultsByAddress);
    useEffect(() => {
        if (nameOrAddressMode === 'registered buyer name') {
            setSearchResults(searchResultsByName);
         } else {
            setSearchResults(searchResultsByAddress);
         }
    }, [nameOrAddressMode, searchResultsByAddress, searchResultsByName]);

    useEffect(() => {
        const sortBy = sort.by;
        const sortOrder = sort.order;
        if (!sort.by || !sort.order) {
            return;
        }

        const newLots = sortedLots.sort((a, b) => {
            if (!a[sortBy]) {
                return -1;
            }
            if (!b[sortBy]) {
                return 1;
            }
            if (sortOrder === 'asc') {
                return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : 1;
            } else {
                return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? -1 : 1;
            }
        });

        setSortedLots(newLots);
    }, [sort, sortedLots]);

    let initialTab = 0;
    if (cookie === 'recent') {
        initialTab = 2;
    } else if (cookie === '12 digit job id' || cookie === '4 digit lot number') {
        initialTab = 1;
    }
    return (
        <main className="main main--container main--no-vertical-padding-mobile" data-testid="find-lot-container">
            <Container>
                <Row className="justify-content-md-center" md={12}>
                    <Col lg={12} md={12} sm={12} className="col--no-pad-mobile">
                        <div
                            className="find-lot-page-container"
                        >
                            <div className="">
                                <Title
                                    title={
                                        Constants.pages.adminLotAssociation
                                            .pageTitle
                                    }
                                />
                                <Heading
                                    as="h1"
                                    like="h1"
                                >
                                    {Constants.pages.adminLotAssociation.header}
                                </Heading>
                                <TabSet
                                    labels={Constants.pages.findLot.labels}
                                    initialTab={initialTab}
                                    extraFuncOnTabChange={() => {
                                        dispatch(createClearLotErrorsAction())
                                    }}
                                    components={[
                                        <>
                                            <NameOrAddressLotSearch onModeChange={(value) => {
                                                setNameOrAddressMode(value)
                                            }} />
                                            {searchResults && (
                                                <LotSearchResults
                                                    id="search-by-name-or-address-results"
                                                    title="Search Results"
                                                    results={searchResults}
                                                    nameOrAddress={nameOrAddressMode}
                                                />
                                            )}
                                        </>,
                                        <LotIdSearch
                                            twelveDigit={twelveDigit}
                                            setTwelveDigit={setTwelveDigit}
                                            fourDigit={fourDigit}
                                            setFourDigit={setFourDigit}
                                            community={community}
                                            setCommunity={setCommunity}
                                            division={division}
                                            setDivision={setDivision}
                                            mode={lotMode}
                                            setMode={setLotMode}
                                        />,
                                        <LotSearchResults
                                            transparent={true}
                                            nameOrAddress={null}
                                            plain={false}
                                            results={sortedLots}
                                            override={true}
                                            passSortBy={sort.by}
                                            passSortOrder={sort.order}
                                            sortActions={[
                                                () => {
                                                    setSort({
                                                        order: sort.by === 'lotId' ? getOpposite(sort.order) : 'desc',
                                                        by: 'lotId',
                                                    });
                                                },
                                                () => {
                                                    setSort({
                                                        order: sort.by === 'jdeCommunityName' ? getOpposite(sort.order) : 'desc',
                                                        by: 'jdeCommunityName',
                                                    });
                                                },
                                                () => {
                                                    setSort({
                                                        by: 'name',
                                                        order: sort.by === 'name' ? getOpposite(sort.order) : 'desc',
                                                    });
                                                },
                                                () => {
                                                    setSort({
                                                        order: sort.by === 'address' ? getOpposite(sort.order) : 'desc',
                                                        by: 'address',
                                                    });
                                                },
                                                () => {
                                                    setSort({
                                                        order:
                                                            sort.by === 'city'
                                                                ? getOpposite(
                                                                    sort.order
                                                                ) :
                                                                'desc',
                                                        by: 'city',
                                                    });
                                                },
                                            ]}
                                        />,
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default FindLot;
