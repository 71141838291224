/* eslint-disable import/prefer-default-export */
import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import {
    ProfileService,
    ConfigurationService,
    CookieService,
    LotService,
} from 'services';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import {
    assignCurrentPage,
    buildErrorObject,
} from 'redux/sagas/sagaHelpers/userHelpers';
import { fetchProfile } from 'redux/sagas/User/userSagas';
import Constants from 'Constants';
import Utils from 'utilities/Utils';

import { Event } from 'components/Analytics';
import {
    getUser,
    getLotAssociation,
    getUserProfileAndSettings,
} from 'redux/sagas/selectors';

export const saveAdminLotsToLocalStorage = (newLot, lotId, name) => {
    newLot.progress = null;
    newLot.photos = null;
    delete newLot.progress;
    delete newLot.photos;
    const currentLots = Utils.getLocalStorage('adminLots') ? JSON.parse(Utils.getLocalStorage('adminLots')) : [];
    const timestamp = new Date().getTime();
    if (currentLots.length === 0) {
        Utils.setLocalStorage('adminLots', JSON.stringify([{...newLot, timestamp, lotId, jdeCommunityName: newLot.communityName, name, address: newLot.streetAddress}]));
    } else {
        if(currentLots.filter((lot) => lot.lotId.toString() === lotId.toString()).length > 0) {
            currentLots.forEach((lot, index) => {
                if(lot.lotId === lotId) {
                    currentLots[index].timestamp = timestamp;
                }
            });
            Utils.setLocalStorage('adminLots', JSON.stringify(currentLots));
        } else {
            currentLots.push({...newLot, timestamp, lotId, jdeCommunityName: newLot.communityName, name, address: newLot.streetAddress})
            currentLots.sort((a, b) => {
                if(a.timestamp > b.timestamp) {
                    return -1;
                }
                return 1;
            })
            Utils.setLocalStorage('adminLots', JSON.stringify(currentLots));
        }
    }
}

async function lotIdAdd(data) {
    try {
        return await _sendRequest(data, ProfileService.addLotAssociation, true);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function lotIdDelete(data) {
    try {
        return await _sendRequest(data, ProfileService.deleteLotAssociation, true);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchLotToolTipContent(data) {
    try {
        return await _sendRequest(data, ConfigurationService.getLotToolTip);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export async function getLotById(data) {
    try {
        const res = await _sendRequest(data, LotService.get, false);
        return res;
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* getLotByIdSaga(action) {
    yield put({ type: ActionTypes.GetLotByIdRequest });
    const lotCallResult = yield call(getLotById, {
        lotId: action.payload.lotId,
    });
    if (!lotCallResult || lotCallResult.errors) {
        try {
            Event('error', 'lot-error', lotCallResult.errors.form[0].message);
        } catch (e) {
            Event('error', 'generic-lot-error');
        }

        yield put({
            type: ActionTypes.GetLotByIdError,
            payload: buildErrorObject({
                message: (lotCallResult && lotCallResult.errors && lotCallResult.errors.form[0].message) || Constants.pages.adminLotAssociation.errorMessages.cantFindLot,
            }),
        });
    } else {
        CookieService.setCookie('adminLotId', action.payload.lotId, 1);
        if (window.ga) {
            window.ga('set', 'dimension1', 'employee');
        }
        saveAdminLotsToLocalStorage(lotCallResult, action.payload.lotId, action.payload.buyerName);

        yield put({
            type: ActionTypes.GetLotByIdSuccess,
            payload: {
                lotId: action.payload.lotId,
                lot: lotCallResult,
            },
        });
    }
}

export function* lotAssociationSaga() {
    const lotAssociation = yield select(getLotAssociation);

    yield put({ type: ActionTypes.AddLotIdRequest });

    const user = yield select(getUser);

    const requestData = {
        accessToken: user.accessToken,
        lotId: lotAssociation.lotId,
    };
    const response = yield call(lotIdAdd, requestData);
    // we need to get the profile again, because we need lot details

    if (
        response === 'success' ||
        (!!response && response.status === 200 && !response.errors)
    ) {
        const profileResponse = yield call(fetchProfile, {
            accessToken: user.accessToken,
        });
        Event('login', 'lot-association');
        yield put({
            type: ActionTypes.LotIdSuccess,
            payload: {
                ...profileResponse,
                currentPage: assignCurrentPage(profileResponse),
                newLotId: lotAssociation.lotId,
            },
        });
    } else {
        yield put({
            type: ActionTypes.LotIdError,
            payload:
                response ||
                buildErrorObject({
                    message: Constants.global.errors.generic.fail,
                }),
        });
    }
}

export function* deleteLotAssociationSaga() {
    const profileAndSettings = yield select(getUserProfileAndSettings);
    const user = yield select(getUser);
    const lid = profileAndSettings.lotForDeletion;
    const requestData = {
        accessToken: user.accessToken,
        lotId: lid,
    };
    const response = yield call(lotIdDelete, requestData);
    if (response === '' && !response.errors) {
        yield put({
            type: ActionTypes.LotDeletionSuccess,
            payload: {
                deletedLot: lid,
                lotAssociations: user.lotAssociations,
            }
        });
    } else {
        yield put({
            type: ActionTypes.LotDeletionFailure,
        });
    }
}

export function* lotTooltipContentSaga() {
    yield put({ type: ActionTypes.LotTooltipRequest });
    const response = yield call(fetchLotToolTipContent);
    if (!!response && !!response.toolTip) {
        yield put({
            type: ActionTypes.LotTooltipSuccess,
            payload: response.toolTip,
        });
        if (response.loginBanner) {
            yield put({
                type: ActionTypes.LoginBannerContentSuccess,
                payload: response.loginBanner,
            });
        }
    } else {
        const errorObject = buildErrorObject(
            { message: 'No Tooltip Information available' },
            'lotToolTip'
        );
        yield put({ type: ActionTypes.LotTooltipError, payload: errorObject });
    }
}
