import ReactGA from 'react-ga';
import Constants from 'Constants';
import Utils from 'utilities/Utils';

const { fatalErrorCodes } = Constants.global.errors;

// eslint-disable-next-line import/prefer-default-export
export const handleExceptionEvent = (response) => {
    const statusCode = response.status;
    const isFatalError = fatalErrorCodes.includes(statusCode);
    const trimmedUrl =
        response.config && response.config.url
            ? Utils.trimResponseUrl(response.config.url)
            : 'Url Not Available';
    ReactGA.exception({
        description: `${statusCode} Error: ${trimmedUrl}`,
        fatal: isFatalError,
    });
};
