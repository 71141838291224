/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './AnchoredMobileNav.scss';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from 'ComponentLibrary';
import { useSelector, useDispatch } from 'react-redux';
import Utils from 'utilities/Utils';
import { useGetConfigurationForCurrentLot, useGetPhotosForCurrentLot } from 'utilities/HooksAndSelectors';
import { createHamburgerToggleAction, createHamburgerCloseAction } from 'redux/actions/Hamburger';

const getIcon = (name, size = 1.25) => (
    <Icon size={size} icon={Utils.slugify(name)} />
);

export default function AnchoredMobileNav(props) {
    const { testId } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const hamburgerState = useSelector((state) => state.hamburgerState);
    const photos = useGetPhotosForCurrentLot();
    const lotConfiguration = useGetConfigurationForCurrentLot(true);
    if (!lotConfiguration || lotConfiguration === 'requested') {
        return null;
    }
    const { features } = lotConfiguration;
    const { showProgressDashboard } = features;
    const links = [{ path: '/dashboard', name: 'Home', size: 1.2 }];
    if (showProgressDashboard) {
        links.push({
            path:
                location.pathname.indexOf('/progress') === 0
                    ? location.pathname
                    : '/progress',
            name: 'Progress',
            size: 1.4,
        });
    }
    links.push({ path: '/checklist', name: 'Checklist', size: 1.7 });
    if (photos && photos.length > 1) {
        links.push({ path: '/photos', name: 'Photos', size: 1.2 });
    }

    let { isOpen } = hamburgerState;
    //urls that are hidden under more
    const extraURLs = ['/documents', '/contacts', '/deposits', '/profile', '/warranty'];

    if (!isOpen) {
        extraURLs.forEach((url) => {
            if (location.pathname.indexOf(url) === 0) {
                isOpen = true;
            }
        });
    }
    links.push({ size:1.4, action: createHamburgerToggleAction, name: 'More', extraClasses: isOpen && 'anchored-nav-link--active' });

    return (
        <nav className="anchored-mobile-nav" data-testid={testId}>
            <ul className={`anchored-mobile-nav__list ${links.length < 5 && 'anchored-mobile-nav__list--condensed'}`}>
                {links &&
                    links.map((link, index) => {
                        const { path, name, size, action, extraClasses } = link;
                        const notMore = name !== 'More';
                        const linkClasses = `anchored-nav-link ${extraClasses && extraClasses} ${isOpen && notMore && 'anchored-nav-link--more-open'} ${!notMore && isOpen && 'anchored-nav-link--active'}`;
                        const testIdForLink = `${testId}__link-${index + 1}`;

                        return (
                            <li
                                className="anchored-mobile-nav__list-item"
                                data-testid={testIdForLink}
                                key={`anchored-nav-link-${index}`}
                            >
                                {action && (
                                    <button
                                        className={linkClasses}
                                        type="button"
                                        data-testid={`anchored-nav-button-${index}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (link.action) {
                                                dispatch(link.action());
                                            }
                                            if (name !== 'More') {
                                                dispatch(createHamburgerCloseAction());
                                            }
                                            return false;
                                        }}
                                    >
                                        <span className="anchored-nav-link__icon">
                                            {getIcon(name, size)}
                                        </span>
                                        <span className="anchored-nav-link__text">
                                            {name}
                                        </span>
                                    </button>
                                )}
                                {!action && (
                                    <NavLink
                                        className={linkClasses}
                                        data-testid={`anchored-nav-link-${index}`}
                                        onClick={(e) => {
                                            window.scroll({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth',
                                            });
                                            if (name !== 'More') {
                                                dispatch(createHamburgerCloseAction());
                                            }
                                            return false;
                                        }}
                                        to={path}
                                        activeClassName={`anchored-nav-link--active ${isOpen && notMore && 'anchored-nav-link--active--more-open'}`}
                                    >
                                        <span className="anchored-nav-link__icon">
                                            {getIcon(name, size)}
                                        </span>
                                        <span className="anchored-nav-link__text">
                                            {name}
                                        </span>
                                    </NavLink>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </nav>
    );
}

AnchoredMobileNav.defaultProps = {
    testId: null,
};

AnchoredMobileNav.propTypes = {
    testId: PropTypes.string,
};
