import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, BackButton } from 'ComponentLibrary';
import './CustomSelect.scss';
import Utils from 'utilities/Utils';

const SPACEBAR_KEY_CODE = [0, 32];
const ENTER_KEY_CODE = 13;
const DOWN_ARROW_KEY_CODE = 40;
const UP_ARROW_KEY_CODE = 38;
const ESCAPE_KEY_CODE = 27;

function fixStatus(str) {
    if (str === 'NotStarted') {
        return 'Not Started';
    }
    return str;
}

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

function focusNextListItem(direction, baseId, length) {
    const activeElementId = document.activeElement.id;
    if (activeElementId === `${baseId}-dropdown__selected`) {
        document.querySelector(`#${baseId}-option-0`).focus();
    } else {
        const pieces = activeElementId.split('-');
        const currentActiveElementIndex = parseInt(
            pieces[pieces.length - 1],
            10
        );

        if (direction === DOWN_ARROW_KEY_CODE) {
            const currentActiveElementIsNotLastItem =
                currentActiveElementIndex < length - 1;
            if (currentActiveElementIsNotLastItem) {
                const nextListItemId = `${baseId}-option-${
                    currentActiveElementIndex + 1
                }`;
                document.querySelector(`#${nextListItemId}`).focus();
            }
        } else if (direction === UP_ARROW_KEY_CODE) {
            const currentActiveElementIsNotFirstItem =
                currentActiveElementIndex > 0;
            if (currentActiveElementIsNotFirstItem) {
                const nextListItemId = `${baseId}-option-${
                    currentActiveElementIndex - 1
                }`;
                document.querySelector(`#${nextListItemId}`).focus();
            }
        }
    }
}

const buildOptions = (tab, index) => {
    const { title, baseId, onClick, status, closeList, length } = tab;
    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
            data-value={index}
            data-testid="custom-select-option"
            key={`${index}-select-option`}
            className={`dropdown__list-item dropdown-list-item dropdown__list-item--${Utils.slugify(status)} dropdown-list-item--${Utils.slugify(status)}}`}
            tabIndex="0"
            id={`${baseId}-option-${index}`}
            onClick={(e) => onClick(index)}
            onBlur={(e) => {
                if (index === length - 1) {
                    closeList();
                }
            }}
            onKeyDown={(e) => {
                switch (e.keyCode) {
                    case ENTER_KEY_CODE:
                        onClick(index);
                    return;
                    case DOWN_ARROW_KEY_CODE:
                        focusNextListItem(DOWN_ARROW_KEY_CODE, baseId, length);
                    return;

                    case UP_ARROW_KEY_CODE:
                        focusNextListItem(UP_ARROW_KEY_CODE, baseId, length);
                    return;

                    case ESCAPE_KEY_CODE:
                        closeList();
                    return;
                default:
                }
            }}
        >
            <span className="dropdown-list-item__text">{title}</span>
            <span className={`dropdown-list-item__status dropdown-list-item__status--${Utils.slugify(status)}`}>{fixStatus(status)}</span>
        </li>
    );
};

const CustomSelect = (props) => {
    const { options, onChange, title, id, bg, initialValue, status } = props;
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState(initialValue);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setOpen(false);
    });

    options.forEach((item) => {
        item.baseId = id;
        item.onClick = (val) => {
            setValue(val);
            setOpen(false);
            onChange(val);
        };
        item.closeList = () => {
            setOpen(false);
        };
        item.length = options.length;

        return item;
    });

    return (
        <>
            <BackButton />
            <ul className="dropdown" ref={wrapperRef}>
                <li>
                    <div
                        id={`${id}-dropdown__label`}
                        className="visuallyhidden dropdown__label"
                    >
                        {title}
                    </div>
                </li>

                <li>
                    <div
                        role="button"
                        aria-labelledby={`${id}-dropdown__label`}
                        id={`${id}-dropdown__selected`}
                        tabIndex="0"
                        className="dropdown__value"
                        onClick={(e) => {
                            setOpen(!open);
                        }}
                        onKeyDown={(e) => {
                            const openDropDown =
                                SPACEBAR_KEY_CODE.includes(e.keyCode) ||
                                e.keyCode === ENTER_KEY_CODE;
                            if (openDropDown) {
                                setOpen(true);
                            }
                            if (e.keyCode === ESCAPE_KEY_CODE) {
                                setOpen(false);
                            }

                            if (e.keyCode === DOWN_ARROW_KEY_CODE) {
                                focusNextListItem(DOWN_ARROW_KEY_CODE);
                            }

                            if (e.keyCode === UP_ARROW_KEY_CODE) {
                                focusNextListItem(UP_ARROW_KEY_CODE);
                            }
                        }}
                    >
                        <Text
                            type="block"
                            variant="large"
                            addClass="progress-page-title"
                        >
                            {options[initialValue] && options[initialValue].title}
                        </Text>
                        <div className={`dropdown__icon-wrapper dropdown__icon-wrapper--${open ? 'open' : ''}`}>
                            <Icon color="indigo" icon="arrow" />
                        </div>
                        <div className={`dropdown__status-label dropdown__status-label--${Utils.slugify(status)}`}>{Utils.translateStatus(status)}</div>
                    </div>
                </li>
                <li
                    aria-expanded={!!open}
                    role="list"
                    className="dropdown__list-container"
                >
                    <ul
                        className={`bg-${bg} dropdown__list ${
                            open ? 'dropdown__list--open' : ''
                        }`}
                    >
                        {options.map(buildOptions)}
                    </ul>
                </li>
            </ul>
        </>
    );
};

CustomSelect.defaultProps = {
    options: [],
    title: '',
    testId: null,
    bg: null,
    initialValue: 0,
};
CustomSelect.propTypes = {
    options: PropTypes.array,
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    testId: PropTypes.string,
    bg: PropTypes.string,
    initialValue: PropTypes.number,
};

export default CustomSelect;
