import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './Checklist.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';

import { useLocation, useHistory, useParams } from 'react-router-dom';
import {
    createCloseChecklistModalAction,
    createOpenChecklistModalAction,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import WithCondition from 'hoc/WithCondition';
import {
    useHaveUser,
    useOutsideAlerter,
    useHasTasks,
    useIsAdmin,
} from 'utilities/HooksAndSelectors';
import Constants from 'Constants';
import {
    createCompleteTasksAction,
    createOpenAddTaskFormAction,
    createCloseAddTaskFormAction,
    createUpdateNewTaskAction,
    createCancelTaskAction,
    createAddNewTaskAction,
    createSubmitEditPersonalTaskAction,
    createAddTaskFormErrorAction,
    createOpenPersonalTasksAction,
    createClosePersonalTasksAction,
} from 'redux/actions/Tasks/TasksActions';
import Utils from 'utilities/Utils';
import { Title, BackButton } from 'ComponentLibrary';
import { Accordion } from 'react-bootstrap';
import PersonalTasksAccordionCard from './PersonalTasksAccordionCard';
import MITasksAccordionCard from './MITasksAccordionCard';

const getTaskIdFromUrl = (id, path) => {
    if (id) {
        return id;
    }
    if (path.startsWith('/dashboard/checklist/')) {
        return path.split('/dashboard/checklist/')[1];
    }

    return null;
}

export default function Checklist(props) {
    const hasUser = useHaveUser();

    const { mode } = props;
    const Tag = mode === 'modal' ? 'dialog' : 'section';
    const userState = useSelector((state) => state.userState);
    const taskState = useSelector((state) => state.taskState);
    const acuityModalState = useSelector((state) => state.acuityModalState);

    const { profileAndSettings, userDetails } = userState;
    const {
        tasks,
        personalTasks,
        showAddTaskForm,
        showEditTaskForm,
        isPersonalTasksOpen,
        taskFormData,
        taskFormErrors,
    } = taskState;
    const { checklistModalOpen, focusedTask } = profileAndSettings;
    const hasTasks = useHasTasks();

    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const params = useParams();

    const wrapperRef = useRef(null);
    const isAdmin = useIsAdmin();

    const [isMITasksOpen, setIsMITasksOpen] = useState(true);

    const closeDropdown = () => {
        history.push(location.pathname.indexOf('/checklist') > 0 ? location.pathname.split('/checklist')[0] : location.pathname);
        const action = createCloseChecklistModalAction();
        dispatch(action);
        dispatch(createCompleteTasksAction());
        dispatch(createClosePersonalTasksAction());
        setIsMITasksOpen(true);
        return false;
    };

    const keyPress = (e) => {
        if (e.keyCode === 27 && checklistModalOpen) {
            if (mode === 'modal') {
                closeDropdown();
            }
        }
    };

    const checkForPersonalFocusByParam = (key, taskArray) => {
        let focusedPersonalTask = null;
        if (key && Array.isArray(taskArray)) {
            focusedPersonalTask = Utils.sortTasks(
                taskArray.filter(
                    (task) => task.taskId.toString() === key.toString()
                )
            );
        }
        return focusedPersonalTask && focusedPersonalTask.length > 0;
    };

    const toggleMITasks = () => {
        setIsMITasksOpen(!isMITasksOpen);
    };

    const togglePersonalTasks = () => {
        isPersonalTasksOpen ? dispatch(createClosePersonalTasksAction()) : dispatch(createOpenPersonalTasksAction());
    };

    const toggleAddTaskForm = () => {
        if (!isPersonalTasksOpen && !showAddTaskForm) {
            dispatch(createOpenPersonalTasksAction());
        }
        const action = !showAddTaskForm
            ? createOpenAddTaskFormAction()
            : createCloseAddTaskFormAction();
        dispatch(action);
    };

    const handleChangeNewTask = (e) => {
        let taskValue = e.target.value;
        if (e.target.name === 'description') {
            taskValue = taskValue.substr(0, 400);
        }
        const update = {
            type: e.target.name,
            value: taskValue,
        };
        dispatch(createUpdateNewTaskAction(update));
    };

    const handleChangeDueDate = (date) => {
        let update = null;
        try {
            const newDate = new Date(date);
            const dateHalf = newDate.toISOString().split('T')[0];
            const datePieces = dateHalf.split('-');
            const formattedDate = `${datePieces[1]}-${datePieces[2]}-${datePieces[0]}`;

            update = {
                type: 'dueDate',
                value: formattedDate,
            };
        } catch (e) {
            update = {
                type: 'dueDate',
                value: null,
            };
        }
        dispatch(createUpdateNewTaskAction(update));
    };

    const handleCancelTaskForm = () => {
        dispatch(createCancelTaskAction());
    };

    const validateTaskForm = () => taskFormData && taskFormData.title;

    const handleSubmitTaskForm = () => {
        const isFormValid = validateTaskForm();
        if (isFormValid) {
            return showEditTaskForm
                ? dispatch(createSubmitEditPersonalTaskAction())
                : dispatch(createAddNewTaskAction());
        }

        return dispatch(createAddTaskFormErrorAction(['title']));
    };

    useOutsideAlerter(wrapperRef, () => {
        if (checklistModalOpen) {
            closeDropdown();
        }
    });

    useEffect(() => {
        return () => {
            if (mode === 'page') {
                dispatch(createCompleteTasksAction());
                dispatch(createClosePersonalTasksAction());
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', keyPress);
        return () => {
            window.removeEventListener('keydown', keyPress);
        };
    });

    useEffect(() => {
        if (personalTasks && Array.isArray(personalTasks)) {
            if (checkForPersonalFocusByParam(getTaskIdFromUrl(params.taskId, location.pathname), personalTasks) || checkForPersonalFocusByParam(focusedTask, personalTasks)) {
                dispatch(createOpenPersonalTasksAction());
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, focusedTask, personalTasks, params]);

    useEffect(() => {
        if (
            hasTasks &&
            location.pathname.startsWith('/dashboard/checklist') &&
            !location.hash &&
            !acuityModalState.isOpen &&
            mode !== 'page' &&
            window.innerWidth >= 764 &&
            window.matchMedia('(min-width:764px)').matches &&
            !checklistModalOpen
        ) {
            dispatch(createOpenChecklistModalAction());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, location.hash, hasTasks, mode, checklistModalOpen]);

    useEffect(() => {
        if (acuityModalState.isOpen) {
            dispatch(createCloseChecklistModalAction());
        }
    }, [acuityModalState.isOpen]);


    if (mode !== 'page' && window.innerWidth < 768 && getTaskIdFromUrl(params.taskId, location.pathname)) {
        const idval = getTaskIdFromUrl(params.taskId, location.pathname);
        return <Redirect to={`/checklist/${idval}`} />
    }

    if (!hasUser || !hasTasks || tasks === 'requested') return null;
    const { accountProfile } = userDetails;
    const { firstName } = accountProfile;

    const upcomingTasks = Utils.sortTasks(
        tasks.filter((task) => !task.isSystemComplete && !task.isUserComplete)
    );
    const completeTasks = Utils.sortTasks(
        tasks.filter((task) => task.isSystemComplete || task.isUserComplete)
    );

    const upcomingPersonalTasks = Utils.sortTasks(
        personalTasks.filter(
            (task) => !task.isSystemComplete && !task.isUserComplete
        )
    );
    const completePersonalTasks = Utils.sortTasks(
        personalTasks.filter(
            (task) => task.isSystemComplete || task.isUserComplete
        )
    );

    const propsForDialog = {};
    if (checklistModalOpen && mode === 'dialog') {
        propsForDialog.open = true;
    }

    if (mode === 'page' && window.innerWidth >= 764) {
        let url = `/dashboard/checklist${getTaskIdFromUrl(params.taskId, location.pathname) ? `/${getTaskIdFromUrl(params.taskId, location.pathname)}` : ''}`;
        return <Redirect to={url} />;
    }

    if (mode === 'modal' && !checklistModalOpen) {
        return null;
    }

    return (
        <WithCondition
            condition={mode === 'modal'}
            wrapper={(children) => (
                <CSSTransition
                    onEnter={(el) => {
                        if (checklistModalOpen && mode === 'modal' && window.innerWidth >= 764 && window.matchMedia('(min-width:764px)').matches) {
                            try {
                                el.showModal();
                            } catch (e) {
                                el.setAttribute('open', 'true');
                            }
                        }
                    }}
                    onExited={(el) => {
                        try {
                            el.close();
                        } catch (e) {
                            // simply because browsers don't all support this
                        }
                        el.removeAttribute('open');
                    }}
                    in={checklistModalOpen && mode === 'modal'}
                    appear={checklistModalOpen}
                    exit={!checklistModalOpen}
                    timeout={200}
                    classNames="fade"
                >
                    {children}
                </CSSTransition>
            )}
        >
            <Tag
                className={`checklist checklist--${mode}`}
                data-testid="checklist"
                ref={wrapperRef}
                open={mode === 'page'}
                {...propsForDialog}
            >
                {mode !== 'page' && (
                    <button
                        onClick={(e) => {
                            closeDropdown();
                        }}
                        type="button"
                        className="modal-close"
                        data-testid="close-button"
                    >
                        <span className="modal-close__inner">Close</span>
                    </button>
                )}
                <div className="checklist__scroll-content">
                    {mode === 'page' && <Title title={Constants.pages.checklist.pageTitle} />}
                    <div className="checklist-modal-header">
                        <h2 className={`checklist__title checklist__title--${mode}`}>
                            {mode === 'page' && <BackButton />}
                            {!isAdmin && firstName}{!isAdmin && '’'}
                            {`${
                                isAdmin || firstName.split().pop().toLowerCase() === 's' ? '' : 's'
                            }`}{' '}
                            Checklist
                        </h2>
                        <p className={`checklist__body checklist__body--${mode}`}>
                            {Constants.checklistModal.introBody1}
                        </p>
                        <p className={`checklist__body checklist__body--${mode} checklist__body--last`}>
                            {Constants.checklistModal.introBody2}
                        </p>
                    </div>
                    {!isAdmin && (
                        <Accordion
                            className={`personal-checklist-accordion ${
                                isPersonalTasksOpen ? 'open' : ''
                            }`}
                            activeKey={isPersonalTasksOpen ? '0' : null}
                        >
                            <PersonalTasksAccordionCard
                                upcomingTasks={upcomingPersonalTasks}
                                completeTasks={completePersonalTasks}
                                mode={mode}
                                handleToggle={togglePersonalTasks}
                                handleToggleAddTaskForm={toggleAddTaskForm}
                                isPersonalTasksOpen={isPersonalTasksOpen}
                                showAddTaskForm={showAddTaskForm}
                                showEditTaskForm={showEditTaskForm}
                                handleChangeNewTask={handleChangeNewTask}
                                taskFormData={taskFormData}
                                handleCancelTaskForm={handleCancelTaskForm}
                                handleSubmitTaskForm={handleSubmitTaskForm}
                                handleChangeDueDate={handleChangeDueDate}
                                taskFormErrors={taskFormErrors}
                            />
                        </Accordion>
                    )}

                    <Accordion
                        className={`mi-checklist-accordion ${
                            isMITasksOpen ? 'open' : ''
                        }`}
                        defaultActiveKey="0"
                    >
                        <MITasksAccordionCard
                            upcomingTasks={upcomingTasks}
                            completeTasks={completeTasks}
                            mode={mode}
                            handleToggle={toggleMITasks}
                            isOpen={isMITasksOpen}
                        />
                    </Accordion>
                </div>
            </Tag>
        </WithCondition>
    );
}

Checklist.defaultProps = {
    mode: 'modal',
};

Checklist.propTypes = {
    mode: PropTypes.string,
};
