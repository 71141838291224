import ActionTypes from 'redux/actions/ActionTypes';

export function createOpenAcuityModalAction() {
    return {
        type: ActionTypes.OpenAcuityModal,
    };
}

export function createCloseAcuityModalAction() {
    return {
        type: ActionTypes.CloseAcuityModal,
    };
}
