import React from 'react';
import PropTypes from 'prop-types';
import './CloseButton.scss';

const CloseButton = (props) => {
    const { onClick, styling, testId } = props;

    return (
        <button data-testid={testId} style={styling} type="button" className="close-button" onClick={onClick}>
            <div className="close-button__inner">Close</div>
        </button>
    );
};

CloseButton.defaultProps = {
    styling: null,
    testId: null,
};

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    styling: PropTypes.object,
    testId: PropTypes.string,
};

export default CloseButton;
