import ActionTypes from 'redux/actions/ActionTypes';
import Utils from 'utilities/Utils';

export function createGetNotificationsAction() {
    return {
        type: ActionTypes.GetNotifications,
    };
}

export function createHavePushSubscriptionAction() {
    return {
        type: ActionTypes.HasPushNotification,
    };
}

export function createOpenNotificationsDropdownAction() {
    return {
        type: ActionTypes.OpenNotificationsDropdown,
    };
}

export function createCloseNotificationsDropdownAction() {
    return {
        type: ActionTypes.CloseNotificationsDropdown,
    };
}

export function createViewNotificationAction(notification) {
    return {
        type: ActionTypes.ViewNotification,
        payload: notification,
    };
}

export function createSetOpenNotificationAction(notificationId) {
    return {
        type: ActionTypes.SetOpenNotification,
        payload: notificationId,
    };
}

export function createGetNotificationsSuccessOnRetryAction(notificationsData) {
    const sortedNotifications = Utils.sortNotifications(notificationsData);
    const unreadNotifications = Utils.sortUnreadNotifications(
        sortedNotifications
    );
    return {
        type: ActionTypes.GetNotificationsSuccess,
        payload: {
            notifications: sortedNotifications,
            unreadNotifications,
        },
    };
}
