import React from 'react';
import Utils from 'utilities/Utils';
import './SortableTable.scss';

const SortableTable = ({ sortBy, sortOrder, cellRenderers = [], headers, keys, rows = [], sortActions = null, small = false, left = false, large = true, describedBy = null }) => {
    if (
        !headers ||
        !keys ||
        !Array.isArray(headers) ||
        !Array.isArray(keys) ||
        headers.length !== keys.length
    ) {
        console.error('bad Sortable Table');
        return <React.Fragment />;
    }

    return (
        <table
            width="100%"
            aria-describedby={describedBy}
            className="sortable-table sortable-table--with-alternate-mobile-view"
        >
            <thead className="sortable-table__header">
                <tr className="sortable-table__row sortable-table__row--header">
                    {headers.map((header, index) => {
                        let headerProps = {};
                        if(sortBy === keys[index]) {
                            headerProps['aria-sort'] = sortOrder && sortOrder.indexOf('asc') >= 0 ? 'ascending' : 'descending';
                        } else {
                            headerProps = {};
                        }

                        return (
                            <th
                                className={`sortable-table__cell  sortable-table__cell--header ${small && 'sortable-table__cell--header-small'} ${left && 'sortable-table__cell--left'} ${small && 'sortable-table__cell--no-pad'}`}
                                data-testid="sortable-table-cell-header"
                                {...headerProps}
                            >
                                {sortActions && sortActions[index] && (
                                    <button
                                        className={`
                                            sortable-table__sort-button
                                            ${sortBy === keys[index] && 'sortable-table__sort-button--active'}
                                            sortable-table__sort-button--${sortOrder}
                                        `}
                                        data-testid={`sort-button-${Utils.slugify(header)}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            sortActions[index]();
                                            return false;
                                        }}
                                        type="button">{header}</button>
                                )}
                                {(!sortActions || !sortActions[index]) && (
                                    header
                                )}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map(
                    (
                        row,
                        index
                    ) => (
                    <tr>
                            {keys.map((key, cellIndex) => {
                                const Renderer = cellRenderers[cellIndex];
                                return (
                                    <td
                                        className={`sortable-table__cell ${large && 'sortable-table__cell--large'} ${left && 'sortable-table__cell--left'} ${
                                            index % 2 === 1 &&
                                            'sortable-table__cell--in-even-row'
                                        }`}
                                        data-testid="sortable-table-cell-row"
                                    >
                                        {Renderer && (
                                            <Renderer {...row}>{row[key]}</Renderer>
                                        )}
                                        {!Renderer && (
                                            row[key]
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    )
                )}
            </tbody>
        </table>
    );
};

export default SortableTable;
