import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    isOpen: false,
    selectForDownloadMode: false,
    photosForDownload: [],
    filterModalOpen: false,
    currentImageIndex: null,
    currentPageNumber: null,
    caption: '',
    tagsForFiltering: null,
    downloadUrl: null,
    downloadState: null,
};

const PhotoModalReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.ClearPhotoDownloadData:
            return {
                ...state,
                downloadUrl: null,
                downloadState: null,
            };
        case ActionTypes.GetDownloadPhotosUrl:
            return {
                ...state,
                downloadState: 'requested',
            }
        case ActionTypes.GetDownloadPhotosUrlError:
        case ActionTypes.GetDownloadPhotosUrlSuccess:
            return {
                ...state,
                downloadUrl: action.payload,
                downloadState: 'have-link',
            }

        case ActionTypes.ExitSelectForDownloadeMode:
            return {
                ...state,
                selectForDownloadMode: false,
            }
        case ActionTypes.ToggleSelectForDownloadeMode:
            const isSelectMode = !state.selectForDownloadMode;
            return {
                ...state,
                selectForDownloadMode: isSelectMode,
                photosForDownload: isSelectMode ? [] : state.photosForDownload,
            };
        case ActionTypes.MarkAllPhotosForDownload:
            return {
                ...state,
                photosForDownload: action.payload,
            }
        case ActionTypes.TogglePhotoInDownloadListAction:
            let newIds = [ ...state.photosForDownload ];
            if (newIds.includes(action.payload)) {
                const indexOfImage = newIds.findIndex((el) => {
                    return action.payload === el;
                });
                const newNew = [];
                newIds.forEach((el, index) => {
                    if(index !== indexOfImage) {
                        newNew.push(el);
                    }
                });
                newIds = newNew;
            } else {
                newIds.push(action.payload);
            }
            return {
                ...state,
                photosForDownload: [...newIds],
            }
        case ActionTypes.CloseFilterPhotosModal:
            return {
                ...state,
                filterModalOpen: false,
                tagsForFiltering: action.payload || state.tagsForFiltering,
            };
        case ActionTypes.LaunchFilterPhotosModal:
            return {
                ...state,
                filterModalOpen: true,
            };
    case ActionTypes.OpenPhotoModal:
        return {
            ...state,
            isOpen: true,
            currentImageIndex: action.payload.currentImageIndex,
            currentPageNumber: action.payload.currentPageNumber,
            caption: action.payload.caption,

        };

    case ActionTypes.ClosePhotoModal:
        return {
            ...state,
            isOpen: false,
            currentImageIndex: null,
            currentPageNumber: null,
            caption: '',
        };

    case ActionTypes.SetPhotoModalPageNumber:
            return {
                ...state,
                currentPageNumber: action.payload,
            };

    case ActionTypes.SetPhotoModalCaption:
            return {
                ...state,
                caption: action.payload,
            };

    default:
        return state;
    }
};

export default PhotoModalReducer;

