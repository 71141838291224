import ApiService from './ApiService';

const DesignSelectionsService = {
    getSelections: (data, service = ApiService()) => service
        .get(`/DesignCenter/Lots/${data.lotId}/approvedselections`, true)
        .then((response) => response)
        .catch((error) => error.response),
    getItemData: (data, service = ApiService()) => service
        .get(`/DesignCenter/Lots/${data.lotId}/approvedselections/${data.optionId}`, true)
        .then((response) => response)
        .catch((error) => error.response),
};

export default DesignSelectionsService;
