import React from 'react';
import PropTypes from 'prop-types';
import './RepCard.scss';
import { Icon, UserBadge } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { createOpenBioModalAction } from 'redux/actions/ContactBioModal/ContactBioModalActions';
import ContentLoader from 'react-content-loader';

export default function RepCard(props) {
    const {
        icon,
        repImageUrl,
        repName,
        repTitle,
        repDescription,
        repPhone,
        repEmail,
        mobileOnly,
        repImageAltText,
        classes,
        as,
        repContactLink,
        desktopOnly,
        skeleton,
        isCell,
        outsideOfContainer = false,
    } = props;

    const dispatch = useDispatch();

    const Tag = as;

    if ((!repName || repName === 'undefined undefined') && !skeleton) {
        return null;
    }

    if (skeleton) {
        return (
            <Tag
                className={`mi-rep-card-container ${
                    mobileOnly && 'mi-rep-card-container--mobile-only'
                } ${desktopOnly && 'mi-rep-card-container--desktop-only'} ${outsideOfContainer && 'mi-rep-card-container--margin'} ${classes.join(' ')}`}
                data-testid="mi-rep-card-container"
            >
                <ContentLoader>
                    <rect x="0" y="0" rx="3" ry="3" width="250" height="16" />
                    <rect x="0" y="26" rx="3" ry="3" width="220" height="16" />
                    <rect x="0" y="52" rx="3" ry="3" width="170" height="16" />
                    <rect x="0" y="78" rx="3" ry="3" width="170" height="16" />
                </ContentLoader>
            </Tag>
        )
    }



    return (
        <CSSTransition in appear timeout={500} classNames="fade-and-rise">
            <Tag
                className={`mi-rep-card-container ${
                    mobileOnly && 'mi-rep-card-container--mobile-only'
                } ${desktopOnly && 'mi-rep-card-container--desktop-only'} ${outsideOfContainer && 'mi-rep-card-container--margin'} ${classes.join(' ')}`}
                data-testid="mi-rep-card-container"
            >
                <div className="rep-description">
                    <div className="rep-card-heading">
                        {repImageUrl && repImageUrl !== '' && (
                            <UserBadge
                                type="large"
                                userImageUrl={repImageUrl}
                                altText={repImageAltText}
                                borderStyle="none"
                                testId="rep-card-user-badge"
                                lazy
                            />
                        )}
                        <div>
                            <h3 className="mi-rep-card__name">{repName}</h3>

                            <p className="mi-rep-card__title">{repTitle}</p>
                            {icon && (
                                <Icon
                                    className="rep-heading-icon"
                                    testId="rep-heading-icon"
                                    size={1}
                                    icon="star"
                                    color="steel-blue"
                                    border="steel-blue"
                                />
                            )}
                        </div>
                    </div>

                    <p className="mi-rep-card__summary">{repDescription}</p>
                </div>
                <div className="rep-contact-info">
                    {repPhone && (
                        <div className="rep-contact-type-container">
                            <Icon size={1} icon={isCell ? 'cellphone' : 'phone'} color="gray" />
                            <a href={`tel:+1${repPhone}`} className="rep-card-link rep-card-link--phone">
                                {Utils.formatPhone(repPhone)}
                            </a>
                        </div>
                    )}
                    {repEmail && (
                        <div className="rep-contact-type-container">
                            <Icon size={1} icon="mail" color="gray" />
                            <a
                                className="rep-card-link rep-card-link--email"
                                href={`mailto:${repEmail}?subject=Feedback&body=Message`}
                            >
                                {repEmail}
                            </a>
                        </div>
                    )}
                    {repContactLink && (
                        <div className="rep-contact-type-container">

                            <button
                                type="button"
                                data-testid="rep-contact-modal-launcher"
                                className="rep-contact-modal-launcher"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(createOpenBioModalAction(props));
                                    return false;
                                }}
                            >
                                <Icon size={1} icon="info" color="gray" />
                                About {repName}
                            </button>
                        </div>
                    )}
                </div>
            </Tag>
        </CSSTransition>
    );
}

RepCard.defaultProps = {
    as: 'div',
    repImageUrl: null,
    skeleton: false,
    repName: null,
    repTitle: null,
    repDescription: null,
    repPhone: null,
    repEmail: null,
    repContactLink: null,
    icon: false,
    mobileOnly: false,
    desktopOnly: false,
    classes: [],
};

RepCard.propTypes = {
    skeleton: PropTypes.bool,
    as: PropTypes.string,
    icon: PropTypes.bool,
    repImageUrl: PropTypes.string,
    repName: PropTypes.string,
    repTitle: PropTypes.string,
    repDescription: PropTypes.string,
    repPhone: PropTypes.string,
    repEmail: PropTypes.string,
    repContactLink: PropTypes.string,
    mobileOnly: PropTypes.bool,
    desktopOnly: PropTypes.bool,
    classes: PropTypes.array,
};
