import ActionTypes from 'redux/actions/ActionTypes';
import store from 'redux/configureStore';

export function createOpenUserProfileDropdownAction() {
    return {
        type: ActionTypes.OpenUserProfileDropdown,

    }
};
export function createCloseUserProfileDropdownAction() {
    return {
        type: ActionTypes.CloseUserProfileDropdown,
    }
};

export function createChangeActiveLotAction(data){
    return {
        payload: data,
        type: ActionTypes.ChangeActiveLot,
    }
};

export function createLogOutAction(token, isAdmin) {
    return {
        type: ActionTypes.LogOut,
        payload: {
            token,
            isAdmin,
        },
    }
};

export function createEnterDeleteModeAction() {
    return {
        type: ActionTypes.EnterRemoveLotMode,
    }
};

export function createExitDeleteModeAction() {
    return {
        type: ActionTypes.ExitRemoveLotMode,
    }
};

export function createNotificationPreferencesSetEvent(data) {
    return {
        type: 'NOTIFICATION_PREFERENCES',
        payload: data,
    }
};

export function createSavePushSubscriptionToStateEvent(notificationSubscriptionId, subscriptionUrl) {
    return {
        type: ActionTypes.SavePushSubscriptionToState,
        payload: {
            notificationSubscriptionId,
            subscriptionUrl,
        },
    }
};

export function createOpenChecklistModalAction(taskId = null) {
    return {
        type: ActionTypes.TriggerChecklistModal,
        payload: taskId,
    }
};

export function createCloseChecklistModalAction() {
    return {
        type: ActionTypes.CloseChecklistModal,
    }
};

export function createAppIsInstallableEvent() {
    return {
        type: ActionTypes.AppIsInstallable,
        payload: null,
    }
};

export function createAddALotAction() {
    return {
        type: ActionTypes.GoToAddLot,
    }
};

export function createOpenChangeLotPageAction(haveCancel) {
    return {
        type: ActionTypes.OpenFindLotPage,
        payload: haveCancel,
    }
};

export function createCloseFindLotPageAction(lotId) {
    return {
        type: ActionTypes.CloseFindLotPage,
        payload: lotId,
    }
};

export function createDeleteLotAssociationAction(lotId) {
    return {
        type: ActionTypes.DeleteLotAssociation,
        payload: lotId,
    }
};

export function createConfirmLotDeletionAction() {
    const state = store.getState();
    const forDelete = state.userState.profileAndSettings.lotForDeletion;
    const { lotAssociations } = state.userState.userDetails;
    return {
        type: ActionTypes.ConfirmLotDeletion,
        payload: {
            deletedLot: forDelete,
            lotAssociations:
                lotAssociations &&
                lotAssociations.filter(({ lotId }) => lotId !== forDelete),
        },
    };
};

export function createClearLotsForTestingAction(newLotsValue) {
    return {
        type: ActionTypes.ClearLotsForTesting,
        payload: newLotsValue,
    }
};
