import { CookieService } from 'services';
import Constants from 'Constants';

const assignCurrentPage = function (userData) {
    if (userData.lotAssociations.length > 0) {
        return 'dashboard';
    }
    if (
        userData &&
        userData.roles &&
        userData.roles.filter(
            (role) => role.description === Constants.global.adminRole
        ) &&
        userData.roles.filter(
            (role) => role.description === Constants.global.adminRole
        ).length > 0
    ) {
        if (CookieService.getCookie('adminLotId')) {
            return 'dashboard';
        }

        return 'findLot';
    }
    return 'lotAssociation';
};

const buildErrorObject = (error, key) => ({
    errors: {
        [key || 'form']: [
            {
                message: error && error.message,
                title: error && error.title,
            },
        ],
    },
});

export { assignCurrentPage, buildErrorObject };
