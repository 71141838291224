import React from 'react';
import ContentLoader from 'react-content-loader';

const DashboardNotificationsButtonSkeleton = () => {
    return (
        <ContentLoader
            data-testid="dashboard-notifications-button-skeleton"
            speed={2}
            viewBox="0 0 345 59"
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
        </ContentLoader>
    );
};


export default DashboardNotificationsButtonSkeleton;
