import React, { useRef, useState } from 'react';
import { Input, Button, ButtonStyles, Icon, Error } from 'ComponentLibrary';
import DatePicker from 'react-datepicker';
import Constants from 'Constants';
import 'react-datepicker/dist/react-datepicker.css';
import './PersonalTaskForm.scss';
import { isSafari, isMobileSafari } from 'react-device-detect';

const PersonalTaskForm = (props) => {
    const {
        handleChange,
        taskData,
        handleCancelTaskForm,
        handleSubmitTaskForm,
        handleChangeDueDate,
        taskFormErrors,
    } = props;

    const [dateIsInFuture, setDataIsInFuture] = useState(true);

    const checkDataInFuture = (e) => {
        const d = new Date(e.currentTarget.value);
        const now = new Date();

        if (now.getTime() - 86400000 > d.getTime()) {
            setDataIsInFuture(false);
        } else {
            setDataIsInFuture(true);
        }
        handleChangeDueDate(e.currentTarget.value);
    };


    const taskDescription = taskData ? taskData.description : '';
    let dateToDisplay = taskData ? taskData.dueDate : '';
    const taskTitle = taskData ? taskData.title : '';

    const usePicker = isSafari && !isMobileSafari;
    const today = new Date();
    let todayString = `${today.getFullYear()}-${today.getMonth() < 9 ? '0' : ''}${today.getMonth() + 1}-${today.getDate() < 10 ? '0' : ''}${today.getDate()}`;//'2021-04-23'
    const minDate = todayString;
    const personalTaskFormText = Constants.checklistModal.personalTaskForm;
    const taskFormRef = useRef(null);

    if (dateToDisplay && dateToDisplay.indexOf('T') >= 0) {
        const newDate = new Date(dateToDisplay);
        const dateHalf = newDate.toISOString().split('T')[0];
        const datePieces = dateHalf.split('-');
        dateToDisplay = `${datePieces[1]}/${datePieces[2]}/${datePieces[0]}`;
    }
    if (dateToDisplay && dateToDisplay.indexOf('-') > 0) {
        todayString = `${dateToDisplay.split('-')[2]}-${dateToDisplay.split('-')[0]}-${dateToDisplay.split('-')[1]}`;
    } else if(dateToDisplay && dateToDisplay.indexOf('/') > 0) {
        todayString = `${dateToDisplay.split('/')[2]}-${dateToDisplay.split('/')[0]}-${dateToDisplay.split('/')[1]}`;
    }

    React.useEffect(() => {
        if (taskFormRef.current) {
            taskFormRef.current.scrollIntoView();
        }
    }, []);

    return (
        <div className="personal-task-form" data-testid="personal-task-form" ref={taskFormRef}>
            {taskFormErrors.length > 0 && (
                <Error testId="personal-task-form-error">
                    {taskFormErrors.indexOf('title') > -1
                        ? personalTaskFormText.errors.title
                        : ''}
                </Error>
            )}
            <Input
                type="text"
                name="title"
                id="title"
                extraClasses="task-title-input"
                testId="task-title"
                label={
                    <span>
                        {personalTaskFormText.checklistNameText}<sup>*</sup>
                    </span>
                }
                placeholder={personalTaskFormText.namePlaceholderText}
                initialValue={taskTitle}
                errors={taskFormErrors && taskFormErrors.indexOf('title') > -1 ?
                    [{ message: <span><sup>*</sup>{personalTaskFormText.errors.fieldError}</span> }]
                    : []
                }
                maxLength="100"
                onChange={(e) => {
                    return e.currentTarget.value.length <= 100 ?  handleChange(e) : ''
                }}
            />

            <div className="personal-tasks-date-picker-container">
                <label className="label label--dueDate" htmlFor="dueDate">
                    {personalTaskFormText.dueDateText}
                </label>
                {usePicker && (
                    <DatePicker
                        id="dueDate"
                        wrapperClassName="personal-task-form-date-picker"
                        placeholderText={personalTaskFormText.dueDatePlaceholderText}
                        selected={dateToDisplay ? new Date(dateToDisplay.replace(/-/g, "/")) : null}
                        onChange={handleChangeDueDate}
                        minDate={new Date()}
                        dateFormat="MM/dd/yyyy"
                        autoComplete="off"
                        popperPlacement="bottom"
                        strictParsing
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'], // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false, // turn off since needs preventOverflow to be enabled
                            },
                        }}
                    />
                )}
                {!usePicker && (
                    <>
                        <div className="personal-task-form-date-picker">
                            <input
                                className="input input--date"
                                onChange={checkDataInFuture}
                                type="date"
                                id="dueDate"
                                value={dateToDisplay ? todayString : null}
                                min={minDate}
                                placeholder="YYYY-MM-DD"
                            />
                        </div>
                        {!dateIsInFuture && (
                            <Error>Please choose a date in the future.</Error>
                        )}
                    </>
                )}
                <button type="button" className="personal-task-icon-button" onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('dueDate').focus()
                    return false;
                }}>
                    <Icon extraClasses="personal-task-calendar-icon" size={1.5} color="body-text" icon="calendar" />
                </button>
            </div>

            <div className="task-description-container">
                <label htmlFor="task-description" className="label label--task-description">
                    {personalTaskFormText.descriptionText}
                </label>
                <textarea
                    className="task-description"
                    id="task-description"
                    name="description"
                    value={taskDescription}
                    data-testid="task-description"
                    rows="6"
                    placeholder={personalTaskFormText.descriptionPlaceholderText}
                    onChange={handleChange}
                />
                <p className="task-description-length-count">
                    {`${taskDescription ? taskDescription.length : 0} of 400`}
                </p>
            </div>

            <div className="task-form-buttons">
                <Button
                    as="button"
                    testId="task-cancel-button"
                    text={personalTaskFormText.cancelText}
                    style={ButtonStyles.TextOnlyLink}
                    bold
                    onClick={handleCancelTaskForm}
                />
                <Button
                    as="button"
                    testId="task-save-button"
                    text={personalTaskFormText.saveText}
                    style={ButtonStyles.PrimaryButton}
                    bold
                    disabled={!dateIsInFuture}
                    onClick={handleSubmitTaskForm}
                />
            </div>
        </div>
    );
};

export default PersonalTaskForm;
