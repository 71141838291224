import React from 'react';
import PropTypes from 'prop-types';
import { AccordionGroup, ProgressAccordion } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import { useParams } from 'react-router-dom';

export function filterAccordionSteps(stages = [], deepLinkedStageId, stageKey) {
    const isPreconstruction = stageKey === 'preconstruction';
    const currentStage = findCurrentStage(stages, deepLinkedStageId);
    const hasCurrentStage = !!currentStage;
    const stagesLength = stages.length;
    const currentStageIndex = stages.indexOf(currentStage);
    const isCurrentFirst = currentStageIndex === 0;
    const isCurrentLast = currentStageIndex === stagesLength - 1;
    let previousStages = [];
    let currentStages = [];
    let upcomingStages = [];
    if (isPreconstruction) {
        currentStages = stages;
    } else if (!hasCurrentStage) {
        previousStages = [];
        currentStages = stages.slice(0, 3);
        upcomingStages = stages.slice(3, stagesLength);
    } else {
        previousStages = isCurrentLast
            ? stages.slice(0, currentStageIndex - 2)
            : stages.slice(
                0,
                currentStageIndex - 1 >= 0
                    ? currentStageIndex - 1
                    : currentStageIndex
            );

        currentStages = [
            isCurrentLast ? stages[currentStageIndex - 2] : null,
            stages[currentStageIndex - 1],
            stages[currentStageIndex],
            stages[currentStageIndex + 1],
            isCurrentFirst ? stages[currentStageIndex + 2] : null,
        ].filter((item) => !!item);

        upcomingStages = isCurrentFirst
            ? stages.slice(currentStageIndex + 3, stages.length)
            : stages.slice(currentStageIndex + 2, stages.length);
    }

    return {
        previousStages,
        currentStages,
        upcomingStages,
        currentStage,
    };
}

export function findCurrentStage(stages, deepLinkedStageId) {
    let currentStage;
    let foundDeepLinked = false;

    if(deepLinkedStageId) {
        const filtered = stages.filter(stage => stage.stageId === parseInt(deepLinkedStageId, 10));
        if(filtered[0]) {
            currentStage = filtered[0];
            foundDeepLinked = true;
        }
    }
    if(!foundDeepLinked) {
        currentStage =
            stages.find((stage) => stage.status === 'Started') ||
            stages.find((stage) => stage.status === 'NotStarted') ||
            stages[stages.length - 1];
    }
    return currentStage;
}

export default function TabsAccordionSection(props) {
    const { stages, stageKey, phaseTitle } = props;
    const params = useParams();
    const {
        previousStages,
        currentStages,
        upcomingStages,
        currentStage,
    } = filterAccordionSteps(stages, params.constructionStepId, stageKey);

    return (
        <div
            className="tabs-accordion-section-container"
            data-testid="tabs-accordion-section-container"
        >
            {previousStages.length > 0 && (
                <AccordionGroup
                    accordionData={previousStages}
                    type="previous"
                    testId="previous-accordion-group"
                />
            )}

            {currentStages.length > 0 && (
                currentStages.map((step, index) => {
                    let status = step.status && step.status.toLowerCase();
                    const defaultOpen = step === currentStage;
                    return (
                        <ProgressAccordion
                            {...step}
                            key={`current-${index}`}
                            indicator={Utils.getIndicator(status)}
                            testId="current-progress-accordion"
                            defaultOpen={defaultOpen}
                            phaseTitle={phaseTitle}
                        />
                    );
                })
            )}

            {upcomingStages.length > 0 && (
                <AccordionGroup
                    accordionData={upcomingStages}
                    type="upcoming"
                    testId="upcoming-accordion-group"
                />
            )}
        </div>
    );
}

TabsAccordionSection.defaultProps = {
    stages: null,
    stageKey: null,
};

TabsAccordionSection.propTypes = {
    stages: PropTypes.array,
    stageKey: PropTypes.string,
};
