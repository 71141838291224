import ActionTypes from 'redux/actions/ActionTypes';
import { sortDocuments } from './DocumentsTransform';

export const initialDocumentsState = {
    documents: null,
    description: null,
    individualDocuments: {},
    base64links: {},
    sortBy: 'date',
    sortOrder: 'desc',
    errors: null,
};

const getOpposite = (sortOrder) => {
    return sortOrder === 'asc' ? 'desc' : 'asc';
}

const DocumentsReducer = (state = initialDocumentsState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.GetSingleDocumentError:
            const newDocuments2 = {...state.individualDocuments};
            delete newDocuments2[action.payload.packageId];// = action.payload.data;
            return {
                ...state,
                individualDocuments: newDocuments2,
                errors: action.payload.errors,
            }

        case ActionTypes.ChangeActiveLot:
            return initialDocumentsState;
        case ActionTypes.SortDocuments:
            const newSortOrder = action.payload === state.sortBy ? getOpposite(state.sortOrder) : 'desc';
            return {
                ...state,
                sortBy: action.payload,
                sortOrder: newSortOrder,
                documents: sortDocuments(action.payload, newSortOrder, state.documents),
            }
        case ActionTypes.GetProgressContentLotContent:
             const contentData = action.payload.data;
            return {
                ...state,
                description: contentData && contentData.documentsPage ? contentData.documentsPage.description : null, 
            }
        case ActionTypes.LogOut:
            return initialDocumentsState;
        case ActionTypes.SetDocumentLink:
            const newBase64Links = {
                ...state.base64links
            };
            newBase64Links[action.payload.packageId] = action.payload.link;
            return {
                ...state,
                base64links: newBase64Links,
            };
        case ActionTypes.GetDocumentsSuccess:
            return {
                ...state,
                documents: action.payload
            };
        case ActionTypes.GetDocuments:
            return {
                ...state,
                documents: 'requested',
            };
        case ActionTypes.GetSingleDocument:
            const newDocumentsForRequest = {...state.individualDocuments};
            newDocumentsForRequest[action.payload] = 'requested';
            return {
                ...state,
                errors: null,
                individualDocuments: newDocumentsForRequest,
            }
        case ActionTypes.GetSingleDocumentSuccess:
            const newDocuments = {...state.individualDocuments};
            newDocuments[action.payload.packageId] = action.payload.data;
            return {
                ...state,
                errors: null,
                individualDocuments: newDocuments,
            }
        default:
            return state;
    }
};

export default DocumentsReducer;
