import ActionTypes from 'redux/actions/ActionTypes';

export const initialHomesState = {
    divisions: null,
    communities: {},
    searchResultsByName: null,
    searchResultsByAddress: null,
    sortOrder: 'asc',
    sortBy: 'name',
    busy: false,
    addressSearch: '',
    firstNameSearch: '',
    lastNameSearch: '',
};

const sort = (searchResults, sortBy, sortOrder) => {
    return searchResults && searchResults.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
            return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
            return sortOrder === 'asc' ? 1 : -1;
        }
        if (sortBy === 'name') {
            if (a['lotId'] > b['lotId']) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            if (a['lotId'] < b['lotId']) {
                return sortOrder === 'asc' ? -1 : 1;
            }
        } else if (sortBy === 'address') {
            if (a['city'] > b['city']) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            if (a['city'] < b['city']) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            //so now the cities are the same, compare lot id
            if (a['lotId'] > b['lotId']) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            if (a['lotId'] < b['lotId']) {
                return sortOrder === 'asc' ? -1 : 1;
            }
        }
        return 0;
    });
};

const HomesReducer = (state = initialHomesState, action) => {
    const newCommunities = state.communities;
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.UpdateLotSearchParam:
            // action.payload.value
            const { field } = action.payload;
            if (field === 'firstName') {
                return {
                    ...state,
                    firstNameSearch: action.payload.value,
                }
            }
            if (field === 'lastName') {
                return {
                    ...state,
                    lastNameSearch: action.payload.value,
                }
            }
            if (field === 'address') {
                return {
                    ...state,
                    addressSearch: action.payload.value,
                }
            }
        case ActionTypes.ClearSearchResults:
            let so = state.sortOrder;
            let sb = state.sortBy;
            if (action.payload && action.payload.mode === 'address') {
                so = 'asc';
                sb = 'address';
            }
            if (action.payload && action.payload.mode === 'registered buyer name') {
                so = 'asc';
                sb = 'name';
            }
            return {
                ...state,
                searchResultsByName: null,
                searchResultsByAddress: null,
                sortOrder: so,
                sortBy: sb,
            }
        case ActionTypes.SortLotSearchResults:
            const { payload } = action;
            const  { sortBy, sortOrder } = payload;
            return {
                ...state,
                sortBy,
                sortOrder,
                searchResultsByName: sort(state.searchResultsByName, sortBy, sortOrder),
                searchResultsByAddress: sort(state.searchResultsByAddress, sortBy, sortOrder),
            }
        case ActionTypes.GetDivisions:
            return {
                ...state,
                divisions: 'requested',
            };
        case ActionTypes.GetDivisionsSuccess:
            return {
                ...state,
                divisions: action.payload.sort((a, b) => { return a.name < b.name ? -1 : 1;}),
            };
        case ActionTypes.GetCommunitiesSuccess:
            newCommunities[action.payload.divisionId] = action.payload.communities.sort((a, b) => { return a.name < b.name ? -1 : 1;});
            return {
                ...state,
                communities: newCommunities,
            };
        case ActionTypes.GetCommunities:
            newCommunities[action.payload] = 'requested';
            return {
                ...state,
                communities: newCommunities,
            };
        case ActionTypes.SearchForLotByName:
            return {
                ...state,
                searchResultsByName: null,
                busy: true,
            };

        case ActionTypes.SearchForLotByAddress:
            return {
                ...state,
                searchResultsByAddress: null,
                busy: true,
            };
        case ActionTypes.SearchForLotByAddressResults:
            return {
                ...state,
                searchResultsByAddress: sort(action.payload, state.sortBy, state.sortOrder),
                busy: false,
            };
        case ActionTypes.SearchForLotByNameResults:
            return {
                ...state,
                searchResultsByName: sort(action.payload, state.sortBy, state.sortOrder),
                busy: false,
            };
        default:
            return state;
    }
};
export default HomesReducer;
