import React from 'react';
import PropTypes from 'prop-types';
import { useHasOrientationData } from 'utilities/HooksAndSelectors';
import { getPlainDateAndTime } from 'utilities/Utils';
import { Card, Text } from 'ComponentLibrary';
import TabContent from './TabContent';

const ConstructionTabContent = (props) => {
    const { tab, active } = props;
    const tabContent = <TabContent tab={tab} active={active} />;
    let hasOrientationData = useHasOrientationData();
    const d = new Date(Date.parse(hasOrientationData));
    const combinedDateAndTime = getPlainDateAndTime(hasOrientationData);
    const today = new Date();

    if (today.getFullYear() > d.getFullYear()) {
        hasOrientationData = false;
    } else if (
        today.getFullYear() === d.getFullYear() &&
        today.getMonth() > d.getMonth()
    ) {
        hasOrientationData = false;
    } else if (
        today.getFullYear() === d.getFullYear() &&
        today.getMonth() === d.getMonth() &&
        today.getDate() > d.getDate()
    ) {
        hasOrientationData = false;
    }
    return hasOrientationData && active ? (
        <>
            <Card type="alert">
                <Text color="err">
                    Your New Home Orientation is scheduled to take place on {combinedDateAndTime}.
                </Text>
            </Card>
            {tabContent}
        </>
    ) : (
        tabContent
    );
};

ConstructionTabContent.defaultProps = {};

ConstructionTabContent.propTypes = {
    tab: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
};

export default ConstructionTabContent;
