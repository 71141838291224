import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    useGetCurrentLotSummary,
    useIsAdmin,
} from 'utilities/HooksAndSelectors';
import { createOpenPhotoModalAction } from 'redux/actions/PhotoModal/PhotoModalActions';
import './MyHome.scss';
import Constants from 'Constants';
import Utils from 'utilities/Utils';
import MyHomeDesktopSkeleton from './MyHomeDesktopSkeleton';
import MyHomeMobileSkeleton from './MyHomeMobileSkeleton';

const MyHome = (props) => {
    const { images, skeletonMode } = props;
    const dispatch = useDispatch();
    const filteredImages = images.filter((image) => image.photoLink !== null);
    const imageGrp = filteredImages.map((obj, index) => ({ ...obj, index }));
    const isAdmin = useIsAdmin();
    let lot = useGetCurrentLotSummary(isAdmin);
    if (!lot) {
        lot = {
            address: {
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
            },
        };
    }
    const { address } = lot;
    const { streetAddress, city, state, zip } = address;

    const moreThanOne = imageGrp.length > 1;
    const haveAThird = imageGrp.length >= 3;

    const imagesToUse = imageGrp.slice(0, 3);

    const handleImageClick = (mapIndex, event) => {
        const { index, caption } = event.currentTarget.dataset;
        dispatch(
            createOpenPhotoModalAction({
                currentImageIndex: parseInt(index, 10),
                currentPageNumber: parseInt(index, 10) + 1,
                caption,
            })
        );
    };

    const isMobileWindow = window.innerWidth <= 764;

    return (
        <section
            className="dashboard-card dashboard-card--no-mobile-padding myhome-container"
            data-testid="myhome-container"
        >
            <div
                className={`myhome-container__img-grid myhome-container__img-grid--${
                    moreThanOne && !skeletonMode ? 'more-than-1' : ''
                }`}
            >
                {skeletonMode ? (
                    isMobileWindow ? (
                            <MyHomeMobileSkeleton />
                        ) : (
                            <MyHomeDesktopSkeleton />
                        )
                ) : (
                    imagesToUse.map((mainHomeImage, index) => (
                        <div
                            key={`dashboard-image-${index}`}
                            className={`myhome-container__img-wrap myhome-container__img-wrap--${
                                moreThanOne ? 'more-than-1' : ''
                            }`}
                        >
                            {index < 2 && (
                                <button
                                    type="button"
                                    data-testId="main-home-photo-button"
                                    data-index={mainHomeImage.index}
                                    data-caption={mainHomeImage.caption}
                                    onClick={(e) => {
                                        handleImageClick(index, e);
                                    }}
                                    className={`myhome-container__img-launcher myhome-container__img-launcher--${index === 2 ? 'view-gallery' : 'single'} ${
                                        imageGrp.length === 1 &&
                                        'myhome-container__img-launcher--single'
                                    }`}
                                >
                                    {mainHomeImage && mainHomeImage.photoLink && (
                                        <img
                                            alt={`My Home: ${streetAddress} ${city}, ${state} ${zip}`}
                                            lazy="true"
                                            width="300"
                                            height="200"
                                            srcSet={Utils.fixImageUrl(mainHomeImage.photoLink)}
                                            className="myhome-container__img"
                                            data-testid="myhome-container__img"
                                            data-index={mainHomeImage.index}
                                            sizes={`(min-width: (min-width: 1200px) ${index === 0 ? '640px' : '188px'}, (min-width: (min-width: 764px) ${index === 0 ? '77vw' : '23vw'}, 100vw`}
                                        />
                                    )}
                                    {index === 0 && (
                                        <address className="myhome-container__address">
                                            <p className="myhome-container__street-address">
                                                {streetAddress}
                                            </p>
                                            {city}, {state} {zip}
                                        </address>
                                    )}
                                    {index === 2 && (
                                        <span className="myhome-container__img-launcher-bg myhome-container__img-launcher-bg--dark">
                                            {Constants.photogallery.viewAllLink}
                                        </span>
                                    )}
                                </button>
                            )}
                            {index === 2 && (
                                <Link to="/photos" data-testid="image-photos-link" className={`myhome-container__img-launcher myhome-container__img-launcher--${index === 2 ? 'view-gallery' : 'single'} ${
                                    imageGrp.length === 1 &&
                                    'myhome-container__img-launcher--single'
                                }`}>
                                    <img
                                        alt={`My Home: ${streetAddress} ${city}, ${state} ${zip}`}
                                        lazy="true"
                                        width="300"
                                        height="200"
                                        srcSet={Utils.fixImageUrl(mainHomeImage.photoLink)}
                                        className="myhome-container__img"
                                        data-testid="myhome-container__img"
                                        data-index={mainHomeImage.index}
                                        sizes={`(min-width: (min-width: 1200px) ${index === 0 ? '640px' : '188px'}, (min-width: (min-width: 764px) ${index === 0 ? '77vw' : '23vw'}, 100vw`}
                                    />
                                    <span className="myhome-container__img-launcher-bg myhome-container__img-launcher-bg--dark">
                                        {Constants.photogallery.viewAllLink}
                                    </span>
                                </Link>
                            )}
                        </div>
                    ))
                )}
                {!haveAThird && moreThanOne && (
                    <Link
                        to="/photos"
                        data-testid="my-home-photos-link"
                        className="myhome-container__img-launcher myhome-container__img-launcher--view-gallery myhome-container__img-launcher--empty"
                    >
                        {Constants.photogallery.viewAllLink}
                    </Link>
                )}
            </div>
        </section>
    );
};

MyHome.defaultProps = {
    images: [],
};

MyHome.propTypes = {
    images: PropTypes.array,
};

export default MyHome;
