import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconTypes, IconSizes } from 'ComponentLibrary';

import './Tooltip.scss';

const Tooltip = (props) => {
    const { icon, content, size, inverted } = props;
    const [isOver, setIsOver] = useState(false);
    const [shiftClass, setShiftClass] = useState(null);
    const ref = useRef();
    useEffect(() => {
        if (ref && ref.current) {
            const box = ref.current.getBoundingClientRect();
            const style = window.getComputedStyle(ref.current, ":after");
            if (
                parseInt(style.width.replace('px', ''), 10)/2 + box.left > window.innerWidth
             ) {
                const shiftBy = Math.abs(window.innerWidth - (parseInt(style.width.replace('px', ''), 10)/2 + box.left));
                if (shiftBy < 10) {
                    setShiftClass('ten')
                } else if(shiftBy < 20) {
                    setShiftClass('twenty');
                } else if(shiftBy < 30) {
                    setShiftClass('thirty');
                } else if(shiftBy < 40) {
                    setShiftClass('fourty');
                } else if(shiftBy < 50) {
                    setShiftClass('fifty');
                } else if(shiftBy < 60) {
                    setShiftClass('sixty');
                } else if(shiftBy < 70) {
                    setShiftClass('seventy');
                } else if(shiftBy < 80) {
                    setShiftClass('eighty');
                } else if(shiftBy < 90) {
                    setShiftClass('ninety');
                } else if(shiftBy < 100) {
                    setShiftClass('hundred');
                } else if(shiftBy < 110) {
                    setShiftClass('hundredten');
                } else if(shiftBy < 120) {
                    setShiftClass('hundredtwenty');
                } else if(shiftBy < 130) {
                    setShiftClass('hundredthirty');
                }
            }
        }
    }, [isOver]);
    return (
        <span
            ref={ref}
            className={`mi-tooltip ${inverted && 'inverted'} ${`mi--tooltip--${shiftClass}`}`}
            role="tooltip"
            data-tooltip={content}
            data-testid="tooltip"
            onMouseEnter={(e) => {
                setIsOver(true);
            }}
            onMouseLeave={(e) => {
                setIsOver(false);
            }}

        >
            <Icon size={size} icon={IconTypes[icon]} />
        </span>
    );
};
Tooltip.defaultProps = {
    size: IconSizes.KindaSmall,
    inverted: false,
}
Tooltip.propTypes = {
    size: PropTypes.number,
    inverted: PropTypes.bool,
}
export default Tooltip;
