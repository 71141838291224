/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import ActionTypes from 'redux/actions/ActionTypes';
import { combineReducers } from 'redux';
import WarrantyClaimsReducer from 'redux/reducers/WarrantyClaims';

const initialState = {
    categories: null,
    activeCategories: [],
    viewAllCategories: false,
    pageStatus: 'details',
    openClaimForm: null,
    draftModalHidden: false,
    showCancelModal: false,
    busyOrIsLoading: false,
};

function findFirstIncompleteItem(serviceItems, currentValue, newlyAdded) {
    let badId = null;
    serviceItems.forEach((serviceItem) => {
        const {
            selectedSubCategory,
            categoryId,
            comments,
            selectedIssue,
        } = serviceItem;
        if (
            !badId &&
            (!selectedSubCategory ||
                !comments ||
                !selectedIssue ||
                selectedIssue === '' ||
                selectedSubCategory === '' ||
                comments === '')
        ) {
            badId = categoryId;
        }
    });
    if (badId !== null) {
        return badId;
    }
    return newlyAdded;
}

const WarrantyDetailsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.GetCategoriesError:
        case ActionTypes.GetCategoryDataError:
            return {
                ...state,
                haveFatalWarrantyError: true,
            }
        case ActionTypes.SignOut:
        case ActionTypes.LogOut:
            return {
                initialState,
            };
        case ActionTypes.LoadClaimDataFromFile:
            if (action.payload.claimData && Array.isArray(action.payload.claimData) && action.payload.claimData.length > 0) {
                if (action.payload.page === 'review' || action.payload.page === 'details' || action.payload.page === 'submit') {
                    return {
                        ...state,
                        pageStatus: action.payload.page,
                        openClaimForm: action.payload.openClaim || state.openClaimForm,
                    };
                }
                return state;
            }
            break;
        case ActionTypes.GetProgressContentLotContent:
            return {
                ...state,
                pageContent: action.payload && action.payload.data && action.payload.data.warrantyPage,
            }
        case ActionTypes.UpdateClaimDetails: {
            return {
                    ...state,
                    busyOrIsLoading: false,
                };
            }
        case ActionTypes.ResetAfterSubmitSuccess:
            return {
                ...initialState,
                pageStatus: 'details',
                categories: state.categories,
            };
        case ActionTypes.RemoveExistingClaimsData:
            return {
                ...state,
                pageStatus: action.payload,
            };
        case ActionTypes.ServiceRequestCompleted:
        case ActionTypes.SuccessfulWarrantySubmission:
            return {
                ...state,
                pageStatus: 'submit',
            };
        case ActionTypes.UpdateWarrantyPageStatus:
            return {
                ...state,
                pageStatus: action.payload,
                draftModalHidden: action.payload === 'review' ? true : state.draftModalHidden,
                openClaimForm: null,
            };

        case ActionTypes.GetCategoriesSuccess:
            return {
                ...state,
                busyOrIsLoading: false,
                categories: action.payload,
            };

        case ActionTypes.SetOpenClaim:
            return {
                ...state,
                openClaimForm: action.payload,
            };

        case ActionTypes.SetActiveCategory:
            return {
                ...state,
                busyOrIsLoading: true,
                activeCategories: [
                    ...state.activeCategories || [],
                    ...[action.payload],
                ],
            };

        case ActionTypes.RemoveActiveCategory:
            return {
                ...state,
                activeCategories: [
                    ...state.activeCategories.slice(
                        0,
                        state.activeCategories.indexOf(action.payload)
                    ),
                    ...state.activeCategories.slice(
                        state.activeCategories.indexOf(action.payload) + 1
                    ),
                ],
            };

        case ActionTypes.UpdateWarrantyPageStatusFromReview:
            return {
                ...state,
                pageStatus: action.payload.page,
                openClaimForm: action.payload.id,
            };

        case ActionTypes.AddClaim:
            return {
                ...state,
                openClaimForm: !state.openClaimForm ? action.payload.categoryId : findFirstIncompleteItem(action.payload.serviceItems, state.openClaimForm, action.payload.categoryId),
            };

        case ActionTypes.SetClaimWithError:
            return {
                ...state,
                openClaimForm: action.payload,
            };

        case ActionTypes.LoadClaimsActiveCategoriesFromFile:
            if (Array.isArray(action.payload)) {
                let foundInvalidServiceItem = false;
                action.payload.forEach((serviceItem) => {
                    const { categoryName, categoryId } = serviceItem;
                    if (!categoryName || !categoryId) {
                        foundInvalidServiceItem = categoryId;
                    }
                })
                if (foundInvalidServiceItem) {
                    return {
                        ...state,
                        activeCategories: action.payload,
                        draftModalHidden: true,
                        openClaimForm: foundInvalidServiceItem,
                    };
                }
                return {
                    ...state,
                    activeCategories: action.payload,
                    draftModalHidden: true,
                };
            } else {
                return {
                    ...state,
                    activeCategories: [],
                    draftModalHidden: true,
                };
            }

        case ActionTypes.HideClaimDraftModal:
            return {
                ...state,
                draftModalHidden: true,
            };

        case ActionTypes.CloseClaimCancelModal:
            return {
                ...state,
                showCancelModal: false,
            };

        case ActionTypes.OpenClaimCancelModal:
            return {
                ...state,
                showCancelModal: true,
            };

        default:
            return state;
    }
};

const WarrantyReducer = combineReducers({
    warrantyDetails: WarrantyDetailsReducer,
    warrantyClaims: WarrantyClaimsReducer,
});

export default WarrantyReducer;
