import ApiService from './ApiService.js';

const NotificationsService = {
    getNotifications: (options, service = ApiService()) => service
        .get(`/Notifications?lotId=${options.lotId}`, true)
        .then((response) => response)
        .catch((error) => error.response),
    setViewedNotification: (options, service = ApiService()) => service
        .put(`/Notifications/${options.notificationId}/viewed`, true)
        .then((response) => response)
        .catch((error) => error.response),
    getSampleNotifications: (options, service = ApiService()) =>
        service
            .get(`/Notifications/sample?lotId=${options.lotId}`, true)
            .then((response) => response)
            .catch((error) => error),
};

export default NotificationsService;
