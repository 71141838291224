import React from 'react';
import { Text } from 'ComponentLibrary';
import Constants from 'Constants';
import Link from './Link';

const ForgotPasswordLink = () => {
    return (
        <Text type="inline" variant="small">
            <Link
                eventLabel={Constants.global.links.forgotPassword.eventLabel}
                to={Constants.global.links.forgotPassword.link}
                blank
            >
                {Constants.global.links.forgotPassword.label}
            </Link>
        </Text>
    )
};

export default ForgotPasswordLink;
