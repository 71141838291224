import ActionTypes from 'redux/actions/ActionTypes';

const initialState = null;

const AdminReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.GetAssociatedBuyers:
            return 'requested';
        case ActionTypes.GetAssociatedBuyersError:
            return action.payload;
        case ActionTypes.GetAssociatedBuyersSuccess:
            return action.payload;
        case ActionTypes.GetLotByIdSuccess:
        case ActionTypes.ChangeActiveLot:
        case ActionTypes.SignOut:
        case ActionTypes.LogOut:
            return initialState;
        default:
            return state;
    }
};
export default AdminReducer;
