import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

export default function Card(props) {
    const {
        imageUrl,
        text,
        headerText,
        linkText,
        type,
        authorImgUrl,
        authorName,
        badge,
        linkIcon,
        children
    } = props;

    return (
        <div className={`mi-card ${type}`} data-testid="card-container">
            {type !== 'container' && type !== 'alert' && (
                <img
                    className="mi-card-image"
                    src={imageUrl}
                    data-testid="card-image"
                    alt="Card"
                />
            )}
            {type !== 'container' && badge}
            {type !== 'container' && type !== 'alert' && (
                <div className="mi-card-content">
                    <p className="content-header">{headerText}</p>
                    <p className="content-text">{text}</p>
                    {type === 'article' && authorImgUrl && authorName && (
                        <div className="content-author">
                            <img
                                className="content-author-image"
                                src={authorImgUrl}
                                data-testid="author-image"
                                alt="Author"
                            />
                            <p className="content-author-name">{authorName}</p>
                        </div>
                    )}
                    <p className="content-link">
                        {linkText}
                        {linkIcon}
                    </p>
                </div>
            )}
            {children}
        </div>
    );
}

Card.defaultProps = {
    imageUrl: null,
    text: null,
    headerText: null,
    linkText: null,
    type: null,
    authorImgUrl: null,
    authorName: null,
    badge: null,
    linkIcon: null,
    children: null,
};

Card.propTypes = {
    imageUrl: PropTypes.string,
    text: PropTypes.string,
    headerText: PropTypes.string,
    linkText: PropTypes.string,
    type: PropTypes.string,
    authorImgUrl: PropTypes.string,
    authorName: PropTypes.string,
    badge: PropTypes.element,
    linkIcon: PropTypes.element,
    children: PropTypes.object,
};
