import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import WithCondition from 'hoc/WithCondition';
import { createOpenVideoModalAction } from 'redux/actions/VideoModal/VideoModalActions';
import { Icon, IconTypes } from 'ComponentLibrary';

const TabContentImage = ({
    imageLink,
    videoLink,
    altText,
    phaseTitle,
    testId,
}) => {
    const dispatch = useDispatch();
    if (!imageLink) return null;

    const altImageText = `${phaseTitle || ''} ${altText || ''}`;
    const handlePlayButtonClick = () => {
        dispatch(createOpenVideoModalAction(videoLink, altText));
    };
    return (
        <WithCondition
            condition={
                videoLink !== null && videoLink.indexOf('youtube.com/') >= 0
            }
            wrapper={(children) => (
                <button
                    className="play-button"
                    data-play-video-modal
                    type="button"
                    style={{ width: '100%' }}
                    data-testid="tab-content-video-button"
                    onClick={handlePlayButtonClick}
                >
                    {children}
                    <Icon
                        size={2}
                        centered
                        color="white"
                        border="white"
                        circle="transparent"
                        icon={IconTypes.Play}
                    />
                </button>
            )}
        >
            <img
                data-testid="tab-content-image"
                lazy="true"
                src={imageLink}
                alt={altImageText}
            />
        </WithCondition>
    );
};

TabContentImage.defaultProps = {
    imageLink: null,
    videoLink: null,
};

TabContentImage.propTypes = {
    imageLink: PropTypes.string,
    videoLink: PropTypes.string,
};

export default TabContentImage;
