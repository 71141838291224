import { combineReducers } from 'redux';
import {
    User,
    LotAssociation,
    Features,
    Tasks,
    Notifications,
    ContactBio,
    Feedback,
    PhotoModal,
    AcuityModal,
    Contacts,
    Warranty,
    Hamburger,
    Documents,
    Division,
    Deposits,
    Share,
    Homes,
    DesignSelections,
    Admin,
} from './reducers';

// Add all reducers here
// when you signout or change lot, you need to dump data for:
// tasks, contacts, deposits
// do not use Utils or Service In A Reducer, Tests Will Fail
export default combineReducers({
    userState: User,
    shareState: Share,
    lotAssociationState: LotAssociation,
    featuresState: Features,
    taskState: Tasks,
    notificationsState: Notifications,
    contactBioState: ContactBio,
    photoModalState: PhotoModal,
    acuityModalState: AcuityModal,
    feedbackState: Feedback,
    contactsState: Contacts,
    warrantyState: Warranty,
    hamburgerState: Hamburger,
    documentsState: Documents,
    divisionState: Division,
    depositsState: Deposits,
    homesState: Homes,
    designSelectionsState: DesignSelections,
    adminState: Admin,
});
