import React, { useState } from 'react';
import { Heading, Icon, Button, ButtonStyles } from 'ComponentLibrary';
import { Accordion, Card } from 'react-bootstrap';
import Constants from 'Constants';
import TaskSetAccordion from '../TaskSetAccordion';
import PersonalUpcomingTaskSetAccordion from '../PersonalUpcomingTaskSetAccordion';
import './PersonalTasksAccordionCard.scss';

const PersonalTasksAccordionCard = (props) => {
    const {
        completeTasks,
        upcomingTasks,
        mode,
        handleToggle,
        handleToggleAddTaskForm,
        isPersonalTasksOpen,
        showAddTaskForm,
        showEditTaskForm,
        handleChangeNewTask,
        taskFormData,
        handleCancelTaskForm,
        handleSubmitTaskForm,
        handleChangeDueDate,
        taskFormErrors,
    } = props;

    const isMobileWindow = window.innerWidth <= 764;
    const [isPersonalUpcomingOpen, setIsPersonalUpcomingOpen] = useState(true);
    const checklistModalText = Constants.checklistModal;

    const toggleAddTask = () => {
        if (!isPersonalUpcomingOpen) {
            setIsPersonalUpcomingOpen(true);
        }

        if (!showAddTaskForm && !showEditTaskForm) {
            handleToggleAddTaskForm();
        }
    };

    return (
            <Card className="personal-checklist-card" data-testid="personal-checklist-card">
                <Accordion.Toggle
                    className="personal-checklist-toggle"
                    variant="link"
                    onClick={handleToggle}
                    eventKey="0"
                    data-testid="personal-checklist-toggle"
                >
                    <div className="personal-checklist-toggle-content">
                        <Heading like="h5" as="h5">
                            {checklistModalText.personalTasksAccordion.title}
                        </Heading>
                        <Icon
                            icon="skinnyarrow"
                            color={isPersonalTasksOpen ? 'white' : 'aqua'}
                            circle={isPersonalTasksOpen ? 'boston-blue' : null}
                            border="boston-blue"
                            extraClasses="personal-checklist-toggle-accordion-icon"
                            testId="personal-checklist-toggle-accordion-icon"
                        />
                    </div>
                </Accordion.Toggle>
                { !showAddTaskForm && !showEditTaskForm && isMobileWindow && (
                    <Button
                        as="button"
                        icon="largePlus"
                        addClass="mobile-add-task-button"
                        testId="mobile-add-task-form-button"
                        text={checklistModalText.addTaskButtonText}
                        style={ButtonStyles.SecondaryButton}
                        onClick={toggleAddTask}
                    />
                )}
                <Accordion.Collapse eventKey="0">
                    <div className="personal-checklist-toggle-collapse-container">
                        <PersonalUpcomingTaskSetAccordion
                            label={checklistModalText.upcomingText}
                            tasks={upcomingTasks}
                            mode={mode}
                            isPersonalTasksOpen={isPersonalTasksOpen}
                            testId="upcoming-task-set"
                            handleToggleAddTaskForm={toggleAddTask}
                            showAddTaskForm={showAddTaskForm}
                            showEditTaskForm={showEditTaskForm}
                            handleChangeNewTask={handleChangeNewTask}
                            taskFormData={taskFormData}
                            handleCancelTaskForm={handleCancelTaskForm}
                            handleSubmitTaskForm={handleSubmitTaskForm}
                            handleChangeDueDate={handleChangeDueDate}
                            isPersonalUpcomingOpen={isPersonalUpcomingOpen}
                            handlePersonalUpcomingToggle={() => {setIsPersonalUpcomingOpen(!isPersonalUpcomingOpen)}}
                            taskFormErrors={taskFormErrors}
                        />
                        <TaskSetAccordion
                            label={checklistModalText.completedText}
                            tasks={completeTasks}
                            mode={mode}
                            testId="completed-task-set"
                        />
                    </div>
                </Accordion.Collapse>
            </Card>
    );
};

export default PersonalTasksAccordionCard;
