export function sortSelections(selectionsArray, type) {
    return [...selectionsArray].sort((a, b) => {
        switch (type) {
            case 'category-ascending':
                return (a.category === null)-(b.category === null) || a.category < b.category ? -1 : 1;
            case 'category-descending':
                return (a.category === null)-(b.category === null) || a.category > b.category ? -1 : 1;
            case 'description-ascending':
                return (a.description === null)-(b.description === null) || a.description < b.description ? -1 : 1;
            case 'description-descending':
                return (a.description === null)-(b.description === null) || a.description > b.description ? -1 : 1;
            case 'quantity-ascending':
                return (a.quantity === null)-(b.quantity === null) || a.quantity < b.quantity ? -1 : 1;
            case 'quantity-descending':
                return (a.quantity === null)-(b.quantity === null) || a.quantity > b.quantity ? -1 : 1;
            case 'date-ascending':
                return (a.createDate === null)-(b.createDate === null) || a.createDate < b.createDate ? -1 : 1;
            case 'date-descending':
                return (a.createDate === null)-(b.createDate === null) || a.createDate > b.createDate ? -1 : 1;
            default:
                return 0;
        }
    });
}