import ActionTypes from 'redux/actions/ActionTypes';

export const initialDivisionState = {
    contacts: null,
    notifications: null,
    tasks: null,
};

const DivisionReducer = (state = initialDivisionState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.GetLotById:
        case ActionTypes.SignOut:
        case ActionTypes.LogOut:
            return initialDivisionState;
        case ActionTypes.GetDivisionContacts:
            return {
                ...state,
                contacts: 'requested',
            };
        case ActionTypes.GetSampleNotifications:
            return {
                ...state,
                notifications: 'requested',
            };
        case ActionTypes.GetDivisionTasks:
            return {
                ...state,
                tasks: 'requested',
            };
        case ActionTypes.GetDivisionTasksSuccess:
            return {
                ...state,
                tasks: action.payload,
            }
        case ActionTypes.GetSampleNotificationsSuccess:
            return {
                ...state,
                notifications: action.payload,
            };
        case ActionTypes.GetDivisionContactsSuccess:
            return {
                ...state,
                contacts: action.payload,
            }
        case ActionTypes.GetDivisionContactsError:
            return {
                ...state,
                contacts: [],
            }
        default:
            return state;
    }
};

export default DivisionReducer;
