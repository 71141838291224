import store from 'redux/configureStore';

const withCurrentLotId = (path = '$lotId') => {
    const state = store.getState();
    if (state) {
        const { userState } = store.getState();
        if (userState) {
            const { profileAndSettings } = userState;
            if (profileAndSettings) {
                const activeLotId = profileAndSettings.activeLotId || null;
                if (activeLotId) {
                    return path.replace('$lotId', activeLotId);
                }
            }
        }
    }
    return path;
};

// eslint-disable-next-line import/prefer-default-export
export { withCurrentLotId };
