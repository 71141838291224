import ActionTypes from 'redux/actions/ActionTypes';

export function createAddLotIdAction(lotId) {
    return {
        type: ActionTypes.AddLotId,
        payload: lotId,
    };
}

export function createCancelAddLotAction() {
    return {
        type: ActionTypes.CancelAddLot,
    };
}

export function createClearLotErrorsAction() {
    return {
        type: ActionTypes.ClearLotErrors,
    }
}

export function createGetLotByIdAction(
    lotId,
    communityName = null,
    buyerName = null,
    address = null,
    city = null
) {
    return {
        type: ActionTypes.GetLotById,
        payload: {
            lotId,
            communityName,
            buyerName,
            address,
            city,
        }
    };
}

export function createUpdateLotAssociationIdAction(lotId) {
    return {
        type: ActionTypes.UpdateLotId,
        payload: lotId,
    };
}

export function createFetchLotToolTipContentAction() {
    return {
        type: ActionTypes.FetchLotToolTipContent,
    };
}
