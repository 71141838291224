import React from 'react';
import PropTypes from 'prop-types';
import ContactGroup from './ContentComponents/ContactGroup';

const ContactTree = ({ contacts, mode, skeletonSections }) => {
    const isSkeleton = mode === 'skeleton';
    if (isSkeleton && !Array.isArray(contacts)) {
        contacts = new Array(skeletonSections);
        contacts.fill(true);
    }
    return contacts.map((section, sectionIndex) => {
        if (!isSkeleton && (!section.contacts || section.contacts.length === 0)) {
            return null;
        }
        return (
            <ContactGroup
                section={section}
                sectionIndex={sectionIndex}
                isSkeleton={isSkeleton}
            />
        );
    });
};

ContactTree.Modes = {
    Skeleton: 'skeleton',
    Active: 'active',
};

ContactTree.propTypes = {
    mode: PropTypes.string,
    contacts: PropTypes.array,
    skeletonSections: PropTypes.number,
};

ContactTree.defaultProps = {
    mode: 'skeleton',
    skeletonSections: 6,
};

export default ContactTree;
