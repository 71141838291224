import ActionTypes from 'redux/actions/ActionTypes';

export const initialContactsState = null;

const ContactsReducer = (state = initialContactsState, action) => {
    switch (action.type) {
        case ActionTypes.ChangeActiveLot:
        case ActionTypes.OpenFindLotPage:
        case ActionTypes.LogOut:
        case ActionTypes.SignOut:
        case ActionTypes.LotIdSuccess:
            return initialContactsState;
        case ActionTypes.GetContacts:
            return 'requested';
        case ActionTypes.GetContactsSuccess:
            return action.payload;
        default:
            return state;
    }
};
export default ContactsReducer;
