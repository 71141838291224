import ActionTypes from 'redux/actions/ActionTypes';

export const triggerEnvisionExternalRedirectAction = () => ({
  type: ActionTypes.StartExternalRedirect,
  payload: 'envision',
});

export const triggerWellsFargoExternalRedirectAction = () => ({
  type: ActionTypes.StartExternalRedirect,
  payload: 'wellsfargo',
});

export const triggerWarrantyExternalRedirectAction = () => ({
  type: ActionTypes.StartExternalRedirect,
  payload: 'warranty',
});

export const triggerExternalRedirectNoURLAction = (redirectType) => ({
  type: ActionTypes.FetchExternalRedirectNoUrl,
  payload: redirectType,
});

export const handleFinancingExternalRedirectErrorAction = () => ({
  type: ActionTypes.FetchFinancingExternalRedirectError,
});
