import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    tasks: '',
    personalTasks: '',
    taskIdsToComplete: [],
    taskIdsToUncomplete: [],
    dropdownTasks: null,
    dropdownPersonalTasks: null,
    checklistDropDownOpen: false,
    isPersonalTasksOpen: false,
    showAddTaskForm: false,
    showEditTaskForm: false,
    taskFormData: null,
    taskFormErrors: [],
};

const TasksReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.ChangeActiveLot:
        case ActionTypes.OpenFindLotPage:
            return initialState;
        case ActionTypes.GetTasks:
            return {
                ...state,
                tasks: 'requested',
                personalTasks: 'requested',
                taskIdsToComplete: [],
            };
        case ActionTypes.OpenChecklistModal:
            return {
                ...state,
                checklistDropDownOpen: false,
            };
        case ActionTypes.GetTasksSuccess:
            return {
                ...state,
                tasks: action.payload.tasks,
                personalTasks: action.payload.personalTasks,
                taskIdsToComplete: [],
            };
        case ActionTypes.UpdateTasksSuccess: {
            return {
                ...state,
                taskIdsToComplete: [],
                taskIdsToUncomplete: [],
            }
        }
        case ActionTypes.CompleteTask:
            return {
                ...state,
                dropdownTasks: null,
                dropdownPersonalTasks: null,
            };
        case ActionTypes.OpenChecklistDropdown:
            return {
                ...state,
                checklistDropDownOpen: true,
            };
        case ActionTypes.CloseChecklistDropdown:
            return {
                ...state,
                checklistDropDownOpen: false,
            };

        case ActionTypes.OpenPersonalTasks:
            return {
                ...state,
                isPersonalTasksOpen: true,
            }
        case ActionTypes.ClosePersonalTasks:
            return {
                ...state,
                isPersonalTasksOpen: false,
            }
        case ActionTypes.CloseChecklistModal:
            return {
                ...state,
                showAddTaskForm: false,
                showEditTaskForm: false,
                isPersonalTasksOpen: false,
                taskFormData: null,
            };
        case ActionTypes.AddTaskToBeCompleted:
            return {
                ...state,
                taskIdsToComplete: [
                    ...state.taskIdsToComplete,
                    ...[action.payload],
                ],
            };
        case ActionTypes.AddTaskToBeUncompleted:
            return {
                ...state,
                taskIdsToUncomplete: [
                    ...state.taskIdsToUncomplete,
                    ...[action.payload],
                ],
            };
        case ActionTypes.RemoveTaskFromToBeCompleted:
            return {
                ...state,
                taskIdsToComplete: [
                    ...state.taskIdsToComplete.slice(
                        0,
                        state.taskIdsToComplete.indexOf(action.payload)
                    ),
                    ...state.taskIdsToComplete.slice(
                        state.taskIdsToComplete.indexOf(action.payload) + 1
                    ),
                ],
            };
        case ActionTypes.RemoveTaskFromToBeUncompleted:
            return {
                ...state,
                taskIdsToUncomplete: [
                    ...state.taskIdsToUncomplete.slice(
                        0,
                        state.taskIdsToUncomplete.indexOf(action.payload)
                    ),
                    ...state.taskIdsToUncomplete.slice(
                        state.taskIdsToUncomplete.indexOf(action.payload) + 1
                    ),
                ],
            };
        case ActionTypes.SetVisibleTasks:
            return {
                ...state,
                dropdownTasks: action.payload.miTasks,
                dropdownPersonalTasks: action.payload.personalTasks,
            };

        case ActionTypes.OpenAddTaskForm:
            return {
                ...state,
                showAddTaskForm: true,
                showEditTaskForm: false,
                taskFormData: null,

            };

        case ActionTypes.CloseAddTaskForm:
            return {
                ...state,
                showAddTaskForm: false,
                showEditTaskForm: false,
                taskFormErrors: [],
            };

        case ActionTypes.UpdateNewTask:
            const updatedTask = {
                ...state.taskFormData,
                ...{ [action.payload.type]: action.payload.value },
            };

            return {
                ...state,
                taskFormData: updatedTask,
            };

        case ActionTypes.CancelTaskForm:
            return {
                ...state,
                taskFormData: null,
                showAddTaskForm: false,
                showEditTaskForm: false,
                taskFormErrors: [],
            };

        case ActionTypes.AddNewTaskSuccess:
            return {
                ...state,
                taskFormData: null,
                showAddTaskForm: false,
                taskFormErrors: [],
            };

        case ActionTypes.AddNewTaskError:
            return {
                ...state,
                taskFormData: null,
                showAddTaskForm: false,
            };

        case ActionTypes.OpenEditTaskForm:
            return {
                ...state,
                taskFormData: action.payload,
                showAddTaskForm: false,
                showEditTaskForm: true,
                taskFormErrors: [],
            }

        case ActionTypes.SubmitEditPersonalTaskSuccess:
            return {
                ...state,
                taskFormData: null,
                showEditTaskForm: false,
                taskFormErrors: [],
            }

        case ActionTypes.AddTaskFormError:
            return {
                ...state,
                taskFormErrors: state.taskFormErrors.concat(action.payload),
            }

        case ActionTypes.LogOut: {
            return {
                ...state,
                tasks: '',
                taskIdsToComplete: [],
                taskIdsToUncomplete: [],
                dropdownTasks: [],
                dropdownPersonalTasks: [],
                checklistDropDownOpen: false,
                showAddTaskForm: false,
                showEditTaskForm: false,
            }
        }
        default:
            return state;
    }
};

export default TasksReducer;
