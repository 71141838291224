import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    isOpen: false,
};

const AcuityModalReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.OpenAcuityModal:
            return {
                ...state,
                isOpen: true,
            };

        case ActionTypes.CloseAcuityModal:
            return {
                ...state,
                isOpen: false,
            };

    default:
        return state;
    }
};

export default AcuityModalReducer;
