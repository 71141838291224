import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { NotificationsService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { getUserProfileAndSettings } from 'redux/sagas/selectors';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import Constants from 'Constants';
import Utils from 'utilities/Utils';

async function fetchNotifications(data) {
    try {
        return await _sendRequest(data, NotificationsService.getNotifications);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchSampleNotifications(data) {
    try {
        return await _sendRequest(
            data,
            NotificationsService.getSampleNotifications
        );
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function putViewNotification(data) {
    try {
        return await _sendRequest(data, NotificationsService.setViewedNotification);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* sampleNotificationsSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };
    const notificationsResponse = yield call(fetchSampleNotifications, data);
    if (Array.isArray(notificationsResponse)) {
        yield put({
            type: ActionTypes.GetSampleNotificationsSuccess,
            payload: notificationsResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetSampleNotificationsError,
        });
    }
}

export function* notificationsRequestSaga() {

    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };

    const notificationsResponse = yield call(fetchNotifications, data);
    if (Array.isArray(notificationsResponse)) {
        const sortedNotifications = Utils.sortNotifications(notificationsResponse);
        const unreadNotifications = Utils.sortUnreadNotifications(sortedNotifications);
        yield put({
            type: ActionTypes.GetNotificationsSuccess,
            payload: {
                notifications: sortedNotifications,
                unreadNotifications: unreadNotifications,
            },
        });
    } else {
        yield put({
            type: ActionTypes.GetNotificationsError,
        });
    }
}

export function* notificationsViewSaga(action) {
    const notification = action.payload
    const data = {
        notificationId: notification.notificationId,
    };
    const notificationsResponse = yield call(putViewNotification, data);
    if (!notificationsResponse && !notificationsResponse.errors && notificationsResponse.name !== 'ERROR') {
        yield put({
            type: ActionTypes.ViewNotificationSuccess,
            payload: notification,
        });
    } else {
        yield put({
            type: ActionTypes.ViewNotificationError,
            payload:
            notificationsResponse ||
                buildErrorObject({
                    message: Constants.global.errors.generic.fail,
                }),
        });
    }
}
