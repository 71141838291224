import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './FeedbackModal.scss';
import { useLocation, useHistory } from 'react-router-dom';
import Utils from 'utilities/Utils';
import Constants from 'Constants';

import {
    createSetFeedbackRatingAction,
    createCloseFeedbackModalAction,
    createSetFeedbackCommentsAction,
    createSubmitFeedbackAction,
    createSubmitFeedbackErrorAction,
    createHoverFeedbackRatingAction,
} from 'redux/actions/Feedback';
import {
    Icon,
    IconSizes,
    IconTypes,
    CloseButton,
    Error,
} from 'ComponentLibrary';
import {
    useCurrentLotId,
    useOutsideAlerter,
} from 'utilities/HooksAndSelectors';
import { FeedbackStates } from 'redux/reducers/Feedback';
import { CSSTransition } from 'react-transition-group';
import { isIOS } from 'react-device-detect';

const secondsInDay = 86400000;
const MAX_LENGTH = 1000;

const FeedbackModal = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const feedback = useSelector((reduxState) => reduxState.feedbackState);
    const userState = useSelector((reduxState) => reduxState.userState);
    const { userDetails } = userState;
    const { user } = userDetails;
    const { isOpen, rating, comments, state, errors, hoverRating } = feedback;

    const [canSubmitMoreFeedback, setCanSubmitMoreFeedback] = useState(true);
    const { sitecoreId } = user || { sitecoreId: null };
    const localStorageName = `feedback-submissions-${sitecoreId}`;
    let initialValue = [];
    try {
        initialValue = JSON.parse(
            Utils.getLocalStorage(localStorageName)
        );
    } catch (e) {
        // could not parse
        initialValue = [];
    }
    if (!initialValue) {
        initialValue = [];
    }
    const [submitted, setSubmitted] = useState(initialValue);
    if ((submitted.length >= 3 || initialValue.length >= 3) && canSubmitMoreFeedback) {
        setCanSubmitMoreFeedback(false);
    }

    const lotId = useCurrentLotId();
    const ref = useRef(null);
    const submissionsToIncludeWhenSet = [];
    useEffect(() => {
        let submissionsWithin24Hours = 0;
        submitted.forEach((submission) => {
            if (submission > new Date().getTime() - secondsInDay) {
                submissionsWithin24Hours++;
                submissionsToIncludeWhenSet.push(submission);
            }
        });
        if (submissionsWithin24Hours >= 3) {
            setCanSubmitMoreFeedback(false);
        }
    }, [submitted, submissionsToIncludeWhenSet]);

    const keyPress = (e) => {
        if (e.keyCode === 27) {
            window.location.hash = '';
            if (isOpen) {
                dispatch(createCloseFeedbackModalAction());
            }
        }
    };

    const windowClick = (e) => {
        const { x, y } = e;
        const { top, right, left, bottom } = e.target.getBoundingClientRect();
        if (x < left || x > right || y < top || y > bottom) {
            e.preventDefault();
            history.push(`${location.pathname}#`);
            dispatch(createCloseFeedbackModalAction());
        }

        return false;
    };

    useEffect(() => {
        if (isOpen && ref.current) {
            history.push(`${location.pathname}#feedback`);
            if (ref && ref.current && !ref.current.hasAttribute('open')) {
                try {
                    ref.current.showModal();
                } catch (e) { }
            }
            window.addEventListener('click', windowClick);

            return () => {
                window.removeEventListener('click', windowClick);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, ref.current]);
    useEffect(() => {
        window.addEventListener('keydown', keyPress);
        return () => {
            window.removeEventListener('keydown', keyPress);
        };
    });
    useOutsideAlerter(ref, (whatWasClicked) => {
        const parentIsContent = Utils.findParent(
            whatWasClicked,
            'feedback-modal'
        );

        if (parentIsContent === null) {
            history.push('/dashboard');
            dispatch(createCloseFeedbackModalAction());
        }
    });

    if (!isOpen) {
        return null;
    }

    const stars = new Array(5);
    stars.fill(1);

    const hasRatingError = errors && errors.fields && errors.fields.rating;
    const hasCommentsError = errors && errors.fields && errors.fields.comments;

    const formProps = {};
    if (state === FeedbackStates.Complete) {
        formProps['aria-hidden'] = 'true';
    }
    return (
        <dialog
            ref={isOpen ? ref : null}
            className={`feedback-modal ${isIOS && 'feedback-modal--ios'}`}
            data-testid="feedback-modal"
        >
            <CloseButton
                testId="feedback-modal__close"
                onClick={(e) => {
                    window.location.hash = '';
                    e.preventDefault();
                    dispatch(createCloseFeedbackModalAction());
                    return false;
                }}
            />
            <div
                className="feedback-modal__content-wrap"
            >
                {(state === FeedbackStates.Complete ||
                    !canSubmitMoreFeedback) && (
                        <CSSTransition
                            in={state === FeedbackStates.Complete}
                            appear={state === FeedbackStates.Complete}
                            timeout={200}
                            classNames="fade-in"
                        >
                            <div className="feedback-modal__success">
                                <Icon
                                    icon={IconTypes.Checkmark}
                                    color="white"
                                    size={IconSizes.ExtraLarge}
                                    circle="confirmation"
                                />
                                <h2 className="feedback-modal__headline">
                                    {Constants.feedbackModal.successTitle}
                                </h2>
                                <h3 className="feedback-modal__headline feedback-modal__headline--small">
                                    {Constants.feedbackModal.successBody}
                                </h3>
                            </div>
                        </CSSTransition>
                    )}

                <form
                    {...formProps}
                    data-testid="feedback-form"
                    className={`feedback-modal__form feedback-modal__form--${Utils.slugify(
                        state
                    )}`}
                    method="dialog"
                    onSubmit={(e) => {
                        e.preventDefault();
                        const errors = {
                            fields: {},
                            form: {},
                        };
                        let valid = true;
                        if (rating === 0) {
                            valid = false;
                            errors.fields.rating = {
                                message:
                                    ' Please select a star rating to continue.',
                            };
                        }
                        if (valid) {
                            const d = new Date();
                            submissionsToIncludeWhenSet.push(d.getTime());
                            Utils.setLocalStorage(
                                localStorageName,
                                JSON.stringify(submissionsToIncludeWhenSet)
                            );
                            setSubmitted(submissionsToIncludeWhenSet);
                            const fd = new FormData(e.currentTarget);
                            dispatch(
                                createSubmitFeedbackAction({
                                    rating: parseInt(fd.get('rating'), 10),
                                    lotId: parseInt(fd.get('lotId'), 10),
                                    comments: fd.get('comments'),
                                    browserWidth: window.innerWidth,
                                    userAgent: window.navigator.userAgent,
                                })
                            );
                        } else {
                            dispatch(createSubmitFeedbackErrorAction(errors));
                        }
                        return false;
                    }}
                >
                    <h1 className="feedback-modal__headline">
                        How helpful is M/I Homes Journey?
                    </h1>
                    <div className="field-wrap rating-stars">
                        <input
                            type="hidden"
                            value={rating}
                            name="rating"
                            id="rating"
                        />
                        <input
                            type="hidden"
                            value={lotId}
                            name="lotId"
                            id="lotId"
                        />
                        {stars.map((item, index) => {
                            const active = hoverRating === 0 ? index < rating : index < hoverRating;
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className={`feedback-star ${active && 'feedback-star--active'
                                        }`}
                                    data-testid="feedback-star"
                                    data-rating={index + 1}
                                    aria-label={`Set Rating To ${index + 1}`}
                                    onMouseLeave={(e) => {
                                        e.preventDefault()
                                        dispatch(
                                            createHoverFeedbackRatingAction(0)
                                        );

                                        return false;
                                    }}
                                    onMouseEnter={(e) => {
                                        e.preventDefault();
                                        dispatch(
                                            createHoverFeedbackRatingAction(
                                                parseInt(
                                                    e.currentTarget.getAttribute(
                                                        'data-rating'
                                                    ),
                                                    10
                                                )
                                            )
                                        );
                                        return false;
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(
                                            createSetFeedbackRatingAction(
                                                parseInt(
                                                    e.currentTarget.getAttribute(
                                                        'data-rating'
                                                    ),
                                                    10
                                                )
                                            )
                                        );
                                        return false;
                                    }}
                                >
                                    <Icon
                                        color={active ? 'tangerine' : 'casper'}
                                        icon={IconTypes.Star}
                                        size={IconSizes.Large}
                                    />
                                </button>
                            );
                        })}
                        {hasRatingError && (
                            <Error field center>{errors.fields.rating.message}</Error>
                        )}
                    </div>
                    <fieldset className="field-wrap ">
                        <label className="visuallyhidden">
                            Any suggestions to improve the app?{' '}
                        </label>
                        <div className="input-wrap input-wrap--textarea">
                            <textarea
                                id="comments"
                                name="comments"
                                data-testid="feedback-comments"
                                maxLength={MAX_LENGTH}
                                onChange={(e) => {
                                    dispatch(
                                        createSetFeedbackCommentsAction(
                                            e.currentTarget.value.substr(0, 1000)
                                        )
                                    );
                                    if (
                                        e.currentTarget.value.length >=
                                        MAX_LENGTH
                                    ) {
                                        const errors = {
                                            fields: {},
                                            form: {},
                                        };
                                        errors.fields.comments = {
                                            message: `Your response is limited to ${MAX_LENGTH} characters.`,
                                        };
                                        dispatch(
                                            createSubmitFeedbackErrorAction(errors)
                                        );
                                    } else {
                                        dispatch(
                                            createSubmitFeedbackErrorAction(null)
                                        );
                                    }
                                }}
                                value={comments}
                                className={`input input--text input--textarea comments-box comments-box--no-size comments-box--with-char-count`}
                                placeholder="Any suggestions to improve the app?"
                            />
                            <div className="comment-counter">
                                {comments.length}/{MAX_LENGTH}
                            </div>
                        </div>
                        {hasCommentsError && (
                            <Error field>
                                {errors.fields.comments.message}
                            </Error>
                        )}
                    </fieldset>
                    {errors && errors.form && errors.form[0] && (
                        <Error>{errors.form[0].message}</Error>
                    )}
                    <input
                        type="submit"
                        data-testid="feedback-modal__submit"
                        className={`button primary-button ${state === FeedbackStates.Busy && 'disabled button--disabled primary-button--disabled'}`}
                        value="Submit feedback"
                    />
                </form>
            </div>
        </dialog>
    );
};
FeedbackModal.defaultProps = {};
FeedbackModal.propTypes = {};

export default FeedbackModal;
