const assignCategories = function(categories) {
    let primary = [];
    let secondary = [];

    categories.forEach((category) => {
        return category.group === 'Primary'
            ? primary.push(category)
            : secondary.push(category);
    });

    return {
        primary,
        secondary,
    };
};

export { assignCategories };
