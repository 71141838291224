import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'ComponentLibrary';
import Constants from 'Constants';
import MessageBoardSkeleton from './MessageBoardSkeleton';

import './MessageBoard.scss';

const parseCurrentProgressPhase = (userState) => {
    if (!!userState) {
        const progressContent = userState.progressContent;
        if (!!progressContent) {
            const lotKey = Object.keys(progressContent.lots)[0];
            if (!!lotKey) {
                const currentLot = progressContent.lots[lotKey];
                if (!!currentLot && !!currentLot.progress && Array.isArray(currentLot.progress)) {
                    const allStarted = currentLot.progress.map((element, index) => { return { element, index } }).filter(item => item.element && item.element.status === 'Started');
                    const allComplete = currentLot.progress.map((element, index) => { return { element, index } }).filter(item => item.element && item.element.status === 'Complete');
                    if (allComplete.length === (currentLot.progress.length - 1) && currentLot.progress[currentLot.progress.length - 1] !== 'Complete') {
                        return currentLot.progress[currentLot.progress.length - 1].title;
                    }
                    const lastStartedPhase = allStarted && allStarted.sort((a, b) => a.index < b.index ? -1 : 1)[0];
                    return (lastStartedPhase && lastStartedPhase.element.title) || null;
                }
            }
        }
    }
    return null;
};

const Content = (props) => {
    const { title, message } = props;
    if (!title || !message) {
        return null;
    }
    return (
        <React.Fragment>
            <Text
                variant="regular"
                component="h1">
                {title}
            </Text>
            <Text
                variant="small"
                semibold
                component='span'>
                {message}
            </Text>
        </React.Fragment>
    );
};

const MessageBoard = (props) => {
    const { skeletonMode } = props;
    const userState = useSelector((state) => state.userState);
    const { firstName } = userState.userDetails.accountProfile;
    const currentProgressPhase = parseCurrentProgressPhase(userState);
    const title = Constants.dashboard.messageBoard.title(firstName);
    const message = Constants.dashboard.messageBoard.message(currentProgressPhase);
    if ((!message || !title) && !skeletonMode) return null;
    return (
        <section className={`${skeletonMode ? 'skeleton': ''} messageboard-container`}>
            {skeletonMode ? (
                <MessageBoardSkeleton />
            ) : (
                <Content title={title} message={message} />
            )}
        </section>
    );
}
export default MessageBoard;
