// This file defines all constant strings for the actions, used in action creators, reducers, & sagas
// Using this will hopefully prevent typos in the strings

const ActionTypes = {
  UpdateLotSearchParam: 'UPDATE_LOT_SEARCH_PARAM',
  ClearSearchResults: 'CLEAR_LOTR_SEARCH_RESULTS',
  SortLotSearchResults: 'SORT_LOT_SEARCH_RESULTS',
  SearchForLotByAddress: 'SEARCH_FOR_LOT_BY_ADDRESS',
  SearchForLotByAddressResults: 'SEARCH_FOR_LOT_BY_ADDRESS_RESULTS',
  SearchForLotByNameResults: 'SEARCH_FOR_LOT_BY_NAME_RESULTS',
  SearchForLotByNameError: 'SEARCH_FOR_LOT_BY_NAME_ERROR',
  SearchForLotByName: 'SEARCH_FOR_LOT_BY_NAME',
  GetCommunities: 'GET_COMMUNITIES',
  GetDivisions: 'GET_DIVISIONS',
  GetCommunitiesSuccess: 'GET_COMMUNITIES_SUCCESS',
  GetDivisionsSuccess: 'GET_DIVISIONS_SUCCESS',
  GetCommunitiesError: 'GET_COMMUNITIES_ERROR',
  GetDivisionsError: 'GET_DIVISIONS_ERROR',
  OpenShareModal: 'OPEN_SHARE_MODAL',
  CloseShareModal: 'CLOSE_SHARE_MODAL',
  GoToAddLot: 'GO_TO_ADD_LOT',
  CancelAddLot: 'CANCEL_ADD_LOT',
  ADOLogin: 'ADO_LOGIN',
  FeedbackHoverRating: 'FEEDBACK_HOVER_RATING',
  AdminLoginRequest: 'ADO_LOGIN_REQUEST',
  GetLotConfiguration: 'GET_LOT_CONFIGURATION',
  GetLotConfigurationRequest: 'GET_LOT_CONFIGURATION_REQUEST',
  GetLotConfigurationSuccess: 'GET_LOT_CONFIGURATION_SUCCESS',
  GetLotConfigurationError: 'GET_LOT_CONFIGURATION_ERROR',
  GetProgressContent: 'GET_PROGRESS_CONTENT',
  GetProgressContentError: 'GET_PROGRESS_CONTENT_ERROR',
  GetContactForPhase: 'GET_CONTACT_FOR_PHASE',
  ClearLotErrors: 'CLEAR_LOT_ERRORS',
  AppIsInstallable: 'APP_IS_INSTALLABLE',
  GetContactForPhaseSuccess: 'GET_CONTACT_FOR_PHASE_SUCCESS',
  GetContactForPhaseError: 'GET_CONTACT_FOR_PHASE_ERROR',
  GetContactForPhaseRequest: 'GET_CONTACT_FOR_PHASE_REQUEST',
  OpenUserProfileDropdown: 'OPEN_USER_PROFILE_DROPDOWN',
  CloseUserProfileDropdown: 'CLOSE_USER_PROFILE_DROPDOWN',
  ChangeActiveLot: 'CHANGE_ACTIVE_LOT',
  LogOut: 'LOGOUT',
  AddLotId: 'ADD_LOT_ID',
  LotDeletionFailure: 'DELETE_LOT_FAILURE',
  LotDeletionSuccess: 'DELETE_LOT_SUCCESS',
  GetLotById: 'GET_LOT_BY_ID',
  GetLotByIdRequest: 'GET_LOT_BY_ID_REQUEST',
  GetLotByIdSuccess: 'GET_LOT_BY_ID_SUCCESS',
  GetLotByIdError: 'GET_LOT_BY_ID_ERROR',
  UpdateLotId: 'UPDATE_LOT_ID',
  FetchLotToolTipContent: 'LOAD_LOT_TOOLTIP',
  Login: 'LOGIN',
  LoginSuccess: 'LOGIN_SUCCESS',
  LoginRequest: 'LOGIN_REQUEST',
  UpdateUserDetails: 'UPDATE_USER_DETAILS',
  CreateTokenRefresh: 'REFRESH_USER_FROM_TOKEN',
  HandleTokenRefreshSuccess: 'UPDATE_USER_TOKEN',
  HandleTokenRefreshError: 'TOKEN_REFRESH_ERROR',
  HandleApiFatalError: 'API_FATAL_ERROR',
  HandleRedirectForbiddenError: 'REDIRECT_FORBIDDEN',
  StartExternalRedirect: 'START_EXTERNAL_REDIRECT',
  LoginError: 'LOGIN_ERROR',
  LogoutSuccess: 'LOGOUT_SUCCESS',
  LogoutError: 'LOGOUT_ERROR',
  RefreshUserRequest: 'REFRESH_USER_FROM_TOKEN_REQUEST',
  RefreshUserError: 'REFRESH_USER_FROM_TOKEN_ERROR',
  RefreshUserSuccess: 'REFRESH_USER_FROM_TOKEN_SUCCESS',
  FetchExternalRedirectSuccess: 'FETCH_EXTERNAL_REDIRECT_SUCCESS',
  FetchExternalRedirectError: 'FETCH_EXTERNAL_REDIRECT_ERROR',
  FetchFinancingExternalRedirectError:
    'FETCH_FINANCING_EXTERNAL_REDIRECT_ERROR',
  FetchExternalRedirectNoUrl: 'FETCH_EXTERNAL_REDIRECT_NO_URL',
  FetchWellsFargoExternalRedirectError: 'FETCH_WELLS_FARGO_ERROR',
  AddLotIdRequest: 'ADD_LOTID_REQUEST',
  LotIdSuccess: 'LOTID_SUCCESS',
  LotIdError: 'LOTID_ERROR',
  LotTooltipRequest: 'LOT_TOOLTIP_CONTENT_REQUEST',
  LotTooltipSuccess: 'LOT_TOOLTIP_CONTENT_SUCCESS',
  LoginBannerContentSuccess: 'LOGIN_BANNER_CONTENT_SUCCESS',
  LotTooltipError: 'LOT_TOOLTIP_CONTENT_ERROR',
  ApiFatalError: 'API_FATAL_ERROR',
  GetProgressContentLotContent: 'GET_PROGRESS_CONTENT_LOT_CONTENT',
  GetProgressContentLotDetails: 'GET_PROGRESS_CONTENT_LOT_DETAILS',
  RedirectForbidden: 'REDIRECT_FORBIDDEN',
  RedirectError404: 'REDIRECT_ERROR_404',
  SignOut: 'SIGN_OUT',
  TriggerChecklistModal: 'TRIGGER_CHECKLIST_MODAL',
  CloseChecklistModal: 'CLOSE_CHECKLIST_MODAL',
  OpenChecklistModal: 'OPEN_CHECKLIST_MODAL',
  GetTasks: 'GET_TASKS',
  GetDivisionTasks: 'GET_DIVISION_TASKS',
  GetTasksSuccess: 'GET_TASKS_SUCCESS',
  GetDivisionTasksSuccess: 'GET_DIVISION_TASKS_SUCCESS',
  GetTasksError: 'GET_TASKS_ERROR',
  GetDivisionTasksError: 'GET_DIVISION_TASKS_ERROR',
  CompleteTask: 'COMPLETE_TASK',
  OpenChecklistDropdown: 'OPEN_CHECKLIST_DROPDOWN',
  CloseChecklistDropdown: 'CLOSE_CHECKLIST_DROPDOWN',
  AddTaskToBeCompleted: 'ADD_TASK_TO_BE_COMPLETED',
  RemoveTaskFromToBeCompleted: 'REMOVE_TASK_FROM_TO_BE_COMPLETED',
  AddTaskToBeUncompleted: 'ADD_TASK_TO_BE_UNCOMPLETED',
  RemoveTaskFromToBeUncompleted: 'REMOVE_TASK_FROM_TO_BE_UNCOMPLETED',
  SetVisibleTasks: 'SET_VISIBLE_TASKS',
  UpdateTasksSuccess: 'UPDATE_TASKS_SUCCESS',
  UpdateTasksError: 'UPDATE_TASKS_ERROR',
  OpenAddTaskForm: 'OPEN_ADD_TASK_FORM',
  CloseAddTaskForm: 'CLOSE_ADD_TASK_FORM',
  UpdateNewTask: 'UPDATE_NEW_TASK',
  CancelTaskForm: 'CANCEL_TASK_FORM',
  GetPersonalTasks: 'GET_PERSONAL_TASKS',
  AddNewTask: 'ADD_NEW_TASK',
  AddNewTaskSuccess: 'ADD_NEW_TASK_SUCCESS',
  AddNewTaskError: 'ADD_NEW_TASK_ERROR',
  RemovePersonalTask: 'REMOVE_PERSONAL_TASK',
  RemovePersonalTaskSuccess: 'REMOVE_PERSONAL_TASK_SUCCESS',
  RemovePersonalTaskError: 'REMOVE_PERSONAL_TASK_ERROR',
  OpenEditTaskForm: 'OPEN_EDIT_TASK_FORM',
  SubmitEditPersonalTask: 'SUBMIT_EDIT_PERSONAL_TASK',
  SubmitEditPersonalTaskSuccess: 'SUBMIT_EDIT_PERSONAL_TASK_SUCCESS',
  SubmitEditPersonalTaskError: 'SUBMIT_EDIT_PERSONAL_TASK_ERROR',
  AddTaskFormError: 'ADD_TASK_FORM_ERROR',
  OpenPersonalTasks: 'OPEN_PERSONAL_TASKS',
  ClosePersonalTasks: 'CLOSE_PERSONAL_TASKS',
  OpenVideoModal: 'OPEN_VIDEO_MODAL',
  CloseVideoModal: 'CLOSE_VIDEO_MODAL',
  OpenContactBioModal: 'OPEN_CONTACT_BIO_MODAL',
  CloseContactBioModal: 'CLOSE_CONTACT_BIO_MODAL',
  SaveUsersDestinationForAfterLogin: 'SAVE_USERS_DESTINATION_AFTER_LOGIN',
  GetSampleNotifications: 'GET_SAMPLE_NOTIFICATIONS',
  GetSampleNotificationsSuccess: 'GET_SAMPLE_NOTIFICATIONS_SUCCESS',
  GetSampleNotificationsError: 'GET_SAMPLE_NOTIFICATIONS_ERROR',
  GetNotifications: 'GET_NOTIFICATIONS',
  GetNotificationsSuccess: 'GET_NOTIFICATIONS_SUCCESS',
  GetNotificationsError: 'GET_NOTIFICATIONS_ERROR',
  OpenNotificationsDropdown: 'OPEN_NOTIFICATONS_DROPDOWN',
  CloseNotificationsDropdown: 'CLOSE_NOTIFICATIONS_DROPDOWN',
  ViewNotification: 'VIEW_NOTIFICATION',
  ViewNotificationSuccess: 'VIEW_NOTIFICATION_SUCCESS',
  ViewNotificationError: 'VIEW_NOTIFICATION_ERROR',
  SetOpenNotification: 'SET_OPEN_NOTIFICATION',
  OpenFindLotPage: 'OPEN_FIND_LOT_PAGE',
  CloseFindLotPage: 'CLOSE_FIND_LOT_PAGE',
  NotificationPreferences: 'NOTIFICATION_PREFERENCES',
  NotificationPrefSuccess: 'NOTIFICATION_PREFERENCES_SUCCESS',
  NotificationPrefDeletePushSubscription:
    'NOTIFICATION_PREFERENCES_DELETE_PUSH_SUBSCRIPTION',
  NotificationPrefUpdateState: 'NOTIFICATION_PREFERENCES_UPDATE_STATE',
  NotificationPrefError: 'NOTIFICATION_PREFERENCES_ERROR',
  OpenPhotoModal: 'OPEN_PHOTO_MODAL',
  ClosePhotoModal: 'CLOSE_PHOTO_MODAL',
  SetPhotoModalPageNumber: 'SET_PHOTO_MODAL_PAGE_NUMBER',
  SetPhotoModalCaption: 'SET_PHOTO_MODAL_CAPTION',
  HasPushNotification: 'HAS_PUSH_NOTIFICATION',
  SavePushSubscriptionToState: 'SAVE_PUSH_SUBSCRIPTION_TO_STATE',
  ShowFeedbackModal: 'SHOW_FEEDBACK_MODAL',
  CloseFeedbackModal: 'CLOSE_FEEDBACK_MODAL',
  SetFeedbackRating: 'SET_FEEDBACK_RATING',
  SetFeedbackComments: 'SET_FEEDBACK_COMMENTS',
  SubmitFeedbackForm: 'SUBMIT_FEEDBACK_FORM',
  SubmitFeedbackFormError: 'SUBMIT_FEEDBACK_FORM_ERROR',
  SubmitFeedbackFormSuccess: 'SUBMIT_FEEDBACK_FORM_SUCCESS',
  GetContacts: 'GET_CONTACTS',
  GetContactsSuccess: 'GET_CONTACTS_SUCCESS',
  GetContactsError: 'GET_CONTACTS_ERROR',
  UpdateWarrantyPageStatus: 'UPDATE_WARRANTY_PAGE_STATUS',
  GetCategories: 'GET_CATEGORIES',
  GetCategoriesSuccess: 'GET_CATEGORIES_SUCCESS',
  GetCategoriesError: 'GET_CATEGORIES_ERROR',
  SetCategories: 'SET_CATEGORIES',
  SetOpenClaim: 'SET_OPEN_CLAIM',
  SetActiveCategory: 'SET_ACTIVE_CATEGORY',
  RemoveActiveCategory: 'REMOVE_ACTIVE_CATEGORY',
  SetClaimData: 'SET_CLAIM_DATA',
  SetActiveClaim: 'SET_ACTIVE_CLAIM',
  RemoveActiveClaim: 'REMOVE_ACTIVE_CLAIM',
  AddClaim: 'ADD_CLAIM',
  RemoveClaim: 'REMOVE_CLAIM',
  UpdateClaimDetails: 'UPDATE_CLAIM_DETAILS',
  SetClaimWithError: 'SET_CLAIM_WITH_ERROR',
  UpdateWarrantyPageStatusFromReview: 'UPDATE_WARRANTY_PAGE_STATUS_FROM_REVIEW',
  ToggleHamburger: 'TOGGLE_HAMBURGER',
  OpenHamburger: 'OPEN_HAMBURGER',
  CloseHamburger: 'CLOSE_HAMBURGER',
  SaveClaimDataToFile: 'SAVE_WARRANTY_CLAIM_TO_FILE',
  SaveClaimDataToFileSuccess: 'SAVE_WARRANTY_CLAIM_TO_FILE_SUCCESS',
  SaveClaimDataToFileError: 'SAVE_WARRANTY_CLAIM_TO_FILE_ERROR',
  LoadClaimDataFromFile: 'LOAD_WARRANTY_CLAIM_FROM_FILE',
  LoadClaimsActiveCategoriesFromFile:
    'LOAD_WARRANTY_CLAIM_ACTIVE_CATEGORIES_FROM_FILE',
  SubmitServiceItem: 'SUBMIT_SERVICE_ITEM',
  HideClaimDraftModal: 'HIDE_CLAIM_DRAFT_MODAL',
  RemoveExistingClaimsData: 'REMOVE_EXISTING_CLAIMS_DATA',
  RemoveExistingClaimsDataSuccess: 'REMOVE_EXISTING_CLAIMS_DATA_SUCCESS',
  ServiceItemSubmitSuccess: 'SERVICE_ITEM_SUBMIT_SUCCESS',
  ServiceItemSubmitError: 'SERVICE_ITEM_SUBMIT_ERROR',
  ServiceItemSubmitRequest: 'SERVICE_ITEM_SUBMIT_REQUEST',
  ServiceItemBatchSubmitSuccess: 'SERVICE_ITEM_BATCH_SUBMIT_SUCCESS',
  SuccessfulWarrantySubmission: 'SUBMIT_WARRANTY_SUCCESS',
  ServiceRequestIdAskedFor: 'GET_WARRANTY_SERVICE_REQUEST_ID',
  GetServiceRequestIdSuccess: 'GET_WARRANTY_SERVICE_REQUEST_ID_SUCCESS',
  ServiceRequestCompleted: 'ENTIRE_SERVICE_REQUEST_COMPLETE',
  CloseClaimCancelModal: 'CLOSE_CLAIM_CANCEL_MODAL',
  OpenClaimCancelModal: 'OPEN_CLAIM_CANCEL_MODAL',
  ResetAfterSubmitSuccess: 'RESET_AFTER_SUBMIT_SUCCESS',
  GetDocuments: 'GET_DOCUMENTS',
  GetDocumentsSuccess: 'GET_DOCUMENTS_SUCCESS',
  GetDocumentsError: 'GET_DOCUMENTS_ERROR',
  GetSingleDocument: 'GET_SINGLE_DOCUMENT',
  GetSingleDocumentSuccess: 'GET_SINGLE_DOCUMENT_SUCCESS',
  GetSingleDocumentError: 'GET_SINGLE_DOCUMENT_ERROR',
  SetDocumentLink: 'SET_DOCUMENT_LINK',
  SortDocuments: 'SORT_DOCUMENTS',
  GetDeposits: 'GET_DEPOSITS',
  GetDepositsSuccess: 'GET_DEPOSITS_SUCCESS',
  GetDepositsError: 'GET_DEPOSITS_ERROR',
  SortScheduled: 'SORT_SCHEDULED',
  SortProcessed: 'SORT_PROCESSED',
  GetDivisionContacts: 'GET_DIVISION_CONTACTS',
  GetDivisionContactsError: 'GET_DIVISION_CONTACTS_ERROR',
  GetDivisionContactsSuccess: 'GET_DIVISION_CONTACTS_SUCCESS',
  LaunchFilterPhotosModal: 'LAUNCH_FILTER_PHOTOS_MODAL',
  CloseFilterPhotosModal: 'CLOSE_FILTER_PHOTOS_MODAL',
  ToggleSelectForDownloadeMode: 'TOGGLE_SELECT_FOR_DOWNLOAD_MODE',
  TogglePhotoInDownloadListAction: 'TOGGLE_PHOTO_IN_DOWNLOAD_LIST',
  OpenAcuityModal: 'OPEN_ACUTIY_MODAL',
  CloseAcuityModal: 'CLOSE_ACUITY_MODAL',
  ExitSelectForDownloadeMode: 'EXIST_PHOTO_DOWNLOAD_SELECT_MODE',
  MarkAllPhotosForDownload: 'MARK_ALL_PHOTOS_FOR_DOWNLOAD',
  GetDownloadPhotosUrl: 'GET_DOWNLOAD_PHOTOS_URL',
  GetDownloadPhotosUrlSuccess: 'GET_DOWNLOAD_PHOTOS_URL_SUCCESS',
  GetDownloadPhotosUrlError: 'GET_DOWNLOAD_PHOTOS_URL_ERROR',
  ClearPhotoDownloadData: 'CLEAR_PHOTO_DOWNLOAD_DATA',
  EnterRemoveLotMode: 'ENTER_REMOVE_LOT_MODE',
  ExitRemoveLotMode: 'EXIT_REMOVE_LOT_MODE',
  DeleteLotAssociation: 'MARK_LOT_FOR_DELETION',
  ConfirmLotDeletion: 'DELETE_LOT',
  GetDesignSelections: 'GET_DESIGN_SELECTIONS',
  GetDesignSelectionsSuccess: 'GET_DESIGN_SELECTIONS_SUCCESS',
  GetDesignSelectionsError: 'GET_DESIGN_SELECTIONS_ERROR',
  SortDesignSelections: 'SORT_DESIGN_SELECTIONS',
  ShowDesignSelectionsModal: 'SHOW_DESIGN_SELECTIONS_MODAL',
  HideDesignSelectionsModal: 'HIDE_DESIGN_SELECTIONS_MODAL',
  UpdateDesignSelectionsTableFilter: 'UPDATE_DESIGN_SELECTIONS_TABLE_FILTER',
  GetDesignSelectionsItemData: 'GET_DESIGN_SELECTIONS_ITEM_DATA',
  GetDesignSelectionsItemDataSuccess: 'GET_DESIGN_SELECTIONS_ITEM_DATA_SUCCESS',
  GetDesignSelectionsItemDataError: 'GET_DESIGN_SELECTIONS_ITEM_DATA_ERROR',
  SetItemProductModalSubProduct: 'SET_ITEM_PRODUCT_MODAL_SUB_PRODUCT',
  GetAssociatedBuyers: 'GET_ASSOCIATED_BUYERS',
  GetAssociatedBuyersSuccess: 'GET_ASSOCIATED_BUYERS_SUCCESS',
  GetAssociatedBuyersError: 'GET_ASSOCIATED_BUYERS_ERROR',
  ClearLotsForTesting: 'CLEAR_LOTS_FOR_TESTING',
  CheckInviteToken: 'CHECK_INVITE_TOKEN',
  InviteTokenValid: 'INVITE_TOKEN_VALID',
  InviteTokenInvalid: 'INVITE_TOKEN_INVALID',
  NoPassResetNeeded: 'NO_PASS_RESET_NEEDED',
  SubmitPassword: 'SUBMIT_PASSWORD',
  SubmitPasswordSuccess: 'SUBMIT_PASSWORD_SUCCESS',
  SubmitPasswordError: 'SUBMIT_PASSWORD_ERROR',
  // new stuff added for accepting/paying deposits
  ShowPaymentConfirmationModal: 'SHOW_PAYMENT_CONFIRMATION_MODAL',
  ClosePaymentConfirmationModal: 'CLOSE_PAYMENT_CONFIRMATION_MODAL',
};

export default ActionTypes;
