import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import {
    Icon,
    IconTypes,
    Error,
    Tooltip,
} from 'ComponentLibrary';

const wrapperBase = 'input-wrap--';
const labelBase = 'label--';

export const InputModifiers = {
    Wrapper: { NoVerticalMargin: `${wrapperBase}no-vertical-margin` },
    Label: { NoVerticalMargin: `${labelBase}no-vertical-margin` },
};

const Input = (props) => {
    const {
        force,
        autofocus,
        extraFieldWrapClasses,
        animated,
        label,
        first,
        pattern,
        id,
        name,
        type,
        required,
        extraClasses,
        extraInputClasses,
        extraCheckboxClasses,
        extraLabelClasses,
        extraLabelTextClasses,
        extraCheckboxIndicatorClasses,
        checked,
        onChange,
        minLength,
        maxLength,
        placeholder,
        errors,
        autocomplete,
        testId,
        initialValue,
        disabled,
        readOnly = false,
        tooltip,
        tooltipIcon,
        tooltipSize,
        tooltipInverted,
        content,
    } = props;

    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState(initialValue);

    const inputTestIdVal = testId ? { 'data-testid': `input-${testId}` } : null;
    const labelTestIdVal = testId ? { 'data-testid': `label-${testId}` } : null;
    const errorTestIdVal = testId ? { 'data-testid': `error-${testId}` } : null;
    const [isChecked, setIsChecked] = useState(checked);
    const showErrors = () => {
        if (!errors || errors.length === 0) return null;
        return (
            <div id={`${id}-errors`} {...errorTestIdVal}>
                {errors.map((error, index) => {
                    const errorTestId = testId
                        ? `error-${testId}-${index}`
                        : null;
                    return (
                        <Error
                            key={`${id}-error-${index}`}
                            testId={errorTestId}
                            field
                        >
                            {error.message}
                        </Error>
                    );
                })}
            </div>
        );
    };

    const describedby =
        errors && errors.length > 0
            ? { 'aria-describedby': `${id}-errors` }
            : null;
    const autoComplete = autocomplete ? { autoComplete: autocomplete } : null;
    const checkValue =
        type === 'checkbox' && (isChecked || checked)
            ? 'checked'
            : type === 'radio'
                ? checked
                : '';
    if (force && isChecked !== checked) {
        setIsChecked(checked);
    }
    const input = (
        <input
            checked={checkValue}
            id={id}
            autoFocus={autofocus ? 'autofocus' : null}
            {...inputTestIdVal}
            type={type === 'password' && showPassword ? 'text' : type}
            pattern={pattern}
            minLength={minLength}
            maxLength={maxLength}
            required={required ? true : null}
            className={`input input--${type} ${
                errors && errors.length > 0 ? 'input--error' : ''
            } input ${extraInputClasses}`}
            placeholder={placeholder}
            {...describedby}
            {...autoComplete}
            name={name}
            readOnly={readOnly}
            value={maxLength && value ? value.slice(0, maxLength) : value}
            disabled={disabled ? 'disabled' : null}
            onChange={(e) => {
                setValue(e.currentTarget.value);
                setIsChecked(!isChecked);
                if (onChange) {
                    onChange(e);
                }
            }}
        />
    );
    return (
        <div className={`field-wrap ${extraFieldWrapClasses} ${first && 'field-wrap--first'}`}>
            {type !== 'checkbox' && type !== 'radio' && (
                <label
                    {...labelTestIdVal}
                    htmlFor={id}
                    className={`label label--${type} ${extraLabelClasses}`}
                >
                    {label}
                    {tooltip && (
                        <Tooltip icon={tooltipIcon} content={content} />
                    )}
                </label>
            )}
            <div className={`input-wrap input-wrap--${type} ${extraClasses} `}>
                {(type === 'checkbox' || type === 'radio') && (
                    <>
                        <label
                            {...labelTestIdVal}
                            htmlFor={id}
                            className={`label label--${type} ${disabled && 'label--disabled'} ${extraLabelClasses}`}
                        >
                            <span
                                className={`checkbox ${
                                    type === 'radio' ? 'checkbox--radio' : ''
                                } ${extraCheckboxClasses}`}
                            >
                                {input}
                                <span
                                    className={`checkbox__indicator ${extraCheckboxIndicatorClasses} ${
                                        type === 'radio'
                                            ? 'checkbox__indicator--radio'
                                            : ''
                                    }`}
                                >
                                    {type === 'checkbox' && (
                                        <Icon
                                            color={'white'}
                                            icon={IconTypes.Checkmark}
                                            size={animated ? 1 : 1.1}
                                        />
                                    )}
                                </span>
                            </span>
                            <span className={extraLabelTextClasses}>{label}</span>
                        </label>
                        {tooltip && (
                            <Tooltip
                                size={tooltipSize}
                                icon={tooltipIcon}
                                content={content}
                                inverted={tooltipInverted}
                            />
                        )}
                    </>
                )}
                {type !== 'checkbox' && type !== 'radio' && input}
                {type === 'password' && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowPassword(!showPassword);
                            return false;
                        }}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                )}
            </div>
            {errors && showErrors()}
        </div>
    );
};

Input.defaultProps = {
    autocomplete: null,
    checked: false,
    required: true,
    type: 'text',
    extraClasses: '',
    extraInputClasses: '',
    extraCheckboxClasses: '',
    extraLabelClasses: '',
    pattern: null,
    maxLength: null,
    minLength: null,
    errors: null,
    testId: null,
    onChange: null,
    initialValue: '',
    tooltipSize: 1.25,
    first: false,
    extraLabelTextClasses: null,
    extraCheckboxIndicatorClasses: null,
    animated: false,
    extraFieldWrapClasses: null,
    force: false,
    tooltipInverted: true,
};

Input.propTypes = {
    first: PropTypes.bool,
    tooltipSize: PropTypes.number,
    autocomplete: PropTypes.string,
    pattern: PropTypes.string,
    onChange: PropTypes.func,
    extraLabelClasses: PropTypes.string,
    extraClasses: PropTypes.string,
    extraCheckboxClasses: PropTypes.string,
    extraInputClasses: PropTypes.string,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    errors: PropTypes.array,
    testId: PropTypes.string,
    minLength: PropTypes.string,
    maxLength: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    initialValue: PropTypes.string,
    extraCheckboxIndicatorClasses: PropTypes.string,
    extraLabelTextClasses: PropTypes.string,
    animated: PropTypes.bool,
    extraFieldWrapClasses: PropTypes.string,
    force: PropTypes.bool,
    tooltipInverted: PropTypes.bool,
};

export default Input;
