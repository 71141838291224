import ActionTypes from 'redux/actions/ActionTypes';
const initialState = {
    isOpen: false,
};

const HamburgerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.OpenHamburger:
            return {
                isOpen: true,
            };
        case ActionTypes.CloseHamburger:
            return {
                isOpen: false,
            };
        case ActionTypes.ToggleHamburger:
            return {
                isOpen: !state.isOpen,
            }
        default:
            return state;
    }
};
export default HamburgerReducer;
