import React from 'react';
import { Text } from 'ComponentLibrary';
import Constants from 'Constants';
import Link from './Link';

const PrivacyPolicyLink = () => {
    return (
        <Text type="inline" variant="small">
            <Link
                eventLabel={Constants.global.links.privacyPolicy.eventLabel}
                to={Constants.global.links.privacyPolicy.link}
                blank>
                {Constants.global.links.privacyPolicy.label}
            </Link>
        </Text>
    )
};

export default PrivacyPolicyLink;