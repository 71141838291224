import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import { ContactService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchDivisionContacts(lotId) {
    try {
        return await _sendRequest(lotId, ContactService.getDivisionContacts);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* getDivisionContactsSaga(lotId) {
    const contactsResponse = yield call(fetchDivisionContacts, lotId);
    if (contactsResponse && Array.isArray(contactsResponse)) {
        yield put({
            type: ActionTypes.GetDivisionContactsSuccess,
            payload: contactsResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetDivisionContactsError
        });
    }
}
