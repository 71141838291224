import ApiService from './ApiService.js';

const withCredentials = (options) => { return { withCredentials: true, ...options }; }

const AuthService = {
    adminLogin: (token, service = ApiService()) => service.postWithPassedToken('/auth/employee/login', token)
        .then(response => response)
        .catch(error => error.response.data),
    login: (options, service = ApiService()) => service.post('/auth/login', options)
        .then(response => response)
        .catch(error => error.response.data),
    logout: (options, service = ApiService()) => service.post('/Auth/Logout', withCredentials(options), {withCredentials: true})
        .then(response => response)
        .catch(error => error.response),
    refresh: (options = { timeout: 5000 }, service = ApiService()) => service.get('/Auth/Refresh', null, true)
        .then(response => response)
        .catch(error => error.response),
    getInviteTokenStatus: (options, service = ApiService()) => service
        .post('/Auth/invites/verify', options)
        .then((response) => response)
        .catch((error) => error.response),
    changePassword: (options, service = ApiService()) => service
        .putWithCreds('/Auth/changepassword', options)
        .then((response) => response)
        .catch((error) => error.response),
};

export default AuthService;
