import { Event } from 'components/Analytics';

let deferredPrompt = null;
window.addEventListener('beforeinstallprompt', (e) => {
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
});

const installApp = () => {
    if (deferredPrompt) {
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            deferredPrompt = null;

            if (choiceResult.outcome !== 'dismissed') {
                Event('app', 'installed');
            }
            document.body.classList.remove('body--noscroll');
        });
    }
};

export default installApp;
