import ActionTypes from 'redux/actions/ActionTypes';

export function createOpenVideoModalAction(videoLink, title) {
    return {
        type: ActionTypes.OpenVideoModal,
        payload: { videoLink, title },
    };
}

export function createCloseVideoModalAction() {
    return {
        type: ActionTypes.CloseVideoModal,
    };
}
