import axios from 'axios';
import store from 'redux/configureStore';

const apiRoot = window._env_.REACT_APP_API_ROOT;
const withBearerTokenHeader = (options = {}, returnString = false, withCreds = false) => {
    const state = store.getState();
    if (state) {
        const { userState } = store.getState();
        if (userState) {
            const { accessToken } = userState.userDetails;
            if (accessToken) {
                if (returnString) {
                    return `Bearer ${accessToken}`
                }
                if (withCreds) {
                    return {
                        headers: { Authorization: `Bearer ${accessToken}` },
                        withCredentials: true,
                        ...options,
                    };
                }
                return {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    ...options,
                };
            }
        }
    }

    if (withCreds) {
        return {
            withCredentials: true,
            ...options,
        };
    }

    return options;
};

const withPassedToken = (token) => {
    return {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
    };
};

const withRootAPI = (path) => `${apiRoot}${path}`;

const withCreds = () => ({ withCredentials: true });

function ApiService(request = axios) {
    return {
        get: (path, options = null, creds = true) => {
            return (request.get(withRootAPI(path),
                options
                    ? withBearerTokenHeader(options === true ? {} : options)
                    : creds
                        ? withCreds()
                        : null
            ))
        },
        putWithTokenHeader: (path, options) => request.put(withRootAPI(path), options, withBearerTokenHeader()),
        put: (path, options) => request.put(
            withRootAPI(path),
            options,
            withBearerTokenHeader(options)
        ),
        putWithCreds: (path, options) => request.put(
            withRootAPI(path),
            options,
            withBearerTokenHeader(options, false, true)
        ),
        post: (path, options = null, creds = true) => {
            const r = request.post(
                withRootAPI(path),
                options,
                creds ? withCreds() : withBearerTokenHeader()
            );
            return r;
        },
        postWithPassedToken: (path, token, data = null) => request.post(
            withRootAPI(path),
            data,
            withPassedToken(token)
        ),
        patch: (path, options) => request.patch(withRootAPI(path), withBearerTokenHeader(options)),
        delete: (path, options) => request.delete(withRootAPI(path), withBearerTokenHeader(options)),
    };
}

export default ApiService;
