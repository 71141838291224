/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import ActionTypes from 'redux/actions/ActionTypes';
import ProfileAndSettingsReducer from 'redux/reducers/ProfileAndSettings/ProfileAndSettings.reducer';
import ProgressContentReducer from 'redux/reducers/ProgressContent/ProgressContent.reducer';
import VideoModalReducer from 'redux/reducers/VideoModal/VideoModal.reducer';
import { combineReducers } from 'redux';
import CookieService from 'services/CookieService';
import Constants from 'Constants';

const getIniitialPage = () => {
  let page = 'login';
  const { location } = window;
  const { pathname } = location;
  if (pathname.indexOf('envision') >= 0) {
    page = 'externalRedirect';
  }
  if (pathname.indexOf('designcenter/confirmedselections') >= 0) {
    page = 'externalRedirect';
  }
  if (pathname.indexOf('financing/loanapplication') >= 0) {
    page = 'externalRedirect';
  }
  if (pathname.indexOf('warranty/manual') >= 0) {
    page = 'externalRedirect';
  }
  if (pathname.indexOf('wellsfargo') >= 0) {
    page = 'externalRedirect';
  }
  if (pathname.indexOf('admin') >= 0) {
    page = 'admin';
  }
  if (pathname.indexOf('invite') >= 0) {
    page = 'invite';
  }

  return page;
};

const getLoadingScreenPhrasing = () => {
  const { location } = window;
  const { pathname } = location;
  if (pathname.indexOf('envision') >= 0) {
    return ['Logging you into Envision'];
  }
  if (pathname.indexOf('wellsfargo') >= 0) {
    return ['Redirecting to Wells Fargo'];
  }
  if (pathname.indexOf('designcenter/confirmedselections') >= 0) {
    return ['Taking you to your selections'];
  }
  if (pathname.indexOf('financing/loanapplication') >= 0) {
    return ['Taking you to your loan application'];
  }
  return [];
};

export const initialUserState = {
  busy: false,
  errors: {},
  currentPage: getIniitialPage(),
  emailAddress: CookieService.getCookie('remember'),
  loadingScreenPhrasing: getLoadingScreenPhrasing(),
};

const UserDetailsReducer = (state = initialUserState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case ActionTypes.ConfirmLotDeletion:
      return {
        ...state,
        lotAssociations: state.lotAssociations.filter(
          (lot) => lot.lotId !== action.payload.deletedLot
        ),
      };
    case ActionTypes.GoToAddLot:
      return {
        ...state,
        currentPage: 'lotAssociation',
      };
    case ActionTypes.ClearLotsForTesting:
      return {
        ...state,
        lotAssociations: action.payload,
      };
    case ActionTypes.CancelAddLot:
      return {
        ...state,
        currentPage: 'dashboard',
      };
    case ActionTypes.SavePushSubscriptionToState:
      return {
        ...state,
        notificationSubscriptions: [action.payload],
      };
    case ActionTypes.LogOut:
      return initialUserState;
    case ActionTypes.GetLotByIdRequest:
      return {
        ...state,
        busy: true,
        errors: {},
      };
    case ActionTypes.ADOLogin:
      return {
        ...state,
        busy: true,
        errors: action.payload.errors || {},
        refreshCookie: 'refreshed',
      };
    case ActionTypes.LoginRequest:
      return {
        ...state,
        busy: true,
        errors: {},
      };

    case ActionTypes.LoginError:
      return {
        ...state,
        busy: false,
        errors: action.payload.errors,
        refreshCookie: 'refreshed',
        currentPage: 'login',
      };

    case ActionTypes.LoginSuccess:
      return {
        ...state,
        busy: false,
        errors: action.payload.errors || {},
        refreshCookie: 'refreshed',
        inviteTokenStatus: null,
        ...action.payload,
      };

    case ActionTypes.GetLotConfiguration:
      return {
        ...state,
        busy: true,
      };

    case ActionTypes.UpdateUserDetails:
      return {
        ...state,
        busy: false,
        errors: {},
        ...action.payload,
      };

    case ActionTypes.AddLotIdRequest:
      return {
        ...state,
        addingNewLot: true,
        busy: true,
      };

    case ActionTypes.LotIdSuccess:
      return {
        ...state,
        busy: false,
        ...action.payload,
      };

    case ActionTypes.LotIdError:
      return {
        ...state,
        busy: false,
      };
    case ActionTypes.LotTooltipRequest:
      return {
        ...state,
        busy: true,
      };

    case ActionTypes.LotTooltipSuccess:
      return {
        ...state,
        busy: false,
      };

    case ActionTypes.LotTooltipError:
      return {
        ...state,
        busy: false,
      };
    case ActionTypes.RefreshUserRequest:
      return {
        ...state,
        refreshCookie: 'checking',
      };
    case ActionTypes.RefreshUserError:
      return {
        ...state,
        currentPage: window.location.pathname === '/admin' ? 'admin' : 'login',
        refreshCookie: 'expired',
      };
    case ActionTypes.FetchExternalRedirectError:
      return {
        ...state,
        currentPage: 'dashboard',
      };
    case ActionTypes.FetchWellsFargoExternalRedirectError:
      return {
        ...state,
        currentPage: 'dashboard',
        errors: {
          isFatal: true,
          message: Constants.global.errors.redirect.wellsfargo,
          disallowRefresh: true,
          hideSecondaryText: true,
        },
      };
    case ActionTypes.FetchFinancingExternalRedirectError:
      return {
        ...state,
        currentPage: 'dashboard',
        errors: {
          isFatal: true,
          message: Constants.global.errors.redirect.financing,
          disallowRefresh: true,
          hideSecondaryText: true,
        },
      };

    case ActionTypes.GetLotByIdSuccess:
      return {
        ...state,
        busy: false,
        currentPage: 'dashboard',
      };
    case ActionTypes.GetLotByIdError:
      return {
        ...state,
        busy: false,
      };
    case ActionTypes.HandleTokenRefreshSuccess:
      return {
        ...state,
        accessToken: action.payload.data.accessToken,
        accessTokenExpiration: action.payload.data.accessTokenExpiration,
      };
    case ActionTypes.SaveUsersDestinationForAfterLogin:
      return {
        ...state,
        afterSuccessfulLogin: action.payload,
      };
    case ActionTypes.HandleTokenRefreshError:
      return {
        ...state,
        accessToken: '',
        accessTokenExpiration: '',
        currentPage: window.location.pathname === '/admin' ? 'admin' : 'login',
        busy: false,
        errors: {},
      };

    case ActionTypes.ApiFatalError:
      if (action.payload && action.payload.link && action.payload.text) {
        return {
          ...state,
          errorScreen: {
            link: action.payload.link,
            text: action.payload.text,
          },
        };
      }
      if (action.payload === 'envision') {
        return {
          ...state,
          errors: {
            isFatal: true,
            message: Constants.global.errors.envisionFatal.description,
            disallowRefresh: true,
            link: '/dashboard',
            text: 'Dashboard',
          },
        };
      }
      return {
        ...state,
        errors: {
          isFatal: true,
          message: Constants.global.errors.fatal.description,
        },
      };
    case ActionTypes.GetProgressContentLotContent: {
      return {
        ...state,
        busy: false,
      };
    }

    case ActionTypes.RedirectForbidden:
      return {
        ...state,
        errors: {
          isFatal: true,
          message: Constants.global.errors.redirect[action.payload],
          disallowRefresh: true,
        },
      };
    case ActionTypes.NotificationPreferences:
      const oldSettings = state.notificationSettings;
      const newSettings = { ...oldSettings };
      if (action.payload.enablePush) {
        newSettings.enablePush = action.payload.enablePush;
      }
      if (action.payload.enableEmail) {
        newSettings.enableEmail = action.payload.enableEmail;
      }
      if (action.payload.enableText) {
        newSettings.enableText = action.payload.enableText;
      }
      return {
        ...state,
        notificationSettings: newSettings,
      };
    case ActionTypes.RedirectError404:
      return {
        ...state,
        errors: {
          isFatal: true,
          message: action.payload.message,
          disallowRefresh: true,
        },
      };
    case ActionTypes.HasPushNotification:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          enablePush: true,
        },
      };
    case ActionTypes.NotificationPrefDeletePushSubscription:
      const oldSubs = state.notificationSubscriptions;
      const oldSubSettings = state.notificationSettings;
      const newSubs = oldSubs.filter(
        (sub) => sub.notificationSubscriptionId !== action.payload
      );
      const newSubSettings = {
        enablePush: false,
        enableText: oldSubSettings.enableText,
        enableEmail: oldSubSettings.enableEmail,
      };

      return {
        ...state,
        notificationSubscriptions: newSubs,
        notificationSettings: newSubSettings,
      };
    case ActionTypes.FetchExternalRedirectNoUrl:
      return {
        ...state,
        currentPage: 'dashboard',
        loadScreenColor: 'gray',
        errors: { ...state.errors, hideSecondaryText: true },
      };

    case ActionTypes.OpenFindLotPage:
      return {
        ...state,
        currentPage: 'findLot',
        haveCancelButton: action.payload,
        busy: false,
      };

    case ActionTypes.CloseFindLotPage:
      return {
        ...state,
        currentPage: 'dashboard',
      };

    case ActionTypes.NotificationPrefSuccess:
      return {
        ...state,
        busy: false,
      };
    case ActionTypes.NotificationPrefUpdateState:
      return {
        ...state,
        notificationSettings: {
          enableEmail: action.payload.enableEmail,
          enablePush: action.payload.enablePush,
          enableText: action.payload.enableText,
        },
      };
    case ActionTypes.NotificationPrefError:
      return {
        ...state,
        errors: {
          isFatal: true,
          message: Constants.global.errors.fatal,
          disallowRefresh: false,
        },
      };
    case ActionTypes.CheckInviteToken:
      return {
        ...state,
        busy: true,
        inviteTokenStatus: 'requested',
      };
    case ActionTypes.InviteTokenValid:
      return {
        ...state,
        busy: false,
        inviteTokenStatus: 'valid',
        emailAddress: action.payload,
      };
    case ActionTypes.NoPassResetNeeded:
      return {
        ...state,
        busy: false,
        currentPage: 'login',
        inviteTokenStatus: null,
      };
    case ActionTypes.InviteTokenInvalid:
      return {
        ...state,
        busy: false,
        inviteTokenStatus: 'invalid',
        errors: action.payload.errors,
      };
    case ActionTypes.SubmitPassword:
      return {
        ...state,
        busy: true,
        errors: {},
      };
    case ActionTypes.SubmitPasswordError:
      return {
        ...state,
        busy: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
const UserReducer = combineReducers({
  userDetails: UserDetailsReducer,
  profileAndSettings: ProfileAndSettingsReducer,
  progressContent: ProgressContentReducer,
  videoModal: VideoModalReducer,
});

export default UserReducer;
