import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { PhotoService } from 'services';
import {
    getProgressContent,
    getPhotoModalState,
    getUserProfileAndSettings,
} from 'redux/sagas/selectors';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchDownloadUrl(data) {
    try {
        return await _sendRequest(data, PhotoService.getDownloadUrl);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* photoDownloadUrlSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const photoModalState = yield select(getPhotoModalState);
    const progressContent = yield select(getProgressContent);

    const lotId = userProfileAndSettings.activeLotId;
    const photoIndexesToDownload = photoModalState.photosForDownload;
    const photos = progressContent.lots[lotId].photos;
    const idsToSend = [];
    photoIndexesToDownload.forEach((index) => {
        idsToSend.push(parseInt(photos[index].photoLink.split('-')[0].split('/').pop(), 10));
    })

    const downloadUrlResponse = yield call(fetchDownloadUrl, idsToSend);
    if (downloadUrlResponse && downloadUrlResponse.url && !downloadUrlResponse.errors && !downloadUrlResponse.error) {
        yield put({
            type: ActionTypes.GetDownloadPhotosUrlSuccess,
            payload: downloadUrlResponse.url,
        });
    } else {
        yield put({
            type: ActionTypes.GetDownloadPhotosUrlError,
        });
    }
}
