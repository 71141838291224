import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import { ContactService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchContacts(data) {
    try {
        return await _sendRequest(data, ContactService.getAll);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* contactsSaga() {
    const contactsResponse = yield call(fetchContacts);
    if (contactsResponse && !contactsResponse.errors) {
        yield put({
            type: ActionTypes.GetContactsSuccess,
            payload: contactsResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetContactsError,
        });
    }
}
