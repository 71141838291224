import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Container, Row, Col } from 'react-bootstrap';
import './Dashboard.scss';
import { useLocation, useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    createGetProgressContentAction,
    createGetLotConfigurationAction,
} from 'redux/actions/ProgressContent/ProgressContentActions';
import { createShowFeedbackModalAction } from 'redux/actions/Feedback';
import { createGetNotificationsAction } from 'redux/actions/Notifications/NotificationsActions';
import { createOpenPhotoModalAction } from 'redux/actions/PhotoModal';
import {
    useHasCurrentLotContent,
    useHasCurrentLotConfiguration,
    useGetConfigurationForCurrentLot,
    useGetPhotosForCurrentLot,
    useHaveFatalError,
    useHasTasks,
    useHasNotifications,
    useGetTabContactData,
    useGetTabDataForCurrentLot,
    useIsAdmin,
    useWindowSize,
} from 'utilities/HooksAndSelectors';

import Utils, { getPageTitle } from 'utilities/Utils';
import { Title, MIModal } from 'ComponentLibrary';
import installApp from 'pwaInstall';
import ChecklistDropdown from 'App/ContentComponents/ChecklistDropdown';
import RepCard from 'components/LibraryElements/RepCard/RepCard';
import DesktopWelcome from './DesktopWelcome';
import MobileWelcome from './MobileWelcome';
import AppUpdateWelcome from './AppUpdateWelcome';
import MyHome from './MyHome';
import ProgressDashboard from './ProgressDashboard';
import MessageBoard from './MessageBoard/MessageBoard';
import DashboardNotificationsButton from './DashboardNotificationsButton/DashboardNotificationsButton';

const scrollToRef = (ref) => {
    try {
        if (ref && ref.current) {
            window.scroll({
                top: ref.current.offsetTop + 50,
                left: 0,
                behavior: 'smooth',
            });
        }
    } catch (e) { }
};


const Dashboard = () => {
    const isAdmin = useIsAdmin();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const userState = useSelector((state) => state.userState);
    const feedbackState = useSelector((state) => state.feedbackState);
    const progressRef = useRef(null);
    const { isOpen } = feedbackState;
    const photoModalOpen = useSelector((state) => state.photoModalState).isOpen;
    const { userDetails, profileAndSettings } = userState;
    const { activeLotId } = profileAndSettings;
    let { accessToken, busy } = userDetails;
    const hasTasks = useHasTasks();
    const hasNotifications = useHasNotifications();
    const hasData = useHasCurrentLotContent();
    const hasConfiguration = useHasCurrentLotConfiguration();
    const images = useGetPhotosForCurrentLot();

    const hasWelcome = Utils.getLocalStorage('mihomesWelcome-v2');
    const hasFatalError = useHaveFatalError();
    const tabsData = useGetTabDataForCurrentLot();
    let stageKey = null;
    let foundKey = false;
    if (tabsData) {
        tabsData.forEach((tab) => {
            if (tab.status === 'Started' && !foundKey) {
                foundKey = true;
                stageKey = tab.stageKey;
            }
        });
    }
    const tabContactData = useGetTabContactData(stageKey);
    const propsForCard = Utils.convertToRepCardData(tabContactData);

    const handleModalDisplay = (isShowing) => () => setShowModal(isShowing);
    const submitWelcomeScreen = () => {
        Utils.setLocalStorage('mihomesWelcome-v2', true);
        setShowModal(false);
        installApp();
    };
    const hasSeenAppChange = Utils.getLocalStorage('mihomes-app-change');
    const inAppMode = window.matchMedia('(display-mode: standalone)').matches;

    const [showAppChangeModal, setShowAppChangeModal] = useState(!hasSeenAppChange && (window.location.host === 'my.mihomes.com' || inAppMode));
    const handleAppChangeModalDisplay = (isShowing) => () => setShowAppChangeModal(isShowing);
    const [showModal, setShowModal] = useState(!hasWelcome && !showAppChangeModal);

    let welcomeContent;
    if (isMobile) {
        welcomeContent = (
            <MobileWelcome submitWelcomeScreen={submitWelcomeScreen} />
        );
    } else {
        welcomeContent = (
            <DesktopWelcome submitWelcomeScreen={submitWelcomeScreen} />
        );
    }
    useEffect(() => {
        if (
            window.location.hash === '#feedback' &&
            location.hash === '#feedback' &&
            !isOpen
        ) {
            dispatch(createShowFeedbackModalAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash]);

    useEffect(() => {
        if (!hasData) {
            // we don't have the data for this lot id, dispatch event to trigger the API call
            const action = createGetProgressContentAction({
                accessToken,
                lotId: activeLotId,
            });
            dispatch(action);
        }
        if (hasTasks && !hasNotifications) {
            const action = createGetNotificationsAction();
            dispatch(action);
        }

        if (!hasConfiguration && activeLotId) {
            const action = createGetLotConfigurationAction({
                accessToken,
                lotId: activeLotId,
            });
            dispatch(action);
        }
    }, [
        hasData,
        hasConfiguration,
        hasTasks,
        hasNotifications,
        activeLotId,
        accessToken,
        dispatch,
    ]);


    const lotConfiguration = useGetConfigurationForCurrentLot();

    if (
        !tabsData ||
        (tabsData &&
            (tabsData.length === 0 ||
                !tabsData[0].title ||
                tabsData === 'requested' ||
                tabsData[0] === 'r'))
    ) {
        busy = true;
    }

    const { features } = lotConfiguration;
    const { showProgressDashboard, showMyHome, showWelcomeBanner } = features;
    let allImagesAreNull = true;
    const hasHomeImages = !!images && images[0];
    images && images.forEach(({ photoLink }) => {
        if (photoLink !== null) {
            allImagesAreNull = false;
        }
    })

    if (progressRef && progressRef.current && location.hash === '#progress') {
        scrollToRef(progressRef);
    }

    if (params.tab && !photoModalOpen) {

        const [index, caption] = Utils.findIndex(params.tab, images);
        if (!Number.isNaN(index) && caption) {
            dispatch(
                createOpenPhotoModalAction({
                    currentImageIndex: index,
                    currentPageNumber: index + 1,
                    caption,
                })
            );
        } else if (location.pathname.indexOf('/dashboard') < 0) {
            history.push('/dashboard');
        }
    }

    return (
        <main className="main main--has-bottom-docked-nav-on-mobile">
            <Container fluid="lg">
                <Row>
                    <Col lg={12} md={12} sm={12} className="col--no-pad-mobile">
                        <div className="customer-dashboard-container customer-dashboard-container--desktop">
                            <Title title={getPageTitle(tabsData)} />
                            {showWelcomeBanner && (
                                <MessageBoard skeletonMode={busy} />
                            )}
                            {showMyHome && hasHomeImages && !allImagesAreNull && (
                                <MyHome images={images} skeletonMode={busy} />
                            )}
                            {showProgressDashboard && (
                                <div ref={progressRef}>
                                    <ProgressDashboard
                                        desktopOnly
                                        id="progress"
                                        skeletonMode={busy}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="customer-dashboard-container customer-dashboard-container--mobile">
                            {showMyHome && hasHomeImages && (
                                <MyHome images={images} skeletonMode={busy} />
                            )}
                            {showWelcomeBanner && (
                                <MessageBoard skeletonMode={busy} />
                            )}
                            <DashboardNotificationsButton skeletonMode={busy} />
                            <ChecklistDropdown
                                mode="page"
                                skeletonMode={busy}
                            />
                            {showProgressDashboard && (
                                <ProgressDashboard
                                    mobileOnly
                                    skeletonMode={busy}
                                />
                            )}
                            {!busy && <RepCard mobileOnly {...propsForCard} />}
                        </div>
                    </Col>
                </Row>
                {!hasFatalError && !isAdmin && (
                    <div className="welcome-modal-container">
                        <MIModal
                            show={showModal}
                            closeModal={handleModalDisplay(false)}
                            customClass={
                                isMobile
                                    ? 'welcome-modal--mobile'
                                    : 'welcome-modal--desktop'
                            }
                            isMobile
                            modalSize="lg"
                            centered="true"
                        >
                            {welcomeContent}
                        </MIModal>
                    </div>
                )}
                {showAppChangeModal && (
                    <div className="welcome-modal-container">
                        <MIModal
                            show={showAppChangeModal}
                            closeModal={handleAppChangeModalDisplay(false)}
                            customClass={
                                isMobile
                                    ? 'welcome-modal--mobile'
                                    : 'welcome-modal--desktop'
                            }
                            isMobile
                            modalSize="lg"
                            centered="true"
                        >
                            <AppUpdateWelcome closeModal={() => {
                                setShowAppChangeModal(false);
                            }} />
                        </MIModal>
                    </div>
                )}
            </Container>
        </main>
    );
};

export default Dashboard;
