import React from 'react';
import { Tabs, Text } from 'ComponentLibrary';
import './ProgressDashboard.scss';
import { useGetTabDataForCurrentLot } from 'utilities/HooksAndSelectors';
import PropTypes from 'prop-types';
import TabLinks from '../TabLinks';
import ProgressDashboardDesktopSkeleton from './ProgressDashboardDesktopSkeleton';
import ProgressDashboardMobileSkeleton from './ProgressDashboardMobileSkeleton';

const ProgressDashboard = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { desktopOnly, mobileOnly, id, skeletonMode } = props;
    const tabsData = useGetTabDataForCurrentLot();
    if (!tabsData) {
        return null;
    }

    if (mobileOnly) {
        return (
            <section
                className="dashboard-card progress-dashboard-container--mobile"
                data-testid="progress-dashboard-container"
            >
                <h2 className="dashboard-card__title dashboard-card__title--indent">
                    <Text type="inline" varaint="regular" family="museo">
                        Progress
                    </Text>
                </h2>
                {skeletonMode ? (
                    <ProgressDashboardMobileSkeleton />
                ) : (
                    <TabLinks tabs={tabsData} />
                )}
            </section>
        );
    }
    return (
        <section
            className="dashboard-card progress-dashboard-container"
            data-testid="progress-dashboard-container"
            id={id || null}
        >

            {skeletonMode ? (
                <ProgressDashboardDesktopSkeleton />
            ) : (
                <Tabs bg="neutral-light" tabs={tabsData} desktopOnly />
            )}
        </section>
    );
};
ProgressDashboard.propTypes = {
    id: PropTypes.string,
    desktopOnly: PropTypes.bool,
    mobileOnly: PropTypes.bool,
};
ProgressDashboard.defaultProps = {
    id: null,
    desktopOnly: false,
    mobileOnly: false,
};
export default ProgressDashboard;
