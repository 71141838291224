import React from 'react';
import ContentLoader from 'react-content-loader';

const ProgressDashboardMobileSkeleton = () => {
    return (
        <ContentLoader
            data-testid="progress-dashboard-mobile-skeleton"
            speed={2}
            viewBox="0 0 343 304"
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="50" />
            <rect x="0" y="70" rx="2" ry="2" width="100%" height="50" />
            <rect x="0" y="140" rx="2" ry="2" width="100%" height="50" />
            <rect x="0" y="210" rx="2" ry="2" width="100%" height="50" />
        </ContentLoader>
    );
};

export default ProgressDashboardMobileSkeleton;
