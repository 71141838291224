import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { TaskService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { getUserProfileAndSettings, getTasks } from 'redux/sagas/selectors';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function fetchTasks(data) {
    try {
        return await _sendRequest(data, TaskService.getTasks);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchDivisionTasks(data) {
    try {
        return await _sendRequest(data, TaskService.getDivisionTasks);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function updateTasks(data) {
    try {
        return await _sendRequest(data, TaskService.updateTasks, false);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function addNewTask(data) {
    try {
        return await _sendRequest(data, TaskService.addTask, false);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function removeTask(data) {
    try {
        return await _sendRequest(data, TaskService.removeTask, false);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function editTask(data) {
    try {
        return await _sendRequest(data, TaskService.editTask, false);
    } catch (error) {
        return buildErrorObject(error);
    }
}

const sortTasksResponse = (tasksResponse) => {
    const tasks = [];
    const personalTasks = [];
    tasksResponse.forEach((task) => {
        return task.taskScope === 'MIHomes'
            ? tasks.push(task)
            : personalTasks.push(task);
    });

    return { tasks, personalTasks };
};

function* handleUpdateTasksResponseSaga(tasks) {
    const taskCompleteResponse = yield call(updateTasks, tasks);
    return taskCompleteResponse;
}

export function* divisionTasksSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };
    const taskResponse = yield call(fetchDivisionTasks, data);
    if (Array.isArray(taskResponse)) {
        yield put({
            type: ActionTypes.GetDivisionTasksSuccess,
            payload: taskResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetDivisionTasksError,
        });
    }
}

export function* taskRequestSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };
    const taskResponse = yield call(fetchTasks, data);
    if (Array.isArray(taskResponse)) {
        const sortedTasks = sortTasksResponse(taskResponse);
        yield put({
            type: ActionTypes.GetTasksSuccess,
            payload: sortedTasks,
        });
    } else {
        yield put({
            type: ActionTypes.GetTasksError,
        });
    }
}

export function* updateTasksSaga() {
    const taskState = yield select(getTasks);
    const tasksToSend = [];
    if (taskState.taskIdsToUncomplete.length === 0 && taskState.taskIdsToComplete.length === 0) {
        yield put({
            type: ActionTypes.UpdateTasksSuccess,
        });
        return;
    }

    for (const i in taskState.taskIdsToComplete) {
        tasksToSend.push({
            taskId: taskState.taskIdsToComplete[i],
            isComplete: true
        })
    }

    for (const i in taskState.taskIdsToUncomplete) {
        tasksToSend.push({
            taskId: taskState.taskIdsToUncomplete[i],
            isComplete: false
        })
    }

    const taskUpdateResponse = yield call(
        handleUpdateTasksResponseSaga,
        tasksToSend
    );

    if (taskUpdateResponse && !taskUpdateResponse.errors) {
        yield put({
            type: ActionTypes.UpdateTasksSuccess,
        });
    } else {
        yield put({
            type: ActionTypes.UpdateTasksError,
        });
    }
}

export function* triggerChecklistModalSaga(action) {
    yield call(updateTasksSaga);
    yield put({
        type: ActionTypes.OpenChecklistModal,
        payload: action.payload,
    });
}

export function* addNewTaskSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const taskState = yield select(getTasks);
    const { title, description, dueDate } = taskState.taskFormData;
    const taskData = {
        lotId: userProfileAndSettings.activeLotId,
        title,
    };

    if (dueDate) {
        const dueDateObject = new Date(dueDate.replace(/-/g, '/'));
        taskData.dueDate = dueDateObject;
    }

    if (description) {
        taskData.description = description;
    }

    const addNewTaskResponse = yield call(addNewTask, taskData);
    if (addNewTaskResponse && !addNewTaskResponse.errors) {
        yield put({
            type: ActionTypes.AddNewTaskSuccess,
        });
    } else {
        yield put({
            type: ActionTypes.AddNewTaskError,
        });
    }
}

export function* removeTaskSaga(action) {
    const data = {
        taskId: action.payload,
    };
    const removeTaskResponse = yield call(removeTask, data);
    if (!removeTaskResponse && !removeTaskResponse.errors && removeTaskResponse.name !== 'ERROR') {
        yield put({
            type: ActionTypes.RemovePersonalTaskSuccess,
            payload: data.taskId,
        });
    } else {
        yield put({
            type: ActionTypes.RemovePersonalTaskError,
        });
    }
}

export function* editPersonalTaskSaga() {
    const taskState = yield select(getTasks);
    const { title, description, dueDate, taskId } = taskState.taskFormData;
    let dateToSend = dueDate;
    if (dueDate && dueDate.indexOf('T') === -1) {
        dateToSend = new Date(dueDate.replace(/-/g, '/')).toISOString();
    }
    const requestData = {
        taskId,
        taskData: {
            title,
        },
    };

    if (dueDate) {
        requestData.taskData.dueDate = dateToSend;
    }

    if (description) {
        requestData.taskData.description = description;
    }

    const editTaskResponse = yield call(editTask, requestData);
    if (!editTaskResponse && !editTaskResponse.errors) {
        yield put({
            type: ActionTypes.SubmitEditPersonalTaskSuccess,
        });
    } else {
        yield put({
            type: ActionTypes.SubmitEditPersonalTaskError,
        });
    }
}
