import ActionTypes from 'redux/actions/ActionTypes';

export const FeedbackStates = {
    Idle: 'idle',
    Busy: 'busy',
    Complete: 'complete',
};
export const initialFeedbackState = {
    isOpen: false,
    rating: 0,
    state: FeedbackStates.Idle,
    comments: '',
    errors: null,
    hoverRating: 0,
};

const Feedback = (state = initialFeedbackState, action) => {
    if (!action) {
        return state;
    }
    const { rating } = state;
    switch (action.type) {
        case ActionTypes.FeedbackHoverRating:
            return {
                ...state,
                hoverRating: action.payload,
            }
        case ActionTypes.LogOut:
            return initialFeedbackState;
        case ActionTypes.SubmitFeedbackForm:
            return {
                ...state,
                state: FeedbackStates.Busy,
            }
        case ActionTypes.SubmitFeedbackFormError:
            return {
                ...state,
                state: FeedbackStates.Idle,
                errors: action.payload,
            }
        case ActionTypes.SubmitFeedbackFormSuccess:
            return {
                ...state,
                state: FeedbackStates.Complete,
            }
        case ActionTypes.SetFeedbackComments:
            return {
                ...state,
                comments: action.payload,
            }
        case ActionTypes.SetFeedbackRating:
            return {
                ...state,
                rating: action.payload,
            }
        case ActionTypes.CloseFeedbackModal:
            return {
                ...state,
                isOpen: false,
                state: FeedbackStates.Idle,
                comments: '',
                errors: null,
            }
        case ActionTypes.ShowFeedbackModal:
            return {
                ...state,
                isOpen: true,
                state: FeedbackStates.Idle,
                rating: action.payload || rating,
            }
        default:
            return state;
    }
};

export default Feedback;

