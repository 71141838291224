import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import { DesignSelectionsService } from 'services';
import { getUserProfileAndSettings } from 'redux/sagas/selectors';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';

async function fetchDesignSelectionsData(data) {
    try {
        return await _sendRequest(data, DesignSelectionsService.getSelections);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function fetchDesignSelectionsItemData(data) {
    try {
        return await _sendRequest(data, DesignSelectionsService.getItemData);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* designSelectionsDataRequestSaga() {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
    };

    const selectionsResponse = yield call(fetchDesignSelectionsData, data);
    if (selectionsResponse && Array.isArray(selectionsResponse)) {
        let categories = [];
        selectionsResponse.forEach(selection => {
            if (categories.indexOf(selection.category) === -1) {
                categories.push(selection.category);
            }
        });

        categories = categories.sort();
        categories.unshift('All');

        yield put({
            type: ActionTypes.GetDesignSelectionsSuccess,
            payload: { selectionsResponse, categories },
        });
    } else {
        yield put({
            type: ActionTypes.GetDesignSelectionsError,
        });
    }
}

export function* designSelectionsModalDataRequestSaga(action) {
    const userProfileAndSettings = yield select(getUserProfileAndSettings);
    const data = {
        lotId: userProfileAndSettings.activeLotId,
        optionId: action.payload,
    };

    const itemDataResponse = yield call(fetchDesignSelectionsItemData, data);
    if (itemDataResponse && itemDataResponse.optionName) {
        yield put({
            type: ActionTypes.GetDesignSelectionsItemDataSuccess,
            payload: itemDataResponse,
        });
    } else {
        yield put({
            type: ActionTypes.GetDesignSelectionsItemDataError,
        });
    }
}
