import React from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import Icon from 'components/LibraryElements/Icon';
import './Button.scss';

const Button = (props) => {
    const {
        as,
        href,
        icon,
        text,
        submit,
        style,
        disabled,
        children,
        target,
        testId,
        bold,
        onClick,
        addClass,
    } = props;
    const match = useRouteMatch({
        path: href,
        exact: true,
    });

    const updatedIcon = icon ? <Icon icon={icon} /> : null;

    const buttonClassList = `${text ? 'button' : ''}
    ${style}-button ${addClass ? `${addClass}` : ''}
    ${match ? `button--active ${style}-button--active` : ''}
    ${icon && text ? 'button--with-icon' : ''}
    ${disabled ? 'disabled' : ''} ${bold ? 'button--bold' : ''}`;

    const linkClassList = `link ${style}-link
    ${addClass ? `${addClass}` : ''}
    ${match ? `link--active ${style}-link--active` : ''}
    ${icon ? 'button--with-icon' : ''}
    ${disabled ? 'disabled' : ''}`;



    if (as === 'button') {
        return (
            <button
                className={buttonClassList}
                data-testid={testId || 'button'}
                type="submit"
                disabled={disabled}
                onClick={onClick}
            >
                {updatedIcon}
                <span className={`button__text ${bold ? 'button__text--bold' : ''}`}>
                    {text}
                    {children}
                </span>
            </button>
        );
    }
    if (as === 'link') {
        const isExternal = href.indexOf('http') === 0;
        const externalProps =
            isExternal && target && target === '_blank'
                ? { target, rel: 'noopener' }
                : {};
        return isExternal ? (
            <a
                {...externalProps}
                className={linkClassList}
                href={href}
                data-testid="link"
            >
                {updatedIcon}
                <span className="link__text">
                    {text}
                    {children}
                </span>
            </a>
        ) : (
            <Link
                className={linkClassList}
                to={href}
                data-testid={testId || 'button'}
                disabled={disabled}
                onClick={onClick}
            >
                {updatedIcon}
                <span className="link__text">
                    {text}
                    {children}
                </span>
            </Link>
        );
    }
    if (as === 'inputButton') {
        return (
            <input
                type={`${submit ? 'submit' : 'button'}`}
                value={text}
                name="button"
                data-testid={testId || 'button'}
                className={buttonClassList}
            />
        );
    }
    return null;
};

export const ButtonStyles = {
    PrimaryButton: 'primary',
    SecondaryButton: 'secondary',
    PrimaryIconOnlyButton: 'primary-icon-only',
    SecondaryIconOnlyButton: 'secondary-icon-only',
    TextOnlyLink: 'text-only',
    TextIconLink: 'text-icon',
};

Button.defaultProps = {
    onClick: null,
    href: '/',
    icon: null,
    disabled: false,
    submit: false,
    text: null,
    target: null,
    bold: false,
};

Button.propTypes = {
    bold: PropTypes.bool,
    as: PropTypes.string.isRequired,
    text: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    onClick: PropTypes.func,
    submit: PropTypes.bool,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
};

export default withRouter(Button);
