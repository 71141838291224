export const sortDocuments = (sortBy, sortOrder, documents) => {
    if (sortBy === 'filename') {
        return documents.sort((a, b) => {
            const adisplayName = a.displayName;
            const bdisplayName = b.displayName;
            const r = new RegExp(/Change Order [0-9]+/, 'gi');
            const firstHasNumber = r.test(adisplayName);
            r.lastIndex = 0;
            const secondHasNumber = r.test(bdisplayName);

            if (firstHasNumber && secondHasNumber) {
                const aNum = parseInt(adisplayName.replace('Change Order ', ''), 10);
                const bNum = parseInt(bdisplayName.replace('Change Order ', ''), 10);

                if (sortOrder === 'asc') {
                    return aNum < bNum ? -1 : 1;
                } else {
                    return aNum > bNum ? -1 : 1;
                }
            }

            if (sortOrder === 'asc') {
                return adisplayName < bdisplayName ? -1 : 1;
            }
            return bdisplayName > adisplayName ? 1 : -1;
        });
    } else {
        return documents.sort((a, b) => {
            const aDate = new Date(a.completedDate)
            const bDate = new Date(b.completedDate);

            if(sortOrder === 'asc') {
                return aDate.getTime() < bDate.getTime() ? -1 : 1;
            } else {
                return aDate.getTime() > bDate.getTime() ? -1 : 1;
            }
        });
    }
}
