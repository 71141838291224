import React from 'react';
import PropTypes from 'prop-types';

import './Text.scss';

const Text = (props) => {
    const {
        type,
        variant,
        children,
        color,
        semibold,
        family,
        component,
        addClass,
    } = props;
    const className = `text text--${variant} text--${family} ${addClass} ${semibold && 'semibold'} ${color || ''}`.trim();
    const elementType = component ? component : type === 'block' ? 'p' : 'span';
    return React.createElement(elementType, { className, 'data-testid': 'text' }, children);
};

Text.defaultProps = {
    type: 'block',
    variant: 'regular',
    color: null,
    semibold: false,
    family: 'proxima-nova',
};

Text.propTypes = {
    semibold: PropTypes.bool,
    type: PropTypes.string.isRequired,
    variant: PropTypes.string,
    color: PropTypes.string,
    family: PropTypes.string,
};

export default Text;
