import React, { useState } from 'react';
import { Button, ButtonStyles, Input, Icon, IconTypes, Error } from 'ComponentLibrary';
import { Link } from 'react-router-dom';
import Constants from 'Constants';
import './InviteForm.scss';

const InviteForm = (props) => {
    const { hasValidInvite, handleSubmit, errors, busy , emailAddress} = props;
    const [password, updatePassword] = useState('');
    const inviteText = Constants.pages.invite;
    const hasExpiredMessage = errors && errors.form && errors.form[0] && errors.form[0].title && errors.form[0].title.indexOf('expired') > -1;

    return (
        <div className="invite-form-container" data-testid="invite-form-container">
            {hasValidInvite ? (
                <form
                    className={`invite-form ${busy ? 'invite-form--busy' : ''}`}
                    data-testid="invite-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        password && handleSubmit(password);
                    }}
                >

                    {errors &&
                        errors.form &&
                        errors.form.map((error, index) => (
                            <Error key={index}>{error.message}</Error>
                    ))}

                    <Input
                        type="text"
                        label="Email"
                        id="email"
                        type="text"
                        name="email"
                        extraClasses="invite-email"
                        initialValue={emailAddress}
                        disabled
                    />

                    <Input
                        type="text"
                        label="Password"
                        id="password"
                        type="password"
                        testId="invite-password"
                        name="password"
                        placeholder={inviteText.passwordPlaceholder}
                        onChange={(e) => {
                            updatePassword(e.target.value);
                        }}
                    />

                    <div className="password-requirements-text">
                        <p>Password must be at least 8 characters and contain at least 3 of the following:</p>
                        <ul>
                            <li>Lower case letters (a-z)</li>
                            <li>Upper case letters (A-Z)</li>
                            <li>Numbers (0-9)</li>
                            <li>Special Characters (@$^%^!)</li>
                        </ul>
                    </div>

                    <Button
                        as="button"
                        submit
                        testId="get-started-invite-button"
                        style={ButtonStyles.PrimaryButton}
                        addClass="get-started-invite"
                        text={inviteText.submitButtonText}
                    />
                </form>
            ) : (
                <div className="invalid-invite-wrapper" data-testid="invalid-invite-wrapper">
                    <Icon size={3} icon="warning" />
                    <p className="invalid-invite-message">
                        {errors && errors.form && errors.form[0].message}
                    </p>

                    {!hasExpiredMessage && (
                        <Link
                        to="/"
                        className="link-to-login-button"
                        data-testid="link-to-login-button"
                        >
                            Log In to your account
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default InviteForm;
