import ActionTypes from 'redux/actions/ActionTypes';
import { put, call } from 'redux-saga/effects';
import Constants from 'Constants';
import { AuthService } from 'services';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';

async function requestInviteTokenStatus(data) {
    try {
        return await _sendRequest(data, AuthService.getInviteTokenStatus, false, true);
    } catch (error) {
        return buildErrorObject(error);
    }
}

async function requestPasswordReset(data) {
    try {
        return await _sendRequest(data, AuthService.changePassword, false, true);
    } catch (error) {
        return buildErrorObject(error);
    }
}

export function* checkInviteTokenSaga(action) {
    const data = {
        inviteToken: action.payload,
    };

    const tokenCheckResponse = yield call(requestInviteTokenStatus, data);

    if (tokenCheckResponse && tokenCheckResponse.email && tokenCheckResponse.passwordNeedsReset) {
        yield put({
            type: ActionTypes.InviteTokenValid,
            payload: tokenCheckResponse.email,
        });
    } else if (tokenCheckResponse && tokenCheckResponse.email && tokenCheckResponse.passwordNeedsReset === false) {
        yield put({
            type: ActionTypes.NoPassResetNeeded,
        });
    } else {
        yield put({
            type: ActionTypes.InviteTokenInvalid,
            payload: tokenCheckResponse || buildErrorObject({ message: 'There was an error' }),
        });
    }
}

export function* submitPasswordSaga(action) {
    const data = {
        newPassword: action.payload.password,
        inviteToken: action.payload.token,
    };

    const passwordResetResponse = yield call(requestPasswordReset, data);

    if (
        passwordResetResponse.accessToken &&
        passwordResetResponse.accessTokenExpiration
    ) {
        yield put({
            type: ActionTypes.SubmitPasswordSuccess,
            accessToken: passwordResetResponse.accessToken,
        });
    } else {
        yield put({
            type: ActionTypes.SubmitPasswordError,
            payload: passwordResetResponse || buildErrorObject({
                message: Constants.global.errors.generic.fail,
            }),
        });
    }
}
