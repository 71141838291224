import React from 'react';
import { TabLink } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import './TabLinks.scss';

const TabLinks = (props) => {
    const { tabs } = props;

    let tabRenders = [];
    tabRenders = tabs.map((t) => {
        const { title, status } = t;
        if (!title) return null;
        const propsToPass = {
            title,
            status,
            href: `/progress/${Utils.slugify(title)}`,
        };
        return (
            <TabLink key={Utils.slugify(title)} {...propsToPass} />
        );
    });

    return (
        <div>
            {tabs && tabs.length > 0 && (
                <div className="tablinks-wrapper--mobile" data-testid="tablinks-wrapper--mobile">
                    <ol role="tablist" className="tabs__nav-list--mobile">
                        {tabRenders}
                    </ol>
                </div>
            )}
        </div>
    );
};

export default TabLinks;
