import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonStyles, RadioSet, Error } from 'ComponentLibrary';
import {
    createGetDivisionsAction,
    createSearchByAddressAction,
    createSearchByNameAction,
    createSearchParamUpdateEventAction,
 } from 'redux/actions/Admin';
import { CookieService } from 'services';
import Constants from 'Constants';
import {
    createCloseFindLotPageAction,
    createLogOutAction,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import { useHistory } from 'react-router-dom';

const NameOrAddressLotSearch = ({onModeChange}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [errors, setErrors] = useState([]);

    const cookie = CookieService.getCookie('searchMode');
    const tabSpecificCookie = CookieService.getCookie('nameOrAddressSearch')

    let initialMode = 'registered buyer name';
    if (cookie === 'address' || tabSpecificCookie === 'address') {
        initialMode = 'address';
    }

    const [mode, setMode] = useState(initialMode);
    const [division, setDivision] = useState(CookieService.getCookie('divisionForSearch') || -1);
    const homesState = useSelector((state) => state.homesState);
    const { divisions, busy, addressSearch, firstNameSearch, lastNameSearch } = homesState;

    const userState = useSelector((state) => state.userState);

    const lotAssociationState = useSelector(
        (state) => state.lotAssociationState
    );
    const lotStateErrors = lotAssociationState.errors;

    const { userDetails } = userState;
    const { accessToken } = userDetails;

    const divisionError = errors && errors.filter(({field}) => field === 'division').length > 0 ? errors.filter(({field}) => field === 'division')[0] : null;
    const lastNameError = errors && errors.filter(({field}) => field === 'last-name').length > 0 ? errors.filter(({field}) => field === 'last-name')[0] : null;
    const addressError = errors && errors.filter(({field}) => field === 'address').length > 0 ? errors.filter(({field}) => field === 'address')[0] : null;
    if (!divisions) {
        dispatch(createGetDivisionsAction());
    }
    CookieService.setCookie('searchMode', mode);
    return (
        <div className="lot-id-search find-lot-block">
            <form
                noValidate
                className={`form ${busy && 'form--busy'}`}
                onSubmit={(e) => {
                    e.preventDefault();
                    let newErrors = [...errors];
                    if (busy) {
                        return false;
                    }
                    let valid = true;
                    if (mode === 'registered buyer name') {
                        if (division === -1 || division === '-1') {
                            valid = false;
                            newErrors.push({field: 'division', message: Constants.pages.findLot.divisionError});
                        }

                        if (!lastNameSearch || lastNameSearch.length < 1) {
                            valid = false;
                            newErrors.push({field: 'last-name', message: Constants.pages.findLot.lastNameError});
                        } else {
                            newErrors = newErrors.filter((error) => error.field !== 'last-name')
                        }
                    }

                    if (mode === 'address') {
                        if (!addressSearch || addressSearch.length < 1) {
                            valid = false;
                            newErrors.push({field: 'address', message: Constants.pages.findLot.addressError});
                        } else {
                            newErrors = newErrors.filter((error) => error.field !== 'address')
                        }
                    }

                    if (valid) {
                        setErrors([]);
                        if (mode === 'registered buyer name') {
                            dispatch(createSearchByNameAction(division, firstNameSearch.trim(), lastNameSearch.trim()));
                        } else if (mode === 'address') {
                            dispatch(createSearchByAddressAction(addressSearch));
                        }
                    } else {
                        setErrors(newErrors);
                    }

                    return false;
                }}
            >
                {lotStateErrors &&
                    lotStateErrors.form &&
                    lotStateErrors.form.map((error, index) => (
                        <Error className="error--margin-bottom" key={index}>
                            {error.message}
                        </Error>
                    ))}
                <RadioSet
                    grid
                    id="name-or-address-search"
                    hiddenLabel
                    groupName="mode"
                    label={Constants.pages.findLot.searchType}
                    initialValue={initialMode}
                    options={Constants.pages.findLot.searchTypesForNameAddress}
                    onChange={(value) => {
                        setMode(value);
                        onModeChange(value);
                        CookieService.setCookie('searchMode', value);
                        CookieService.setCookie('nameOrAddressSearch', value);
                    }}
                />
                {mode === 'registered buyer name' &&
                    divisions && Array.isArray(divisions) && (
                    <>
                        <div className="field-wrap">
                            <label className="label" htmlFor="division">Division*</label>
                            <div className={`input-wrap input-wrap--select input-wrap--select-like-input ${divisionError && 'input-wrap--error'}`}>
                                <select
                                    required
                                    id="division"
                                    name="division"
                                    className="input input--select select--small-padding"
                                    value={division}
                                    onChange={(e) => {
                                        if(divisionError) {
                                            setErrors(errors.filter(error => error.field !== 'division'));
                                        }
                                        CookieService.setCookie('divisionForSearch', e.currentTarget.value)
                                        setDivision(e.currentTarget.value);
                                    }}
                                >
                                    <option value={-1}>
                                        {Constants.pages.findLot.divisionSelectPlaceholder}
                                    </option>
                                    {divisions.map(({ name, divisionId, sitecoreId }) => <option key={sitecoreId} value={sitecoreId}>{name}</option>)}
                                </select>
                            </div>
                            {divisionError && (
                                <Error className="error--below">{divisionError.message}</Error>
                            )}
                        </div>
                        <div className="field-wrap">
                            <label htmlFor="first-name" className="label">First Name</label>
                            <div className="input-wrap input-wrap--text">
                                <input
                                    id="first-name"
                                    name="last-name"
                                    className="input input--text"
                                    type="text"
                                    value={firstNameSearch}
                                    onChange={(e) => {
                                        dispatch(createSearchParamUpdateEventAction(e.currentTarget.value, 'firstName'));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="field-wrap">
                            <label className="label" htmlFor="last-name">Last Name*</label>
                            <div className="input-wrap input-wrap--text">
                                <input
                                    id="last-name"
                                    name="last-name"
                                    required
                                    type="text"
                                    className={`input input--text ${lastNameError && 'input--error'}`}
                                    value={lastNameSearch}
                                    onChange={(e) => {
                                        dispatch(createSearchParamUpdateEventAction(e.currentTarget.value, 'lastName'));
                                    }}
                                />
                            </div>
                            {lastNameError && (
                                <Error className="error--below">{lastNameError.message}</Error>
                            )}
                        </div>
                    </>
                )}

                {mode === 'address' && (
                    <div className="field-wrap">
                        <label className="label" htmlFor="address">Address*</label>
                        <div className="input-wrap input-wrap--text">
                            <input type="text" required value={addressSearch} onChange={(event) => {
                                dispatch(createSearchParamUpdateEventAction(event.currentTarget.value, 'address'));
                            }} aria-describedby="address-info" id="address" name="address" className={`input input--text ${addressError && 'input--error'}`} />
                        </div>
                        <p id="address-info" className="field-instructions">{Constants.pages.findLot.instructions}</p>
                        {addressError && (
                            <Error className="error--below">{addressError.message}</Error>
                        )}
                    </div>
                )}
                <div className="find-lot-buttons">
                    <Button
                        as="button"
                        testId="submit-lot-button"
                        style={ButtonStyles.PrimaryButton}
                        text={Constants.pages.adminLotAssociation.submit}
                        disabled={busy}
                        submit
                    />
                    <Button
                        as="button"
                        testId="find-lot-cancel-button"
                        style={ButtonStyles.SecondaryButton}
                        addClass="find-lot-cancel"
                        text={Constants.pages.adminLotAssociation.cancel}
                        onClick={(e) => {
                            const c = CookieService.getCookie('adminLotId');

                            if (c) {
                                const action = createCloseFindLotPageAction(
                                    CookieService.getCookie('adminLotId')
                                );
                                dispatch(action);
                                history.push('/dashboard');
                            } else {
                                dispatch(createLogOutAction(accessToken, true));
                                history.push('/login?logout=true');
                            }

                            e.preventDefault();
                            return false;
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default NameOrAddressLotSearch;
