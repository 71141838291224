import React from 'react';
import PropTypes from 'prop-types';
import { ProgressAccordion } from 'ComponentLibrary';
import { Accordion, Card } from 'react-bootstrap';
import Utils from 'utilities/Utils';
import AccordionGroupHeader from './AccordionGroupHeader/AccordionGroupHeader';
import './AccordionGroup.scss';

export default function AccordionGroup(props) {
    const { accordionData, type, testId } = props;
    const status = type === 'previous' ? 'complete' : 'notstarted';

    return (
        <div className="accordion-group-container" data-testid={testId}>
            <Accordion defaultActiveKey="1">
                <Card>
                    <AccordionGroupHeader type={type} />
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {accordionData && accordionData.map((step, index) => {
                                return (
                                    <ProgressAccordion
                                        {...step}
                                        key={`${type}-${step.title}-${index}`}
                                        indicator={ step.status ?
                                            Utils.getIndicator(step.status) :
                                            Utils.getIndicator(status)
                                        }
                                    />
                                )
                            })}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

AccordionGroup.defaultProps = {
    accordionData: null,
    type: null,
    testId: 'accordion-group-container',
};

AccordionGroup.propTypes = {
    accordionData: PropTypes.array,
    type: PropTypes.string,
    testId: PropTypes.string,
};
