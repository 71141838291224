/* eslint-disable import/prefer-default-export */
import { takeLatest, takeEvery } from 'redux-saga/effects';
import ActionTypes from 'redux/actions/ActionTypes';
import {
    progressContentSaga,
    configurationSaga,
    contactRequestSaga,
} from './ProgressContent/progressContentSagas';

import {
    adminLoginSaga,
    loginSaga,
    logoutSaga,
    refreshSaga,
    externalRedirectSaga,
    setNotificationPreferencesSaga,
    profileSaga,
} from './User/userSagas';

import {
    lotAssociationSaga,
    getLotByIdSaga,
    lotTooltipContentSaga,
    deleteLotAssociationSaga,
} from './LotAssociation/lotAssociationSaga';

import { submitFeedbackSaga } from 'redux/sagas/Feedback';
import { photoDownloadUrlSaga } from 'redux/sagas/Photos';
import { contactsSaga } from 'redux/sagas/Contacts';
import { getDivisionContactsSaga } from 'redux/sagas/Division';

import {
    getDivisionsSaga,
    getCommunitiesSaga,
    searchByAddressSaga,
    searchByNameSaga,
    getAssociatedBuyersSaga,
} from 'redux/sagas/Admin';

import {
    documentsSaga,
    singleDocumentsSaga,
} from './Documents/documentSagas';



import {
    taskRequestSaga,
    updateTasksSaga,
    triggerChecklistModalSaga,
    divisionTasksSaga,
    addNewTaskSaga,
    removeTaskSaga,
    editPersonalTaskSaga,
} from './Tasks/taskSagas';

import {
    notificationsRequestSaga,
    notificationsViewSaga,
    sampleNotificationsSaga,
} from './Notifications/notificationsSagas';

import {
    categoriesRequestSaga,
    categoryDataRequestSaga,
    removeClaimSaga,
    saveClaimSaga,
    submitWarrantyClaimsSaga,
    removeExistingClaimsDraftSaga,
    getServiceRequestIdSaga,
    completeServiceRequestSaga,
} from './Warranty/warrantySagas';

import { depositsDataRequestSaga } from './Deposits/depositsSagas';

import {
    designSelectionsDataRequestSaga,
    designSelectionsModalDataRequestSaga,
} from './DesignSelections/designSelectionsSagas';

import { checkInviteTokenSaga, submitPasswordSaga } from './Invite/inviteSagas';

export function* watchSubmitServiceItems() {
    yield takeEvery(ActionTypes.SubmitServiceItem, submitWarrantyClaimsSaga);
}

export function* watchDeleteLotAssociation() {
    yield takeEvery(ActionTypes.ConfirmLotDeletion, deleteLotAssociationSaga);
}

export function* watchCompleteServiceRequest() {
    yield takeEvery(
        ActionTypes.SuccessfulWarrantySubmission,
        completeServiceRequestSaga
    );
}

export function* watchGetServiceRequestId() {
    yield takeEvery(
        ActionTypes.ServiceRequestIdAskedFor,
        getServiceRequestIdSaga
    );
}

export function* watchLogin() {
    yield takeLatest(ActionTypes.Login, loginSaga);
}

export function* watchAdminLogin() {
    yield takeLatest(ActionTypes.ADOLogin, adminLoginSaga);
}

export function* watchGetDivisionContacts() {
    yield takeLatest(ActionTypes.GetDivisionContacts, getDivisionContactsSaga);
}

export function* watchContactRequest() {
    yield takeEvery(ActionTypes.GetContactForPhase, contactRequestSaga);
}

export function* watchTasksRequest() {
    yield takeEvery(ActionTypes.GetTasks, taskRequestSaga);
}

export function* watchGetPhotosDownloadUrl() {
    yield takeEvery(ActionTypes.GetDownloadPhotosUrl, photoDownloadUrlSaga);
}

export function* watchTaskComplete() {
    yield takeLatest(ActionTypes.CompleteTask, updateTasksSaga);
}

export function* watchTaskModalTrigger() {
    yield takeLatest(
        ActionTypes.TriggerChecklistModal,
        triggerChecklistModalSaga
    );
}

export function* watchNotificationsRequest() {
    yield takeEvery(ActionTypes.GetNotifications, notificationsRequestSaga);
}

export function* watchSampleNotificationsRequest() {
    yield takeEvery(
        ActionTypes.GetSampleNotifications,
        sampleNotificationsSaga
    );
}

export function* watchViewNotification() {
    yield takeEvery(ActionTypes.ViewNotification, notificationsViewSaga);
}

export function* watchTaskCompleteSuccess() {
    yield takeLatest(ActionTypes.UpdateTasksSuccess, taskRequestSaga);
}

export function* watchGetAssociatedBuyers() {
    yield takeLatest(ActionTypes.GetAssociatedBuyers, getAssociatedBuyersSaga);
}

export function* watchTaskCompleteError() {
    yield takeLatest(ActionTypes.UpdateTasksError, taskRequestSaga);
}

export function* watchGetProgressContent() {
    yield takeLatest(ActionTypes.GetProgressContent, progressContentSaga);
}

export function* watchLogout() {
    yield takeLatest(ActionTypes.LogOut, logoutSaga);
}

export function* watchGetDocuments() {
    yield takeLatest(ActionTypes.GetDocuments, documentsSaga);
}
export function* watchGetSingleDocument() {
    yield takeLatest(ActionTypes.GetSingleDocument, singleDocumentsSaga);
}

export function* watchSetNotificationPreferences() {
    yield takeLatest(
        'NOTIFICATION_PREFERENCES',
        setNotificationPreferencesSaga
    );
}

export function* watchGetConfiguration() {
    yield takeLatest(ActionTypes.GetLotConfiguration, configurationSaga);
}

export function* watchGetDivisionTasks() {
    yield takeLatest(ActionTypes.GetDivisionTasks, divisionTasksSaga);
}

export function* watchRefresh() {
    yield takeLatest(ActionTypes.CreateTokenRefresh, refreshSaga);
}

export function* watchLotAssociation() {
    yield takeLatest(ActionTypes.AddLotId, lotAssociationSaga);
}

export function* watchGetLotById() {
    yield takeEvery(ActionTypes.GetLotById, getLotByIdSaga);
}

export function* watchLotToolTip() {
    yield takeLatest(ActionTypes.FetchLotToolTipContent, lotTooltipContentSaga);
}

export function* watchExternalRedirect() {
    yield takeLatest(ActionTypes.StartExternalRedirect, externalRedirectSaga);
}

export function* watchFeedback() {
    yield takeLatest(ActionTypes.SubmitFeedbackForm, submitFeedbackSaga);
}

export function* watchContacts() {
    yield takeLatest(ActionTypes.GetContacts, contactsSaga);
}

export function* watchWarrantyCategoriesRequest() {
    yield takeEvery(ActionTypes.GetCategories, categoriesRequestSaga);
}

export function* watchWarrantySetActiveCategory() {
    yield takeEvery(ActionTypes.SetActiveCategory, categoryDataRequestSaga);
}

export function* watchWarrantyRemoveActiveCategory() {
    yield takeEvery(ActionTypes.RemoveActiveCategory, removeClaimSaga);
}

export function* watchSaveWarrantyClaim() {
    yield takeEvery(ActionTypes.SaveClaimDataToFile, saveClaimSaga);
}

export function* watchRemoveExistingWarrantyClaimDraft() {
    yield takeEvery(
        ActionTypes.RemoveExistingClaimsData,
        removeExistingClaimsDraftSaga
    );
}

export function* watchDeposits() {
    yield takeLatest(ActionTypes.GetDeposits, depositsDataRequestSaga);
}

export function* watchAddNewTask() {
    yield takeLatest(ActionTypes.AddNewTask, addNewTaskSaga);
}

export function* watchAddNewTaskSuccess() {
    yield takeLatest(ActionTypes.AddNewTaskSuccess, updateTasksSaga);
}

export function* watchRemovePersonalTask() {
    yield takeLatest(ActionTypes.RemovePersonalTask, removeTaskSaga);
}

export function* watchRemovePersonalTaskSuccess() {
    yield takeLatest(ActionTypes.RemovePersonalTaskSuccess, updateTasksSaga);
}

export function* watchSubmitEditPersonalTask() {
    yield takeLatest(ActionTypes.SubmitEditPersonalTask, editPersonalTaskSaga);
}

export function* watchSubmitEditPersonalTaskSuccess() {
    yield takeLatest(ActionTypes.SubmitEditPersonalTaskSuccess, updateTasksSaga);
}

export function* watchGetDivisions() {
    yield takeLatest(ActionTypes.GetDivisions, getDivisionsSaga);
}

export function* watchGetCommunities() {
    yield takeLatest(ActionTypes.GetCommunities, getCommunitiesSaga);
}

export function* watchSearchByAddress() {
    yield takeLatest(ActionTypes.SearchForLotByAddress, searchByAddressSaga);
}

export function* watchSearchByName() {
    yield takeLatest(ActionTypes.SearchForLotByName, searchByNameSaga);
}

export function* watchDesignSelections() {
    yield takeLatest(
        ActionTypes.GetDesignSelections,
        designSelectionsDataRequestSaga
    );
}

export function* watchGetDesignSelectionItemRequest() {
    yield takeLatest(
        ActionTypes.GetDesignSelectionsItemData,
        designSelectionsModalDataRequestSaga
    );
}

export function* watchCheckInviteToken() {
    yield takeLatest(ActionTypes.CheckInviteToken, checkInviteTokenSaga);
}

export function* watchSubmitPassword() {
    yield takeLatest(ActionTypes.SubmitPassword, submitPasswordSaga);
}

export function* watchSubmitPasswordSuccess() {
    yield takeLatest(ActionTypes.SubmitPasswordSuccess, profileSaga);
}
