import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './ChecklistDropdown.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import {
    createCloseChecklistDropdownAction,
    createAddTaskToBeCompletedAction,
    createRemoveTaskFromToBeCompletedAction,
    createSetVisibleTasksAction,
    createCompleteTasksAction,
} from 'redux/actions/Tasks/TasksActions';
import { createOpenChecklistModalAction } from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import Utils from 'utilities/Utils';
import WithCondition from 'hoc/WithCondition';
import { useHaveUser, useHasTasks, useIsAdmin } from 'utilities/HooksAndSelectors';
import { Button, Text, ButtonStyles } from 'ComponentLibrary';
import Constants from 'Constants';
import { Event } from 'components/Analytics';
import ChecklistDropdownSection from './ContentComponents/ChecklistDropdownSection';
import ChecklistDropdownSkeleton from './ChecklistDropdownSkeleton';

function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event.target);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

export default function ChecklistDropdown(props) {
    const hasUser = useHaveUser();
    const hasTasks = useHasTasks();

    const history = useHistory();
    const location = useLocation();
    const taskState = useSelector((state) => state.taskState);
    const userState = useSelector((state) => state.userState);

    const isAdmin = useIsAdmin();

    const { userDetails } = userState;
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const { mode, skeletonMode } = props;
    const maxTasksToShow = mode === 'page' ? 2 : 3;
    const maxPersonalTasksToShow = mode === 'page' ? 1 : 2;

    const {
        tasks,
        personalTasks,
        dropdownTasks,
        dropdownPersonalTasks,
        taskIdsToComplete,
        checklistDropDownOpen,
    } = taskState;

    useOutsideAlerter(wrapperRef, (whatWasClicked) => {
        const parentIsToggle = Utils.findParent(
            whatWasClicked,
            'navigation-header-checklist-container'
        );
        const parentIsInput = Utils.findParent(
            whatWasClicked,
            'field-wrap'
        );

        const parentIsModal = Utils.findParent(whatWasClicked, 'checklist-dropdown--modal');

        if (checklistDropDownOpen && parentIsToggle === null && parentIsInput === null && parentIsModal === null) {
            closeDropdown();
            dispatch(createCompleteTasksAction());
        }
    });

    const buildVisibleTasks = (taskArray) => {
        const visibleTasks = [];
        const upcomingTasks = Utils.sortTasks(
            taskArray.filter(
                (task) => !task.isSystemComplete && !task.isUserComplete
            )
        );

        upcomingTasks.forEach((task) => {
            if (visibleTasks.length < 3) {
                visibleTasks.push(task);
            }
        });

        return visibleTasks;
    };

    useEffect(() => {
        if (hasUser && hasTasks && tasks !== 'requested') {
            const visibleMITasks = buildVisibleTasks(tasks);
            const visiblePersonalTasks = buildVisibleTasks(personalTasks);
            dispatch(createSetVisibleTasksAction(visibleMITasks, visiblePersonalTasks));
        }
    }, [
        tasks,
        personalTasks,
        dispatch,
        hasUser,
        hasTasks,
        maxTasksToShow,
        mode,
    ]);

    useEffect(() => {
        return () => {
            if (mode === 'page' && !isAdmin) {
                dispatch(createCompleteTasksAction());
            }
        };
    }, [mode, dispatch, isAdmin]);

    if (!hasUser) return null;

    const closeDropdown = () => {
        history.push(location.pathname);
        const action = createCloseChecklistDropdownAction();
        dispatch(action);
        return false;
    };

    const handleViewAllTasks = () => {
        dispatch(createOpenChecklistModalAction());
    };

    const handleTaskCompletion = (task) => {
        const taskCompleteIndex = taskIdsToComplete.indexOf(task.taskId);
        if (taskCompleteIndex > -1) {
            Event('tasks', 'uncomplete');
            dispatch(createRemoveTaskFromToBeCompletedAction(task.taskId));
        } else {
            Event('tasks', 'complete');
            dispatch(createAddTaskToBeCompletedAction(task.taskId));
        }
    };

    return (
        <WithCondition
            condition={mode !== 'page'}
            wrapper={(children) => (
                <CSSTransition
                    onEnter={(el) => {
                        el.setAttribute('open', '');
                        el.querySelectorAll(Utils.focusable)[0].focus();
                    }}
                    onExited={(el) => {
                        try {
                            el.close();
                        } catch (e) {
                            // simply because browsers don't all support this
                        }
                        el.removeAttribute('open');
                    }}
                    in={checklistDropDownOpen}
                    appear={checklistDropDownOpen}
                    exit={!checklistDropDownOpen}
                    timeout={200}
                    classNames="fade"
                >
                    {children}
                </CSSTransition>
            )}
        >
            <section
                className={`checklist-dropdown checklist-dropdown--${mode}`}
                data-testid="checklist-dropdown"
                ref={wrapperRef}
            >
                <div className="checklist-dropdown__inner-wrap">
                    <div className={`checklist-header checklist-header--${mode}`}>
                        <Text family="museo">{!isAdmin && `${userDetails.accountProfile.firstName}'s `}{Constants.checklistDropdown.checklist}</Text>
                    </div>

                    <div className="checklist-task-container">
                        {skeletonMode ? (
                            <ChecklistDropdownSkeleton />
                        ) : (
                            <React.Fragment>
                                {!isAdmin && (
                                    <ChecklistDropdownSection
                                        title="PERSONAL"
                                        tasks={dropdownPersonalTasks}
                                        mode={mode}
                                        handleTaskCompletion={handleTaskCompletion}
                                        taskIdsToComplete={taskIdsToComplete}
                                        maxTasksToShow={maxPersonalTasksToShow}
                                        isPersonal
                                    />
                                )}

                                <ChecklistDropdownSection
                                    title="M/I HOMES"
                                    tasks={dropdownTasks}
                                    mode={mode}
                                    handleTaskCompletion={handleTaskCompletion}
                                    taskIdsToComplete={taskIdsToComplete}
                                    maxTasksToShow={maxTasksToShow}
                                />
                            </React.Fragment>
                        )}
                        {dropdownTasks &&
                            dropdownPersonalTasks &&
                            dropdownTasks.length === 0 &&
                            dropdownPersonalTasks.length === 0 && (
                            <div className="checklist-empty-message" data-testid="checklist-empty-message">
                                <Text variant="small" color="dusty-grey">{Constants.checklistDropdown.emptyMessage}</Text>
                            </div>
                        )}
                    </div>
                    {mode === 'modal' &&
                        (<Button
                            as="button"
                            text={Constants.checklistDropdown.viewAll}
                            style={ButtonStyles.PrimaryButton}
                            bold
                            onClick={handleViewAllTasks}
                        />)
                    }
                    {(mode === 'page' && !skeletonMode) &&
                        (<Link
                            className="button primary-button button--bold"
                            to="/checklist"
                        ><span className="button__text button__text--bold" data-testid="checklist-page-view-all" >{Constants.checklistDropdown.viewAll}</span></Link>)
                    }
                </div>
            </section>
        </WithCondition>
    );
}

ChecklistDropdown.defaultProps = {
    testId: 'checklist-dropdown',
    mode: 'modal',
};

ChecklistDropdown.propTypes = {
    mode: PropTypes.string,
};
