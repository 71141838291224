import { UserAgentApplication } from 'msal';

const msalConfig = {
    authority: `${window._env_.REACT_APP_MSAD_AUTHORITY}`,
    clientId: `${window._env_.REACT_APP_MSAD_CLIENT_ID}`,
    redirectUri: `${window._env_.REACT_APP_MSAD_REDIRECT_URI}`,
};

export const msalAuth = new UserAgentApplication({
    auth: msalConfig,
});
