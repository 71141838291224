import ApiService from './ApiService';

const DocumentsService = {
    getDocuments: (action, service = ApiService()) => {
        const lotId = action.payload;
        return service
            .get('/Documents', {
                params: { lotId },
            }).then((response) => response)
            .catch((error) => error.response);
        },
    getDocument: (packageId, service = ApiService()) => service
            .get(`/Documents/${packageId}`, { responseType: 'blob' }, false)
            .then((response) => response)
            .catch((error) => error.response),
};

export default DocumentsService;
