import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
    createCheckInviteTokenAction,
    createSubmitPasswordAction,
    createInvalidTokenAction,
} from 'redux/actions/Invite';
import { Heading } from 'ComponentLibrary';
import Links from 'components/Links';
import InviteForm from './ContentComponents/InviteForm';
import InviteDesktopSkeleton from './ContentComponents/InviteDesktopSkeleton';
import InviteMobileSkeleton from './ContentComponents/InviteMobileSkeleton';
import Constants from 'Constants';
import './Invite.scss';

const Invite = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const token =
        location.search && location.search.indexOf('token')
            ? location.search.match(/token=([^&]*)/)[1]
            : '';
    const userState = useSelector((state) => state.userState);
    const { userDetails } = userState;
    const { busy, errors, inviteTokenStatus, emailAddress } = userDetails;
    const inviteText = Constants.pages.invite;
    const hasValidInvite = inviteTokenStatus === 'valid';
    const isMobileWindow = window.innerWidth <= 764;

    useEffect(() => {
        if (!inviteTokenStatus && token) {
            dispatch(createCheckInviteTokenAction(token));
        } else if (!token) {
            dispatch(createInvalidTokenAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteTokenStatus, token]);

    const handlePasswordSubmit = (value) => {
        dispatch(createSubmitPasswordAction({
            password: value,
            token,
        }));
    };

    return (
        <main className="main main--container">
            <Container>
                <Row className="justify-content-md-center" md={12}>
                    <Col lg={8} md={10} sm={12}>
                        <div
                            className="invite-page-container"
                            data-testid="invite-page-container"
                        >

                            {inviteTokenStatus === 'requested' ? (
                                isMobileWindow ? <InviteMobileSkeleton /> : <InviteDesktopSkeleton />
                            ) : (
                                <React.Fragment>
                                    <Heading centered as="h1" like="h1">
                                        {!hasValidInvite && errors && errors.form && errors.form[0].title ? errors.form[0].title : inviteText.headingText}
                                    </Heading>
                                    <InviteForm
                                        hasValidInvite={hasValidInvite}
                                        emailAddress={emailAddress}
                                        handleSubmit={handlePasswordSubmit}
                                        errors={errors}
                                        busy={busy}
                                    />
                                </React.Fragment>
                            )}

                            {hasValidInvite && (
                                <div className="policy-links" data-testid="policy-links">
                                    <Links.PrivacyPolicyLink />
                                    <span>{' • '}</span>
                                    <Links.TermsOfUseLink />
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </main>
    );
};

export default Invite;
