import React from 'react';
import PropTypes from 'prop-types';
import 'components/FormElements/Input/Input.scss';
import Utils from 'utilities/Utils';

const buildOptions = (tab, index) => {
    const { label } = tab;
    return (
        <option key={`${Utils.slugify(label)}-select-option`} data-testid={`option-${label}`} value={index}>
            {label}
        </option>
    );
};
const Select = (props) => {
    const { options, onChange, label, indicator, id, testId, first } = props;
    return (
        <div className={`field-wrap ${first ? 'field-wrap--first' : ''}`}>
            <label
                data-testid={`select__label--${testId}`}
                htmlFor={id}
                className="visuallyhidden"
            >
                {label}
            </label>
            <div className="input-wrap input-wrap--select">
                {indicator}
                <select
                    data-testid={`select__select--${testId}`}
                    id={id}
                    className={`input input--select ${
                        indicator ? 'input--has-indicator' : ''
                    }`}
                    onChange={(e) => {
                        onChange(e);
                    }}
                >
                    {options.map(buildOptions)}
                    <optgroup label=""></optgroup>
                </select>
            </div>
        </div>
    );
};

Select.defaultProps = {
    options: [],
    label: '',
    testId: null,
    first: false,
};
Select.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    testId: PropTypes.string,
    first: PropTypes.bool,
};

export default Select;
