import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './HamburgerMenu.scss';
import { NavLink } from 'react-router-dom';
import { createShowFeedbackModalAction } from 'redux/actions/Feedback/FeedbackActions';
import { createHamburgerCloseAction } from 'redux/actions/Hamburger';
import Utils from 'utilities/Utils';
import { Icon } from 'ComponentLibrary';

const getIcon = (name, size = 1.25) => (
    <Icon size={size} icon={Utils.slugify(name)} testId="hamburger-menu-icon" />
);

const HamburgerMenu = (props) => {
    const hamburgerState = useSelector((state) => state.hamburgerState);
    const dispatch = useDispatch();
    const { isOpen } = hamburgerState;

    const links = [
        { label: 'Contacts', path: '/contacts', icon: 'phone' },
        { label: 'Deposits', path: '/deposits', icon: 'wallet' },
        { label: 'Documents', path: '/documents' },
        { label: 'Warranty', path: '/warranty', icon: 'warranty', size: 1 },

        {
            label: 'Feedback',
            action: createShowFeedbackModalAction,
            icon: 'star',
        },
        { label: 'Profile Settings', path: '/profile', icon: 'profile', size: 1.1 },
    ];
    return (
        <nav className={`hamburger-menu ${isOpen && 'hamburger-menu--open'}`} data-testid="hamburger-menu">
            <ol className="hamburger-menu__list">
                {links.map(({ label, action, path, icon, size = 1.25 }, index) => {
                    const inner = (
                        <>
                            <span className="hamburger-menu-link__icon">
                                {getIcon(icon || label, size)}
                            </span>
                            <span className="hamburger-menu-link__text">
                                {label}
                            </span>
                        </>
                    );
                    return (
                            <li
                                className="hamburger-menu__list-item"
                                key={`burger-${index}`}
                                data-testid="hamburger-menu__list-item"
                            >
                            {action ? (
                                <button
                                    className="hamburger-menu__list-item-link hamburger-menu-link"
                                    data-testid="hamburger-menu-button-link"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(action());
                                        return false;
                                    }}
                                >
                                    {inner}
                                </button>
                            ) : (
                                <NavLink
                                    data-testid="hamburger-menu-nav-link"
                                    onClick={(e) => {
                                        dispatch(createHamburgerCloseAction());
                                        window.scroll({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                        return false;
                                    }}
                                    className="hamburger-menu__list-item-link hamburger-menu-link"
                                    to={path}
                                >
                                    {inner}
                                </NavLink>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default HamburgerMenu;
